import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { findIndex, remove, filter, range } from 'lodash'
import Timeframe, { halfOrFullDayOptions } from './store/models/timeframe'

class Timeframes extends Component {
  constructor(props) {
    super(props)

    this.store = this.props.store

    this.state = {
      timeframes: props.timeframes
    }
  }

  saveToStore() {
    var pricePolicyIndex = findIndex(
      this.store.offer.price_policies,
      (policy) => policy.id === this.props.pricePolicy.id
    )
    this.store.offer.price_policies[pricePolicyIndex].time_mask.timeframes =
      this.state.timeframes
  }

  formatTimeframe(timeframe) {
    return (
      <span className="timeslot mini-card active" key={timeframe.id}>
        {timeframe.formattedSlots()}
      </span>
    )
  }

  updateBeginTime(e, timeframe) {
    var timeframes = this.state.timeframes
    const timeframeIndex = findIndex(timeframes, (tf) => tf.id === timeframe.id)

    timeframe.setBeginTime(e.target.value)
    timeframes[timeframeIndex] = timeframe

    this.setState({ timeframes })
    this.saveToStore()
  }

  updateEndTime(e, timeframe) {
    var timeframes = this.state.timeframes
    const timeframeIndex = findIndex(timeframes, (tf) => tf.id === timeframe.id)

    timeframe.setEndTime(e.target.value)
    timeframes[timeframeIndex] = timeframe

    this.setState({ timeframes })
    this.saveToStore()
  }

  updateInterval(e, timeframe) {
    var timeframes = this.state.timeframes
    const timeframeIndex = findIndex(timeframes, (tf) => tf.id === timeframe.id)

    timeframe.interval = parseInt(e.target.value)
    timeframes[timeframeIndex] = timeframe

    this.setState({ timeframes })
    this.saveToStore()
  }

  createTimeframe(type) {
    var timeframes = this.state.timeframes
    var timeframe = new Timeframe()

    if (type === 'range') {
      timeframe.setEndTime('00:00')
      timeframe.interval = 30
    } else if (type === 'halfOrFullDay') {
      timeframe.setEndTime('00:00')
    }

    timeframes.push(timeframe)

    this.setState({ timeframes })
    this.saveToStore()
  }

  updateHalfOrFullDay(e, timeframe) {
    var timeframes = this.state.timeframes
    const timeframeIndex = findIndex(timeframes, (tf) => tf.id === timeframe.id)

    const selected = halfOrFullDayOptions[e.target.value]
    timeframe.begin_time = selected.begin_time
    timeframe.end_time = selected.end_time
    timeframes[timeframeIndex] = timeframe
    this.setState({ timeframes })
    this.saveToStore()
  }

  removeTimeframe(timeframe) {
    var timeframes = this.state.timeframes

    remove(timeframes, (tf) => tf.id === timeframe.id)

    this.setState({ timeframes })
    this.saveToStore()
  }

  render() {
    const { timeframes } = this.state

    const fixedTimeframes = filter(timeframes, (t) => t.end_time == null)
    const rangedTimeframes = filter(
      timeframes,
      (t) => t.end_time && !t.isHalfOrFullDay()
    )
    const halfOrFullDayTimeframes = filter(
      timeframes,
      (t) => t.end_time && t.isHalfOrFullDay()
    )

    return (
      <div>
        <div className="timeslots">
          {timeframes.map((timeframe) => this.formatTimeframe(timeframe))}
        </div>
        <div className="timeframes-form row">
          <div className="half-or-full-day-timeframe col-xs-3">
            <h4>Journée / Demi-journée :</h4>
            {halfOrFullDayTimeframes.map((timeframe) => (
              <p key={`ft-input-${timeframe.id}`}>
                <select
                  className="form-control"
                  value={timeframe.formattedSlots(true)}
                  onChange={(e) => this.updateHalfOrFullDay(e, timeframe)}>
                  {Object.keys(halfOrFullDayOptions).map((key) => (
                    <option key={key} value={key}>
                      {halfOrFullDayOptions[key].name}
                    </option>
                  ))}
                </select>
                <a
                  className="btn btn-delete"
                  onClick={(e) => this.removeTimeframe(timeframe)}>
                  X
                </a>
              </p>
            ))}
            <a
              className="add-half-day-timeframe"
              onClick={(e) => this.createTimeframe('halfOrFullDay')}>
              Ajouter une journée / demi-journée
            </a>
          </div>
          <div className="fixed-timeframe col-xs-3">
            <h4>Horaires fixes :</h4>
            {fixedTimeframes.map((timeframe) => (
              <span
                className="fixed-timeframe-input"
                key={`ft-input-${timeframe.id}`}>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => this.updateBeginTime(e, timeframe)}
                  defaultValue={timeframe.formattedSlots()}
                />
                <a
                  className="btn btn-delete"
                  onClick={(e) => this.removeTimeframe(timeframe)}>
                  X
                </a>
              </span>
            ))}
            <a
              className="add-fixedtimeframe"
              onClick={(e) => this.createTimeframe('fixed')}>
              Ajouter un horaire
            </a>
          </div>
          <div className="ranged-timeframe col-xs-6">
            <h4>Plage horaires :</h4>
            {rangedTimeframes.map((timeframe) => (
              <div
                className="ranged-timeframe-input row"
                key={`rt-input-${timeframe.id}`}>
                <div className="input-group col-xs-7">
                  <input
                    className="form-control"
                    onChange={(e) => this.updateBeginTime(e, timeframe)}
                    defaultValue={timeframe.formattedBeginTime()}
                    placeholder="9h00"
                    type="text"
                  />
                  <div className="input-group-addon">à</div>
                  <input
                    className="form-control"
                    onChange={(e) => this.updateEndTime(e, timeframe)}
                    defaultValue={timeframe.formattedEndTime()}
                    placeholder="10h00"
                    type="text"
                  />
                </div>
                <div className="input-group interval col-xs-4">
                  <select
                    value={timeframe.interval}
                    onChange={(e) => this.updateInterval(e, timeframe)}>
                    {range(5, 245, 5).map((interval) => (
                      <option
                        value={interval}
                        key={`tf-${timeframe.id}-interval-${interval}`}>
                        {interval} minutes
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-xs-1">
                  <a
                    className="btn btn-delete"
                    onClick={(e) => this.removeTimeframe(timeframe)}>
                    X
                  </a>
                </div>
              </div>
            ))}
            <a
              className="add-rangedtimeframe"
              onClick={(e) => this.createTimeframe('range')}>
              Ajouter une plage horaire
            </a>
          </div>
        </div>
      </div>
    )
  }
}

Timeframes.propTypes = {
  store: PropTypes.object.isRequired,
  pricePolicy: PropTypes.object.isRequired,
  timeframes: PropTypes.array.isRequired
}

Timeframes.defaultProps = {}

export default Timeframes
