import moment from 'moment'

export const fullDay = 'full_day'
export const morning = 'morning'
export const afternoon = 'afternoon'
export const evening = 'evening'
export const getSpecialPeriodName = (specialPeriod) => {
  if (specialPeriod === fullDay) {
    return 'Journée'
  } else if (specialPeriod === morning) {
    return 'Matinée'
  } else if (specialPeriod === afternoon) {
    return 'Après-midi'
  } else if (specialPeriod === evening) {
    return 'Soirée'
  }
}

const specialPeriodNameWithPronoun = (specialPeriod) => {
  if (specialPeriod === fullDay) {
    return ' la journée'
  } else if (specialPeriod === morning) {
    return ' la matinée'
  } else if (specialPeriod === afternoon) {
    return " l'après-midi"
  } else if (specialPeriod === evening) {
    return 'la soirée'
  }
}

export const fullDateWithTime = (date, time) => {
  let dateWithTime = moment(date).format('dddd Do MMMM')
  if (time) {
    dateWithTime +=
      specialPeriodNameWithPronoun(time) ||
      ` à ${moment(time, 'LTS').format('LT')}`
  }
  return dateWithTime
}
