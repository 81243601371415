import Base from './base'
import AvailableModels from './collection'

class PriceTier extends Base {
  defaultAttributes() {
    return {
      price_policy_id: null,
      price_variant_id: null,
      lower_echelon: null,
      upper_echelon: null,
      price: null,
      is_flat_price: false,
      flat_discount: 0.0,
      percent_discount: 0.0,
      use_flat_discount: false,
      price_variant: null
    }
  }

  availableModels() {
    return AvailableModels()
  }
}

export default PriceTier
