import React from 'react'
import PropTypes from 'prop-types'

class SearchMobileBox extends React.Component {
  renderFeatureOption = (feature) => {
    return (
      <option key={feature.id} value={feature.id}>
        {feature.label}
      </option>
    )
  }

  renderCategoryOption = (category) => {
    return (
      <option key={category.id} value={category.id}>
        {category.name}
      </option>
    )
  }

  updateEventFeature = (event) => {
    this.props.onAddEventFeature(event.target.value, false, false)
  }

  updateCategory = (event) => {
    this.props.onAddCategory(event.target.value, false, false)
  }

  updateLocation = (event) => {
    this.props.onLocationSelected(event.target.value, false)
  }

  handleSearchRequested = (event) => {
    this.props.triggerSearch()
  }

  render() {
    var categoriesNodes = this.props.filters.categories.map(
      this.renderCategoryOption
    )
    var eventFeaturesNodes = this.props.filters.event_features.map(
      this.renderFeatureOption
    )

    var defaultValue = ''
    var firstOrDefault = function (array) {
      return array.length > 0 ? array[0] : defaultValue
    }

    var selectedCategories = firstOrDefault(this.props.selected_categories)
    var selectedEvents = firstOrDefault(this.props.selectedEvents)
    const { selectedLocation, locationFilters } = this.props
    const locationNodes = locationFilters.map((locationFilter, index) => {
      return (
        <option key={index} value={locationFilter.slug}>
          {locationFilter.name}
        </option>
      )
    })
    return (
      <div
        className="modal fade"
        id="mobileSearchModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mobileSearchModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <span
                className="search-modal-title modal-title"
                id="mobileSearchModalLabel">
                Filtrer ma recherche
              </span>
            </div>
            <div className="modal-body search-mobile-body">
              <select
                className="form-control dropdown"
                value={selectedLocation}
                onChange={this.updateLocation}>
                <option value={defaultValue}>Choisir une région</option>
                {locationNodes}
              </select>
              <select
                className="form-control dropdown"
                value={selectedCategories}
                onChange={this.updateCategory}>
                <option value={defaultValue}>Choisir une activité</option>
                {categoriesNodes}
              </select>
              <select
                className="form-control dropdown"
                value={selectedEvents}
                onChange={this.updateEventFeature}>
                <option value={defaultValue}>A quelle occasion ?</option>
                {eventFeaturesNodes}
              </select>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal">
                Fermer
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.handleSearchRequested}>
                Rechercher
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
SearchMobileBox.propTypes = {
  filters: PropTypes.object.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  selectedEvents: PropTypes.array.isRequired,
  triggerSearch: PropTypes.func.isRequired,
  onAddEventFeature: PropTypes.func.isRequired,
  onAddCategory: PropTypes.func.isRequired,
  selectedLocation: PropTypes.string.isRequired,
  locationFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLocationSelected: PropTypes.func.isRequired
}

export default SearchMobileBox
