import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connectSearchBox } from 'react-instantsearch/connectors'

const SearchBox = ({ currentRefinement, refine }) => {
  const [value, setValue] = useState(currentRefinement)

  useEffect(() => {
    if (!currentRefinement) {
      setValue('')
    }
  }, [currentRefinement])

  return (
    <div className="ais-SearchBox">
      <form
        className="form"
        onSubmit={(event) => {
          event.preventDefault()
          refine(value)
        }}>
        <div className="input-group">
          <input
            className="form-control"
            type="search"
            value={value}
            placeholder="Rechercher par mot-clé"
            autoFocus={false}
            onChange={(event) => setValue(event.currentTarget.value)}
            enterKeyHint="search"
          />
          <span className="input-group-btn">
            <button
              className="btn btn-info"
              type="button"
              onClick={() => refine(value)}>
              <span className="glyphicon glyphicon-search" />
            </button>
          </span>
        </div>
      </form>
    </div>
  )
}

SearchBox.propTypes = {
  currentRefinement: PropTypes.string,
  refine: PropTypes.func
}

export default connectSearchBox(SearchBox)
