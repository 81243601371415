import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import createReactClass from 'create-react-class'
import MultipleStepsFormStep from './MultipleStepsFormStep'
import MultipleStepsFormHeader from './MultipleStepsFormHeader'
import googleTracker from 'lib/trackers/googleTracker'

var MultipleStepsForm = createReactClass({
  displayName: 'MultipleStepsForm',

  childContextTypes: {
    startLoading: PropTypes.func,
    setProgress: PropTypes.func,
    stopLoading: PropTypes.func,
    pushComponent: PropTypes.func,
    goPrevious: PropTypes.func
  },

  getChildContext: function () {
    return {
      startLoading: this.startLoading,
      setProgress: this.setProgress,
      stopLoading: this.stopLoading,
      pushComponent: this.pushComponent,
      goPrevious: this.goPrevious
    }
  },

  pushComponent: function (name, content, url) {
    var newSteps = this.state.steps
    var newIndex = this.state.step + 1
    var nextStep = {
      name: name,
      content: content,
      url: url
    }
    newSteps.splice(newIndex, 1, nextStep)
    this.setState({ steps: newSteps }, function () {
      this.switchStep(newIndex)
    })
  },

  startLoading: function () {
    this.setState({ loading: true })
  },
  stopLoading: function () {
    this.setState({ loading: false })
  },

  goNext: function (index) {
    var newIndex = index + 1
    if (newIndex < this.state.steps.length) {
      this.switchStep(newIndex)
    }
  },

  goPrevious: function () {
    var newIndex = this.state.step - 1
    if (newIndex >= 0) {
      this.switchStep(newIndex)
    }
  },

  goTo: function (index) {
    if (index < this.state.step) {
      this.switchStep(index)
    }
  },

  switchStep: function (index) {
    this.setState({ step: index })
    if (index > this.state.maxReachedStep) {
      this.setState({ maxReachedStep: index })
    }
    googleTracker.trackPageView(this.state.steps[index].url.path)
    window.scrollTo(0, 0)
  },

  componentDidMount: function () {
    googleTracker.trackPageView()
  },

  getDefaultProps: function () {
    return {
      initialStep: 0
    }
  },

  getInitialState: function () {
    return {
      steps: this.props.steps,
      step: this.props.initialStep,
      loading: false,
      maxReachedStep: this.props.initialStep
    }
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    if (nextProps.steps !== this.state.steps) {
      this.setState({ steps: nextProps.steps })
    }
  },

  renderStep: function (el, index) {
    return (
      <MultipleStepsFormStep
        goNext={this.goNext}
        goPrevious={this.goPrevious}
        goTo={this.goTo}
        hide={index !== this.state.step}
        key={index}
        index={index}
        title={el.name}
        loading={index === this.state.step && this.state.loading}
        images={this.props.images}>
        {el.content}
      </MultipleStepsFormStep>
    )
  },

  render: function () {
    var stepsNodes = this.state.steps.map(this.renderStep)

    return (
      <Fragment>
        <MultipleStepsFormHeader
          steps={this.state.steps}
          currentStep={this.state.step}
          goTo={this.goTo}
          maxReachedStep={this.state.maxReachedStep}
          babasportLogo={this.props.images.babasportLogo}
          homepageURL={this.props.homepageURL}
        />
        <div className="row">
          <div className="left-side col-sm-7 col-xs-12">{stepsNodes}</div>
          <div className="right-side col-sm-4 col-sm-offset-1 col-xs-12">
            {this.props.children}
          </div>
        </div>
      </Fragment>
    )
  }
})
export default MultipleStepsForm
