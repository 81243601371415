import React from 'react'
import PropTypes from 'prop-types'

const ActivityPopin = ({ activity }) => {
  const image = activity.aa_images[0] || ''

  return (
    <a
      className="link-unstyled"
      href={activity.aa_public_path}
      target="_blank"
      rel="noreferrer">
      <div className="hit-card hit-card-map">
        <div className="img-container">
          <img src={image.url} alt={activity.name} />
        </div>
        <div className="content-small">
          <div className="activity-title">{activity.name}</div>
        </div>
      </div>
    </a>
  )
}

ActivityPopin.propTypes = {
  activity: PropTypes.object
}

export default ActivityPopin
