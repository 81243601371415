import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as Sentry from '@sentry/react'

const ModalFaqTrigger = ({ isButton, isMobile }) => {
  return (
    <Sentry.ErrorBoundary fallback="An error has occurred">
      {isButton ? (
        <button
          className={classNames(
            'btn btn-info btn-cta btn-block whitespace-pre-wrap',
            { 'btn-sm': isMobile }
          )}
          data-toggle="modal"
          data-target="#quote_form_modal">
          Demander un devis
        </button>
      ) : (
        <a
          className="ask-quote-link"
          data-toggle="modal"
          data-target="#quote_form_modal">
          DEVIS GROUPES ET ENTREPRISES
        </a>
      )}
    </Sentry.ErrorBoundary>
  )
}

ModalFaqTrigger.propTypes = {
  isButton: PropTypes.bool,
  isMobile: PropTypes.bool
}

export default ModalFaqTrigger
