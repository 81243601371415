import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { findIndex } from 'lodash'

class PriceTierForm extends Component {
  constructor(props) {
    super(props)

    this.store = this.props.store

    this.state = {
      priceTier: props.priceTier,
      isFlatPrice: props.priceTier.is_flat_price,
      useFlatDiscount: props.priceTier.use_flat_discount
    }
  }

  // Save the priceTier
  saveToStore() {
    var pricePolicyIndex = findIndex(
      this.store.offer.price_policies,
      (policy) => policy.id === this.props.pricePolicy.id
    )
    var priceTierIndex = findIndex(
      this.store.offer.price_policies[pricePolicyIndex].price_tiers,
      (pt) => pt.id === this.state.priceTier.id
    )

    this.store.offer.price_policies[pricePolicyIndex].price_tiers[
      priceTierIndex
    ] = this.state.priceTier
  }

  toggleFlatDiscount() {
    var { priceTier } = this.state

    priceTier.use_flat_discount = !this.state.useFlatDiscount

    this.setState({
      priceTier,
      useFlatDiscount: !this.state.useFlatDiscount
    })

    this.saveToStore()
  }

  toggleIsFlatPrice() {
    var { priceTier } = this.state

    priceTier.is_flat_price = !this.state.isFlatPrice

    this.setState({
      priceTier,
      isFlatPrice: !this.state.isFlatPrice
    })

    this.saveToStore()
  }

  updatePriceTier(e, field) {
    var { priceTier } = this.state
    priceTier[field] = e.target.value

    this.setState({ priceTier })
    this.saveToStore()
  }

  toggleablePromotionInput() {
    const { priceTier } = this.state

    if (this.state.useFlatDiscount) {
      return (
        <Fragment>
          <input
            className="form-control"
            onChange={(e) => this.updatePriceTier(e, 'flat_discount')}
            value={priceTier.flat_discount}
            placeholder="0"
            type="number"
          />
          <div
            onClick={(e) => this.toggleFlatDiscount()}
            className="input-group-addon">
            €
          </div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <input
            className="form-control"
            onChange={(e) => this.updatePriceTier(e, 'percent_discount')}
            value={priceTier.percent_discount}
            placeholder="0"
            type="number"
          />
          <div
            onClick={(e) => this.toggleFlatDiscount()}
            className="input-group-addon">
            %
          </div>
        </Fragment>
      )
    }
  }

  render() {
    const { priceTier } = this.state

    return (
      <div className="row">
        <span className="echelons input-group col-xs-3">
          <input
            className="form-control"
            onChange={(e) => this.updatePriceTier(e, 'lower_echelon')}
            value={priceTier.lower_echelon}
            type="number"
          />
          <span className="separator">&nbsp;-&nbsp;</span>
          <input
            className="form-control"
            onChange={(e) => this.updatePriceTier(e, 'upper_echelon')}
            value={priceTier.upper_echelon}
            type="number"
          />
        </span>
        <span className="price input-group col-xs-1">
          <input
            className="form-control pull-right"
            onChange={(e) => this.updatePriceTier(e, 'price')}
            value={priceTier.price}
            type="number"
          />
          <div className="input-group-addon">€</div>
        </span>
        <span className="flat-price input-group col-xs-4">
          <div
            onClick={(e) => this.toggleIsFlatPrice()}
            className="input-group-addon">
            {this.state.isFlatPrice ? 'Fixe' : 'Unité'}
          </div>
        </span>
        <span className="promotion input-group col-xs-1">
          {this.toggleablePromotionInput()}
        </span>
        <span className="price-tier-delete input-group pull-right col-xs-1">
          <a
            className="btn btn-delete btn-floating"
            onClick={(e) => this.props.deletePriceTier(priceTier)}>
            X
          </a>
        </span>
      </div>
    )
  }
}

PriceTierForm.propTypes = {
  store: PropTypes.object.isRequired,
  pricePolicy: PropTypes.object.isRequired,
  priceTier: PropTypes.object.isRequired
}

PriceTierForm.defaultProps = {}

export default PriceTierForm
