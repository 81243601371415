import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BabaForm from 'components/forms/Index'
import NavigationButtons from 'components/bookings/NavigationButtons'

class GiftRecipientForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: '',
      personalizedMessage: this.props.order.personalizedMessage || '',
      giftFrom: this.props.order.giftFrom || '',
      giftFor: this.props.order.giftFor || ''
    }

    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
  }

  static contextTypes = {
    handleValidationSuccessful: PropTypes.func,
    goPrevious: PropTypes.func
  }

  success(data) {
    this.setState({
      errorMessage: ''
    })

    this.props.onOrderModification(data)
    if (this.context.handleValidationSuccessful) {
      this.context.handleValidationSuccessful()
    }
  }

  error() {
    this.setState({
      errorMessage: 'Un problème est survenu, veuillez réessayer.'
    })
  }

  render() {
    const countriesOptions = this.props.availableCountries.map((country) => {
      return {
        id: country.code,
        name: `${country.name} ${
          country.extraShipmentFee > 0
            ? `(+${Math.floor(
                country.extraShipmentFee
              )}€ de frais d'expédition)`
            : ''
        }`
      }
    })
    const firstName = this.props.order.shipment
      ? this.props.order.shipment.address.firstName
      : ''
    const lastName = this.props.order.shipment
      ? this.props.order.shipment.address.lastName
      : ''
    const address1 = this.props.order.shipment
      ? this.props.order.shipment.address.address1
      : ''
    const address2 = this.props.order.shipment
      ? this.props.order.shipment.address.address2
      : ''
    const city = this.props.order.shipment
      ? this.props.order.shipment.address.city
      : ''
    const zipcode = this.props.order.shipment
      ? this.props.order.shipment.address.zipcode
      : ''
    const countryCode = this.props.order.shipment
      ? this.props.order.shipment.address.country.code
      : 'FR'

    return (
      <div data-rspec="checkout-gift-recipient-container">
        <div className="step-title">Personnalisation</div>
        {this.state.errorMessage && (
          <div className="alert alert-danger" role="alert">
            {this.state.errorMessage}
          </div>
        )}
        <div className="mb-3">Personnalisez votre bon cadeau</div>
        <BabaForm.BabaForm
          name="personalized_message_and_address"
          remote
          action={`${this.props.updateGiftRecipientStepUrl}.json`}
          method="post"
          success={this.success}
          error={this.error}>
          <BabaForm.FormLine>
            <BabaForm.BabaInput
              id="giftFor"
              type="text"
              maxlength={20}
              objectName="order"
              fieldName="gift_for"
              placeHolder="Bénéficiaire"
              helpBlock="20 caractères max"
              hideLabel
              required
              defaultValue={this.state.giftFor}
            />
            <BabaForm.BabaInput
              id="giftFrom"
              type="text"
              maxlength={20}
              objectName="order"
              fieldName="gift_from"
              placeHolder="De la part de"
              helpBlock="20 caractères max"
              hideLabel
              required
              defaultValue={this.state.giftFrom}
            />
          </BabaForm.FormLine>
          <BabaForm.FormLine>
            <BabaForm.BabaInput
              id="personalizedMessage"
              type="textarea"
              maxlength={120}
              objectName="order"
              fieldName="personalized_message"
              placeHolder="Message pour le destinataire"
              helpBlock="120 caractères max"
              rows={4}
              hideLabel
              defaultValue={this.state.personalizedMessage}
            />
          </BabaForm.FormLine>
          {this.props.order.shippingMethod && (
            <Fragment>
              <p>Adresse de livraison du bon</p>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  id="first_name"
                  type="text"
                  objectName="address"
                  fieldName="first_name"
                  placeHolder="Prénom"
                  hideLabel
                  required
                  defaultValue={firstName}
                />
                <BabaForm.BabaInput
                  id="last_name"
                  type="text"
                  objectName="address"
                  fieldName="last_name"
                  placeHolder="Nom"
                  hideLabel
                  required
                  defaultValue={lastName}
                />
              </BabaForm.FormLine>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  id="address1"
                  type="text"
                  objectName="address"
                  fieldName="address1"
                  placeHolder="Adresse"
                  hideLabel
                  required
                  defaultValue={address1}
                />
              </BabaForm.FormLine>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  id="address2"
                  type="text"
                  objectName="address"
                  fieldName="address2"
                  hideLabel
                  placeHolder="Complément d'adresse"
                  defaultValue={address2}
                />
              </BabaForm.FormLine>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  id="city"
                  type="text"
                  objectName="address"
                  fieldName="city"
                  placeHolder="Ville"
                  hideLabel
                  required
                  defaultValue={city}
                />
                <BabaForm.BabaInput
                  id="zipcode"
                  type="text"
                  objectName="address"
                  fieldName="zipcode"
                  placeHolder="Code postal"
                  hideLabel
                  required
                  pattern="[0-9]+"
                  defaultValue={zipcode}
                />
              </BabaForm.FormLine>
              <BabaForm.FormLine>
                <BabaForm.BabaLabel
                  htmlFor="country_code"
                  label="Pays :"
                  required
                />
                <BabaForm.BabaSelect
                  objectName="country"
                  fieldName="code"
                  values={countriesOptions}
                  defaultValue={countryCode}
                  required
                />
              </BabaForm.FormLine>
            </Fragment>
          )}
          <NavigationButtons
            priceAfter={this.props.order.priceAfter}
            goPrevious={this.context.goPrevious}
            submitLabel="Suivant"
            handleValidate={null}
          />
        </BabaForm.BabaForm>
      </div>
    )
  }
}

GiftRecipientForm.propTypes = {
  order: PropTypes.object.isRequired,
  updateGiftRecipientStepUrl: PropTypes.string.isRequired,
  onOrderModification: PropTypes.func.isRequired
}

export default GiftRecipientForm
