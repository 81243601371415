import React, { Component } from 'react'
import PropTypes from 'prop-types'

import EmptyStar from 'images/icons/etoile.svg'
import FullStar from 'images/icons/etoile_pleine.svg'
import HalfStar from 'images/icons/etoile_moitie.svg'
import * as Sentry from '@sentry/react'

class StarRating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      actualNote: props.note,
      displayNote: props.note
    }
  }

  generateStar = (number, img) => {
    if (this.props.editable) {
      return (
        <li
          onMouseEnter={this.handleStarHover.bind(this, number)}
          onMouseLeave={this.handleStarLeave.bind(this, number)}
          onClick={this.handleStarClick.bind(this, number)}
          key={number}
          ref={number}>
          <img className="rating-star" src={img} alt="Note de l'activité" />
        </li>
      )
    } else {
      return (
        <li key={number} ref={number}>
          <img className="rating-star" src={img} alt="Note de l'activité" />
        </li>
      )
    }
  }

  handleStarHover = (rating) => {
    this.setState({ displayNote: rating })
  }

  handleStarLeave = (rating) => {
    this.setState({ displayNote: this.state.actualNote })
  }

  handleStarClick = (rating, event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ actualNote: rating, displayNote: rating })
    if (this.props.editable && this.props.handleChangedRating) {
      this.props.handleChangedRating(rating)
    }
  }

  generateAllStars = (full, half, empty) => {
    const list = []
    let j = 1
    for (let i = 0; i < full; i++) {
      list.push(this.generateStar(j, FullStar))
      j++
    }
    for (let i = 0; i < half; i++) {
      list.push(this.generateStar(j, HalfStar))
      j++
    }
    for (let i = 0; i < empty; i++) {
      list.push(this.generateStar(j, EmptyStar))
      j++
    }
    return list
  }

  render() {
    if (this.props.threshold && this.props.threshold > this.state.actualNote) {
      return null
    } else {
      const note10 = Math.ceil(this.state.displayNote * 2)
      const halfStarCount = note10 % 2
      const fullStarCount = (note10 - halfStarCount) / 2
      const emptyStarCount = 5 - fullStarCount - halfStarCount
      const allStarsNodes = this.generateAllStars(
        fullStarCount,
        halfStarCount,
        emptyStarCount
      )
      const editableClassName = this.props.editable ? 'editable' : ''

      return (
        <Sentry.ErrorBoundary fallback="An error has occured">
          <div className={'rating-stars ' + editableClassName}>
            <ul>{allStarsNodes}</ul>
          </div>
          {this.props.count > 0 && <div>({`${this.props.count}`})</div>}
        </Sentry.ErrorBoundary>
      )
    }
  }
}
StarRating.propTypes = {
  note: PropTypes.string.isRequired,
  threshold: PropTypes.number,
  count: PropTypes.number,
  editable: PropTypes.bool
}
StarRating.defaultProps = {
  threshold: 0,
  count: 0,
  editable: false
}

export default StarRating
