import PropTypes from 'prop-types'
import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'

var MultipleStepsFormStep = createReactClass({
  displayName: 'MultipleStepsFormStep',

  childContextTypes: {
    handleValidationSuccessful: PropTypes.func
  },

  getChildContext: function () {
    return {
      handleValidationSuccessful: this.handleValidationSuccessful
    }
  },

  handleValidationSuccessful: function () {
    if (this.props.goNext) {
      this.props.goNext(this.props.index)
    }
  },

  render: function () {
    return (
      <div className={classNames('step', { hidden: this.props.hide })}>
        {this.props.loading && (
          <div className="loading-blur">
            <div className="loading-content">
              <span className="loading-message">Chargement</span>
            </div>
          </div>
        )}
        <div className="step-form">{this.props.children}</div>
      </div>
    )
  }
})

export default MultipleStepsFormStep
