import React, { useEffect, useState } from 'react'
import BabaForm from 'components/forms/Index'
import SearchSelectActivities from 'components/shared/SearchSelectActivities'
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import BookingModule from 'components/offers/bookings/BookingModule'
import PropTypes from 'prop-types'

const AddLineItemsUI = ({ publicQuoteId }) => {
  const [activity, setActivity] = useState(null)
  const [offer, setOffer] = useState(null)
  useQueryClient()

  const { data: apiOffers } = useQuery({
    queryKey: ['activity_offers', activity?.id],
    queryFn: async () => {
      const res = await fetch(`/activities/${activity.id}/offers`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      return res.json()
    },
    enabled: !!activity
  })
  const offers = apiOffers?.offers

  const { data: activityOfferVariants } = useQuery({
    queryKey: ['activity_offer_variants', activity?.id, offer?.id],
    queryFn: async () => {
      const res = await fetch(
        `/activities/${activity.id}/offers/${offer.id}/variants`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      return res.json()
    },
    enabled: !!offer
  })

  useEffect(() => {
    if (offers?.length > 0 && !offer) {
      setOffer(offers[0])
    }
  }, [offers, offer])

  useEffect(() => {
    if (activity?.id !== offer?.activity_id) {
      setOffer(null)
    }
  }, [activity, offer])
  return (
    <div>
      <div className="form-group">
        <BabaForm.FormLine>
          <BabaForm.BabaLabel label="Ajouter une activité :" />
          <SearchSelectActivities handleSelectedActivity={setActivity} />
        </BabaForm.FormLine>
      </div>
      {offers?.length > 0 && (
        <div className="form-group">
          <BabaForm.FormLine>
            <BabaForm.BabaLabel label="Selectionner l'offre :" />
            <select
              className="form-control"
              onChange={(e) => {
                setOffer(
                  offers.find((offer) => offer.id.toString() === e.target.value)
                )
              }}
              value={offer?.id || ''}
              required={true}>
              {offers.map((offer) => (
                <option key={offer.id} value={offer.id}>
                  {offer.name}
                </option>
              ))}
            </select>
          </BabaForm.FormLine>
        </div>
      )}
      {offer && activityOfferVariants && (
        <BookingModule
          activity={activityOfferVariants.activity}
          offer={activityOfferVariants.offer}
          giftMode={false}
          publicQuoteId={publicQuoteId}
        />
      )}
    </div>
  )
}
AddLineItemsUI.propTypes = {
  publicQuoteId: PropTypes.string
}

const queryClient = new QueryClient()
const AddLineItems = ({ publicQuoteId }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AddLineItemsUI publicQuoteId={publicQuoteId} />
    </QueryClientProvider>
  )
}

AddLineItems.propTypes = {
  publicQuoteId: PropTypes.string
}
export default AddLineItems
