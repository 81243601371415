import React from 'react'
import createReactClass from 'create-react-class'
import BabaForm from 'components/forms/Index'
import MangopayCardRegistrar from 'lib/MangopayCardRegistrar'
import lockIcon from 'images/icons/lock.svg'
import PropTypes from 'prop-types'
import NavigationButtons from 'components/bookings/NavigationButtons'

var CardRegistrationForm = createReactClass({
  displayName: 'CardRegistrationForm',

  getInitialState: function () {
    return {
      disableSubmitButton: false,
      errors: []
    }
  },
  componentDidMount() {
    $(function () {
      $('#cvv-tooltip span').tooltip()
    })
  },
  resetErrors: function () {
    this.setState({ errors: [] })
  },
  showOverlay: function () {
    this.props.startLoading()
  },
  errorOverlay: function (errors) {
    this.props.stopLoading()
    this.setState({ errors: errors })
    this.setState({ disableSubmitButton: false })
  },

  cardRegistrationSuccess: function (mangopayId, cardId) {
    this.props.cardRegistrationSuccess(mangopayId, cardId)
  },

  parseExpiryDate: function (val) {
    var els = val.split('/')
    var month = els[0].replace(/\s+/g, '')
    var year = els[1].replace(/\s+/g, '')
    if (year.length > 2) {
      year = year.slice(-2)
    }
    return month + year
  },
  parseNumber: function (val) {
    return val.replace(/\s+/g, '')
  },
  parseCardType: function (val) {
    var cardType = $.payment.cardType(val)
    // Very weirdly, Mangopay suggests testing VISA/mastercard with cardnumber starting with 35, length: 16, thus being detected as JCB.
    // Hence JCB detected cards are set as CB_VISA_MASTERCARD. Not a problem since Mangopay does not support JCB anyway.
    switch (cardType) {
      case 'maestro':
        return 'MAESTRO'
      case 'mastercard':
      case 'visa':
      case 'jcb':
        return 'CB_VISA_MASTERCARD'
      case 'dinersclub':
        return 'DINERS'
      case 'amex':
      case 'dankort':
      case 'discover':
      case 'forbrugsforeningen':
      case 'unionpay':
        return 'UNSUPPORTED'
      default:
        return 'CB_VISA_MASTERCARD'
    }
  },
  handleSubmit: function () {
    this.setState({ disableSubmitButton: true })
    var cardExpirationDate = this.parseExpiryDate($('#card_expiry').val())
    var cardNumber = this.parseNumber($('#card_number').val())
    var cardType = this.parseCardType($('#card_number').val())
    if (cardType === 'UNSUPPORTED') {
      this.setState({
        errors: [
          { field: 'Erreur', message: "Ce type de carte n'est pas supporté" }
        ]
      })
      return
    }
    var cardData = {
      cardNumber: cardNumber,
      cardType: cardType,
      cardExpirationDate: cardExpirationDate,
      cardCvx: $('#card_cvc').val()
    }
    var showOverlayFn = this.showOverlay
    var errorOverlayFn = this.errorOverlay
    var cardRegistrationSuccessFn = this.cardRegistrationSuccess
    var resetErrorsFn = this.resetErrors
    var mangopayDelegate = {
      getCardType: function () {
        return cardType
      },
      getCardData: function () {
        return cardData
      },
      didStart: function () {
        resetErrorsFn()
        showOverlayFn()
      },
      didCreateCardRegistration: function () {},
      didRegisterCard: function (mangopayId, cardId) {
        cardRegistrationSuccessFn(mangopayId, cardId)
      },
      didFailWithMessage: function (errors) {
        errorOverlayFn(errors)
      }
    }
    var mangopayCardRegistrar = new MangopayCardRegistrar(mangopayDelegate)
    mangopayCardRegistrar.registerCard()
  },

  render: function () {
    return (
      <div>
        <div className="credit-card-information mb-2">
          <img
            src={lockIcon}
            alt="Paiement sécurisé"
            className="mr-2"
            height="12px"
            width="12px"
          />
          <span>Paiement 100% sécurisé</span>
          <img src={this.props.images.diners} alt="carte Diners" />
          <img src={this.props.images.visa} alt="carte VISA" />
          <img src={this.props.images.maestro} alt="carte Maestro" />
          <img src={this.props.images.mastercard} alt="carte Mastercard" />
        </div>
        <div className="mb-2">
          <img
            className="img-responsive"
            src={this.props.images.mangopayImage}
            height="40px"
            alt="Mangopay"
          />
        </div>
        <BabaForm.BabaForm
          remote={false}
          onSubmit={this.handleSubmit}
          errors={this.state.errors}>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <BabaForm.BabaInput
                type="tel"
                objectName="card"
                fieldName="number"
                hideLabel={true}
                placeHolder="Numéro de carte"
                required
                validate={false}
                paymentFormat="formatCardNumber"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4 col-sm-3">
              <BabaForm.BabaInput
                type="tel"
                objectName="card"
                fieldName="expiry"
                hideLabel={true}
                placeHolder="MM / AA"
                required
                validate={false}
                paymentFormat="formatCardExpiry"
              />
            </div>
            <div className="col-xs-4 col-sm-3">
              <BabaForm.BabaInput
                type="tel"
                objectName="card"
                fieldName="cvc"
                hideLabel={true}
                placeHolder="CVV"
                required
                validate={false}
                paymentFormat="formatCardCVC"
              />
            </div>
            <div id="cvv-tooltip" className="col-xs-1">
              <span
                className="glyphicon glyphicon-question-sign"
                data-toggle="tooltip"
                data-placement="top"
                title="Les 3 derniers chiffres au dos de la carte"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="notice">
                Vos coordonnées bancaires sont anonymisées et ne sont pas
                conservées par Babasport.
                <br />
                En sélectionnant le bouton ci-dessous, vous acceptez les{' '}
                <a
                  href={this.props.urls.aboutTermsURL}
                  target="_blank"
                  rel="noreferrer">
                  CGV de Babasport
                </a>
                .
              </div>
            </div>
          </div>
          <NavigationButtons
            priceAfter={this.props.priceAfter}
            goPrevious={this.context.goPrevious}
            submitLabel={this.props.callToActionText}
            handleValidate={null}
            disableSubmitButton={this.state.disableSubmitButton}
          />
        </BabaForm.BabaForm>
      </div>
    )
  }
})

CardRegistrationForm.contextTypes = {
  goPrevious: PropTypes.func
}

export default CardRegistrationForm
