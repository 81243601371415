import React, { useState, useEffect, Fragment } from 'react'
import MultipleStepsForm from 'components/funnel/MultipleStepsForm'
import InformationForm from 'components/bookings/InformationForm'
import GiftRecipientForm from 'components/bookings/GiftRecipientForm'
import PaymentForm from './PaymentForm'
import OrderCheckoutValidation from './OrderCheckoutValidation'
import BookingFaqs from 'components/bookings/BookingFaqs'
import OrderRecap from 'components/bookings/OrderRecap'
import googleTracker from 'lib/trackers/googleTracker'
import * as Sentry from '@sentry/react'
import lockIcon from 'images/icons/lock.svg'
import { shouldRenderOrderCheckoutValidation } from 'lib/Checkout'

const OrderCheckout = ({
  user: userProp,
  order: orderProp,
  authenticityToken,
  availableCountries,
  images,
  steps,
  urls,
  babaPhoneNumber
}) => {
  const [order, setOrder] = useState(orderProp)
  const [user, setUser] = useState(userProp)

  useEffect(() => {
    if (order.orderStatus === 'in_creation') {
      googleTracker.trackInitiateCheckout(trackingInfo())
    } else if (
      order.orderStatus !== 'payment_error' &&
      order.orderStatus !== 'pre_auth_error'
    ) {
      googleTracker.trackPurchase(trackingInfo())
    }
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://widgets.rr.skeepers.io/generated/e0619e69-b4b3-dd44-911b-45b8a77e037e/e36c503a-fa16-45d8-b180-be41115fd0e4.js'
    script.async = true
    document.body.appendChild(script)
  }, [])

  const trackingInfo = () => ({
    activityId: order.activity.id,
    activityName: order.activity.name,
    centerId: order.center.id,
    price: order.priceAfter,
    isGift: order.isGift ? 'is_gift' : 'is_booking',
    orderId: order.id,
    coupon: order.couponName
  })

  const handleOrderModification = (data) => {
    setOrder(data.order)
  }

  const stepsArray = []

  if (shouldRenderOrderCheckoutValidation(order)) {
    const validationForm = (
      <OrderCheckoutValidation
        urls={urls}
        order={order}
        onOrderModification={handleOrderModification}
        trackingInfo={trackingInfo()}
        previousStepURL={urls.activityURL}
      />
    )
    const optionStepName = order.isGift ? 'Options' : 'Option'
    stepsArray.push({
      name: optionStepName,
      content: validationForm,
      url: steps.validationUrl
    })
  }

  const informationForm = (
    <InformationForm
      urls={urls}
      authenticityToken={authenticityToken}
      user={user}
      onUserModification={setUser}
      trackingInfo={trackingInfo()}
      priceAfter={order.priceAfter}
      onOrderModification={handleOrderModification}
      order={order}
      previousStepURL={
        !shouldRenderOrderCheckoutValidation(order) && urls.activityURL
      }
    />
  )
  stepsArray.push({
    name: 'Informations',
    content: informationForm,
    url: steps.informationUrl
  })

  if (order.isGift) {
    const giftRecipientForm = (
      <GiftRecipientForm
        order={order}
        onOrderModification={handleOrderModification}
        updateGiftRecipientStepUrl={urls.giftRecipientStepUpdateURL}
        availableCountries={availableCountries}
      />
    )
    stepsArray.push({
      name: 'Personnalisation',
      content: giftRecipientForm,
      url: steps.giftRecipientUrl
    })
  }

  const cardForm = (
    <div className="checkout-payment">
      <PaymentForm
        images={images}
        order={order}
        onOrderModification={handleOrderModification}
        urls={urls}
        trackingInfo={trackingInfo()}
        priceAfter={order.priceAfter}
      />
    </div>
  )
  stepsArray.push({
    name: 'Paiement',
    content: cardForm,
    url: steps.paymentUrl
  })

  const paymentError =
    order.orderStatus === 'pre_auth_error' ||
    order.orderStatus === 'payment_error'
  const isInCreation = order.orderStatus === 'in_creation'

  let initialStep = 0
  if (paymentError || !isInCreation) {
    initialStep = stepsArray.length - 1
  }
  const faqType = order.isGift ? 'gift_order' : 'order'

  return (
    <Sentry.ErrorBoundary fallback="An error has occured">
      <div className="container mb-3">
        <MultipleStepsForm
          images={images}
          initialStep={initialStep}
          steps={stepsArray}
          homepageURL={urls.homepageURL}
          activityURL={urls.activityURL}>
          <Fragment>
            <OrderRecap order={order} />
            <div className="row secure-payment-banner text-center">
              <div className="col-xs-12">
                <img
                  src={lockIcon}
                  alt="Paiement sécurisé"
                  className="mr-2"
                  height={18}
                  width={18}
                />
                <strong>Paiement 100% sécurisé</strong>
              </div>
            </div>
            <div className="col-xs-12">
              <div
                className="center-child-link"
                id="e36c503a-fa16-45d8-b180-be41115fd0e4"></div>
            </div>
          </Fragment>
        </MultipleStepsForm>
        <BookingFaqs type={faqType} babaPhoneNumber={babaPhoneNumber} />
      </div>
    </Sentry.ErrorBoundary>
  )
}

OrderCheckout.displayName = 'OrderCheckout'
export default OrderCheckout
