import React, { useState, useRef } from 'react'
import { map, reduce, sum, values, range } from 'lodash'
import * as Sentry from '@sentry/react'
import Modal from './Modal'
import QuantityPicker, { QuantityPickerValidator } from './QuantityPicker'
import DatePicker, { DatePickerValidator } from './DatePicker'
import TimePicker, { TimePickerValidator } from './TimePicker'
import OrderSummary from './OrderSummary'

const stepQuantity = {
  displayName: 'Participants',
  component: QuantityPicker,
  validator: QuantityPickerValidator,
  displayNextButton: true
}
const stepDatePicker = {
  displayName: 'Date',
  component: DatePicker,
  validator: DatePickerValidator,
  displayNextButton: false
}
const stepTimePicker = {
  displayName: 'Heure',
  component: TimePicker,
  validator: TimePickerValidator,
  displayNextButton: false
}
const stepOrderSummary = (publicQuoteId) => {
  return {
    displayName: publicQuoteId ? 'Ajout au devis' : 'Ajout au panier',
    component: OrderSummary,
    displayNextButton: true
  }
}

const defaultVariantQuantities = (relevantVariants) =>
  map(relevantVariants, (variant) => {
    return {
      id: variant.id,
      name: variant.name,
      description: variant.description,
      quantity_type: variant.quantity_type,
      option: variant.option,
      quantity: 0
    }
  })

const generateSteps = (giftMode, publicQuoteId) => {
  if (giftMode) {
    return [stepQuantity, stepOrderSummary(publicQuoteId)]
  } else {
    return [stepQuantity, stepDatePicker, stepTimePicker, stepOrderSummary(publicQuoteId)]
  }
}

const BookingModule = ({ giftMode, offer, activity, publicQuoteId = null }) => {
  const modalRef = useRef()
  const steps = generateSteps(giftMode, publicQuoteId)
  // steps is an array of checkout step objects which we can compose as we wish,
  // its index starts at 0
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [maxStepReachedIndex, setMaxStepReachedIndex] = useState(0)
  const [error, setError] = useState('')
  const [variantQuantities, setVariantQuantities] = useState(
    defaultVariantQuantities(offer.relevantVariants)
  )
  const [availabilities, setAvailabilities] = useState({})
  const [fetchedMonths, setFetchedMonths] = useState([])
  const [hourlyPrices, setHourlyPrices] = useState({})
  const [priceSummary, setPriceSummary] = useState(null)
  const [chosenDate, setChosenDate] = useState(null)
  const [chosenTime, setChosenTime] = useState(null)
  const [displayNextButton, setDisplayNextButton] = useState(true)

  const showModal = () => {
    $(modalRef.current).modal('show')
  }

  const nextStep = () => {
    const currentStep = steps[currentStepIndex]
    const validation = currentStep.validator(
      variantQuantities,
      chosenDate,
      chosenTime
    )

    if (validation && validation.valid) {
      setError('')
      const nextStepIndex = currentStepIndex + 1
      if (nextStepIndex !== steps.length - 1) {
        const nextStep = steps[nextStepIndex]
        const nextStepValidation = nextStep.validator(
          variantQuantities,
          chosenDate,
          chosenTime
        )
        if (nextStepValidation && nextStepValidation.valid) {
          setDisplayNextButton(true)
        } else {
          setDisplayNextButton(steps[nextStepIndex].displayNextButton)
        }
      } else {
        setDisplayNextButton(true)
      }
      if (maxStepReachedIndex !== steps.length - 1) {
        setMaxStepReachedIndex(nextStepIndex)
      }
      setCurrentStepIndex(nextStepIndex)
    } else {
      setError(validation.message)
    }
  }

  const submit = () => {
    const totalPricesPerVariant = reduce(
      priceSummary,
      (totalPrices, priceSummary, variantId) => {
        totalPrices[variantId] = sum(values(priceSummary))
        return totalPrices
      },
      {}
    )

    const data = {
      offer_id: offer.id,
      date: chosenDate,
      time: chosenTime,
      gift: giftMode,
      price_params: priceSummary,
      variants: reduce(
        variantQuantities,
        (variants, variant) => {
          variants[variant.id] = {
            quantity: variant.quantity,
            price: totalPricesPerVariant[variant.id],
            quantity_type: variant.quantity_type,
            option: variant.option
          }

          return variants
        },
        {}
      )
    }

    if (publicQuoteId) {
      $.ajax({
        url: `/admin/public_quotes/${publicQuoteId}/add_line_items`,
        method: 'POST',
        data: data,
        dataType: 'json',
        success: function (data) {
          if (data.redirect) {
            window.location.href = data.url
          }
        }
      })
    } else {
      $.ajax({
        url: '/order_checkout/initiate_from_params',
        method: 'POST',
        data: data,
        dataType: 'json',
        success: function (data) {
          if (data.redirect) {
            window.location.href = data.url
          }
        }
      })
    }
  }

  const goToStep = (stepIndex) => {
    setCurrentStepIndex(stepIndex)
    setDisplayNextButton(true)
    setError('')
  }

  const reset = () => {
    setCurrentStepIndex(0)
    setMaxStepReachedIndex(0)
    setError('')
    setVariantQuantities(defaultVariantQuantities(offer.relevantVariants))
    setPriceSummary({})
    setChosenDate('')
    setChosenTime('')
    setDisplayNextButton(true)
  }

  const renderError = () => {
    if (error) {
      return <div className="error">{error}</div>
    }
  }

  const renderSteps = () =>
    map(range(0, steps.length), (stepIndex) =>
      React.createElement(steps[stepIndex].component, {
        key: `step-${stepIndex}`,
        giftMode: giftMode,
        stepIndex: stepIndex,
        nextStep: nextStep,
        goToStep: goToStep,
        isCurrent: currentStepIndex === stepIndex,
        offer: offer,
        chosenDate: chosenDate,
        setChosenDate: setChosenDate,
        chosenTime: chosenTime,
        setChosenTime: setChosenTime,
        availabilities: availabilities,
        setAvailabilities: setAvailabilities,
        hourlyPrices: hourlyPrices,
        setHourlyPrices: setHourlyPrices,
        priceSummary: priceSummary,
        setPriceSummary: setPriceSummary,
        variantQuantities: variantQuantities,
        setVariantQuantities: setVariantQuantities,
        setError: setError,
        setMaxStepReachedIndex: setMaxStepReachedIndex,
        fetchedMonths: fetchedMonths,
        setFetchedMonths: setFetchedMonths
      })
    )

  const isLast = currentStepIndex === steps.length - 1
  return (
    <Sentry.ErrorBoundary fallback="An error has occured">
      <div className="booking-module">
        <div className="booking-module-container">
          <button
            onClick={showModal}
            className={`btn btn-primary btn-cta ${
              giftMode ? 'btn-outline pink' : ''
            }`}>
            {publicQuoteId
              ? 'Ajouter'
              : giftMode
              ? 'Offrir un cadeau'
              : 'Réserver une date'}
          </button>
        </div>
        <Modal
          modalId="booking-modal"
          giftMode={giftMode}
          activity={activity}
          infosFunnel={offer.infosFunnel}
          steps={steps}
          currentStepIndex={currentStepIndex}
          maxStepReachedIndex={maxStepReachedIndex}
          goToStep={goToStep}
          chosenDate={chosenDate}
          chosenTime={chosenTime}
          variantQuantities={variantQuantities}
          modalRef={modalRef}
          displayNextButton={displayNextButton}
          onButtonClick={isLast ? submit : nextStep}
          onClose={reset}
          publicQuoteId={publicQuoteId}
          priceSummary={priceSummary}>
          {renderError()}
          {renderSteps()}
        </Modal>
      </div>
    </Sentry.ErrorBoundary>
  )
}

export default BookingModule
