import apiHelper from './ApiHelper'

var MangopayCardRegistrar = function (delegate) {
  this.delegate = delegate
}
MangopayCardRegistrar.prototype.registerCard = function () {
  this.delegate.didStart()
  this._createCardRegistration()
}

MangopayCardRegistrar.prototype._createCardRegistration = function () {
  $.ajax({
    url: '/mangopay/reg_create',
    cache: false,
    data: { card_type: this.delegate.getCardType() }
  })
    .done(
      function (data) {
        this.cardRegistrationParams = data
        this.delegate.didCreateCardRegistration()
        this._doRegisterCard()
      }.bind(this)
    )
    .fail(
      function (error) {
        this.delegate.didFailWithMessage(this.translateError(error))
      }.bind(this)
    )
}

MangopayCardRegistrar.prototype._doRegisterCard = function () {
  var accessKey = this.cardRegistrationParams.AccessKey
  var preregistrationData = this.cardRegistrationParams.PreregistrationData
  var cardRegistrationURL = this.cardRegistrationParams.CardRegistrationURL
  var cardPreregistrationId = this.cardRegistrationParams.Id

  mangoPay.cardRegistration.init({
    Id: cardPreregistrationId,
    cardRegistrationURL: cardRegistrationURL,
    preregistrationData: preregistrationData,
    accessKey: accessKey
  })

  mangoPay.cardRegistration.registerCard(
    this.delegate.getCardData(),
    function (data) {
      this.delegate.didRegisterCard(data.UserId, data.CardId)
    }.bind(this),
    function (error) {
      this.delegate.didFailWithMessage(this.translateMangopayError(error))
    }.bind(this)
  )
}

MangopayCardRegistrar.prototype.translateMangopayError = function (
  mangopayError
) {
  return [apiHelper.parseMangopayError(mangopayError)]
}
MangopayCardRegistrar.prototype.translateError = function (mangopayError) {
  return [
    {
      code: 'XXX',
      field: 'CB',
      message: "Erreur lors de l'enregistrement de la Carte Bleue"
    }
  ]
}

export default MangopayCardRegistrar
