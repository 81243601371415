import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BabaForm from 'components/forms/Index'
import PasswordResetForm from 'components/users/PasswordResetForm'
import UserFormFields from './UserFormFields'
import NavigationButtons from 'components/bookings/NavigationButtons'
import OrderCommentsInput from 'components/bookings/OrderCommentsInput'

class CreateSessionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginMode: false,
      resetPasswordMode: false,
      successMessage: ''
    }
  }

  successSignIn = (data, status, xhr) => {
    this.props.userDidLogIn(data, false)
  }

  successSignUp = (data, status, xhr) => {
    this.props.userDidLogIn(data, true)
  }

  error = (xhr, status, error) => {
    this.context.stopLoading()
  }

  handleClick = (event, loginMode) => {
    event.preventDefault()
    this.setState({ loginMode: loginMode })
    if (!loginMode) {
      this.setState({ resetPasswordMode: false })
    }
  }

  closeAndGoPrevious = () => {
    this.setState({ loginMode: false })
    this.context.goPrevious()
  }

  handleOpenResetPassword = () => {
    this.setState({ resetPasswordMode: !this.state.resetPasswordMode })
  }

  handleResetPasswordSuccess = (successMessage) => {
    this.setState({ resetPasswordMode: false, successMessage: successMessage })
  }

  render() {
    const baseURL = this.state.loginMode
      ? this.props.urls.signIn
      : this.props.urls.signUp
    const method = 'POST'
    const formId = 'new_user'
    const actionURL = `${baseURL}.json`
    const successCallback = this.state.loginMode
      ? this.successSignIn
      : this.successSignUp
    const fieldsToShow = {
      email: !this.state.resetPasswordMode,
      password: !this.state.resetPasswordMode,
      fname: !this.state.loginMode,
      lname: !this.state.loginMode,
      phone: !this.state.loginMode,
      reset_password: this.state.loginMode
    }
    const shouldValidateFields = !this.state.loginMode

    const switchLoginSignInLink = this.state.loginMode ? (
      <Fragment>
        <div>
          <span className="mr-3">Pas encore inscrit(e) ?</span>
          <a className="light-blue" onClick={(e) => this.handleClick(e, false)}>
            Créer un compte
          </a>
          {!this.state.resetPasswordMode && (
            <div className="my-3">ou saisissez vos identifiants ci-dessous</div>
          )}
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div>
          <span className="mr-3">Déjà inscrit(e) ?</span>
          <a
            className="light-blue underline"
            onClick={(e) => this.handleClick(e, true)}>
            <strong>Se connecter</strong>
          </a>
        </div>
        <div className="my-3">ou saisissez vos informations ci-dessous</div>
      </Fragment>
    )

    const successAlert = this.state.successMessage ? (
      <div className="alert alert-success alert-dismissible" role="alert">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        {this.state.successMessage}
      </div>
    ) : null

    const displayOrderCommentsInput = this.props.order && !this.state.loginMode

    return (
      <Fragment>
        <div className="step-title">Vos coordonnées</div>
        <BabaForm.FormLine>{switchLoginSignInLink}</BabaForm.FormLine>
        {successAlert}
        <BabaForm.BabaForm
          name="user"
          remote
          didSubmit={this.context.startLoading}
          success={successCallback}
          error={this.error}
          id={formId}
          action={actionURL}
          method={method}
          authenticity_token={this.props.authenticityToken}
          errors={this.state.errors}>
          <input type="hidden" name="claim_gift" value={this.props.claimGift} />
          <UserFormFields
            show={fieldsToShow}
            user={this.props.user}
            urls={this.props.urls}
            shouldValidateFields={shouldValidateFields}
            authenticityToken={this.props.authenticityToken}
          />
          {!this.state.resetPasswordMode && (
            <Fragment>
              {this.state.loginMode && (
                <div>
                  <a
                    className="light-blue"
                    onClick={this.handleOpenResetPassword}>
                    Mot de passe oublié ?
                  </a>
                </div>
              )}
              {displayOrderCommentsInput && (
                <OrderCommentsInput
                  order={this.props.order}
                  claimGift={this.props.claimGift}
                />
              )}
              <NavigationButtons
                previousStepURL={this.props.previousStepURL}
                priceAfter={this.props.priceAfter}
                goPrevious={this.closeAndGoPrevious}
                submitLabel="Suivant"
                handleValidate={null}
              />
            </Fragment>
          )}

          {this.state.loginMode && this.state.resetPasswordMode && (
            <PasswordResetForm
              onOpenResetPassword={this.handleOpenResetPassword}
              onResetPasswordSuccess={this.handleResetPasswordSuccess}
              authenticityToken={this.props.authenticityToken}
              backToLoginMode={this.handleClick}
              urls={this.props.urls}
              previousStepURL={this.props.previousStepURL}
            />
          )}
        </BabaForm.BabaForm>
      </Fragment>
    )
  }
}

CreateSessionForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  urls: PropTypes.object.isRequired,
  priceAfter: PropTypes.string,
  claimGift: PropTypes.bool,
  user: PropTypes.object,
  order: PropTypes.object,
  userDidLogIn: PropTypes.func,
  previousStepURL: PropTypes.string
}
CreateSessionForm.contextTypes = {
  handleValidationSuccessful: PropTypes.func,
  startLoading: PropTypes.func,
  stopLoading: PropTypes.func,
  goPrevious: PropTypes.func
}

export default CreateSessionForm
