import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Configure,
  InstantSearch,
  Pagination,
  ScrollTo
} from 'react-instantsearch-dom'
import {
  connectHits,
  connectStateResults
} from 'react-instantsearch/connectors'
import CategoriesRefinementList from 'components/search/CategoriesRefinementList'
import CurrentRefinements from 'components/search/CurrentRefinements'
import Filter from 'components/search/Filter'
import KezakoBox from 'components/search/KezakoBox'
import ListFull from 'components/search/ListFull'
import LocationFilter from 'components/search/LocationFilter'
import MobileFilters from 'components/search/MobileFilters'
import SchemaOrg from 'components/search/SchemaOrg'
import SearchBox from 'components/search/SearchBox'
import SearchResultsReasonsBox from 'components/search/SearchResultsReasonsBox'
import SideFilters from 'components/search/SideFilters'
import SortBy from 'components/search/SortBy'
import Stats from 'components/search/Stats'
import AlgoliaHelper from 'lib/AlgoliaHelper'
import * as Sentry from '@sentry/react'
import algoliasearch from 'algoliasearch'
import 'instantsearch.css/themes/algolia.css'
import SearchMap from 'components/geo/leaflet/SearchMap'
import ShowFiltersButtonMobile from 'components/search/ShowFiltersButtonMobile'
import Faq from 'components/shared/Faq'
import ClearRefinementsMobile from 'components/search/ClearRefinementsMobile'

const SearchPage = ({
  initialHits,
  initialFilters,
  searchableCategories,
  searchState,
  metadata,
  faq,
  searchResults,
  showMap,
  setShowMap
}) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const openMobileMenu = useCallback(() => {
    setShowMobileMenu(true)
  }, [])

  const topRef = useRef(null)

  const { reasons_title, reasons_body, category_kezako, category_name } =
    metadata

  const currentResults = () => {
    return isFiltering() ? searchResults : initialHits
  }

  const isFiltering = () => {
    return Object.keys(searchState).length > 0
  }

  const locationFilter = () => (
    <LocationFilter
      showMap={showMap}
      initialFilters={initialFilters}
      defaultRefinement={getInitialGeoArea()}
    />
  )

  const getInitialGeoArea = () => {
    const geo = initialFilters.around

    if (!geo || typeof window === 'undefined') return

    const bounds = L.latLng(geo.lat, geo.lng).toBounds(geo.radius * 2 * 1000) // the params is a diameter

    return {
      northEast: bounds._northEast,
      southWest: bounds._southWest
    }
  }

  const featureFilter = () => {
    const eventFeatures = AlgoliaHelper.mapFromOriginalRefinementList(
      currentResults().facets.as_event_features,
      initialFilters.as_event_features
    )
    return (
      <Filter
        attribute="as_event_features"
        defaultLabel="Type d'évènement"
        defaultRefinement={initialFilters.as_event_features}
        initialItems={eventFeatures}
      />
    )
  }

  const categoryFilter = () => (
    <CategoriesRefinementList
      initialItems={searchableCategories}
      attribute="aa_categories"
      defaultRefinement={initialFilters.aa_categories}
      operator="and"
      limit={200}
    />
  )

  const searchBox = () => (
    <SearchBox defaultRefinement={initialFilters.text_search} />
  )
  const currentRefinement = () => <CurrentRefinements clearsQuery />
  const clearRefinementsMobile = () => <ClearRefinementsMobile clearsQuery />

  useEffect(() => {
    if (currentResults().hits.find((hit) => hit.type === 'promo')) {
      return
    }
    const promo = { type: 'promo' }

    const currentCategories =
      searchState.refinementList?.aa_categories ||
      (initialFilters.aa_categories && initialFilters.aa_categories[0])
    const currentFeature =
      searchState.refinementList?.as_event_features ||
      (initialFilters.as_event_features && initialFilters.as_event_features[0])

    if (currentCategories?.includes('Montgolfière')) {
      promo.node = 'hot-air-balloon'
    } else if (currentCategories?.includes('ULM')) {
      promo.node = 'ulm'
    } else if (currentCategories?.includes('Vol en hélicoptère')) {
      promo.node = 'helicopter'
    } else if (currentCategories?.includes('Parapente')) {
      promo.node = 'paragliding'
    } else if (currentCategories?.includes("Saut à l'élastique")) {
      promo.node = 'bungee-jumping'
    } else if (currentCategories?.includes('Saut en parachute')) {
      promo.node = 'parachute'
    } else if (currentCategories?.includes('Canyoning')) {
      promo.node = 'canyoning'
    } else if (currentCategories?.includes('Stage de survie')) {
      promo.node = 'survival'
    } else if (currentCategories?.includes('Auto')) {
      promo.node = 'racing'
    }

    if (!promo.node) {
      switch (currentFeature) {
        case 'Enterrement vie de Jeune Fille':
          promo.node = 'bachelorette'
          break
        case 'Enterrement vie de Garçon':
          promo.node = 'bachelor'
          break
        case 'Team building':
          promo.node = 'team'
          break
        default:
          promo.node = 'gift'
          break
      }
    }
    currentResults().hits.splice(1, 0, promo)
  }, [searchResults, initialHits, searchState, initialFilters])

  return (
    <Sentry.ErrorBoundary fallback="An error has occured">
      <SchemaOrg
        title={metadata.meta_title}
        description={metadata.meta_description}
        nbHits={currentResults().nbHits}
        ratingsAverage={
          initialHits.facets_stats &&
          initialHits.facets_stats['aa_rating_info.avg']
        }
        ratingsCount={
          initialHits.facets_stats &&
          initialHits.facets_stats['aa_rating_info.count']
        }
        priceInfo={
          initialHits.facets_stats &&
          initialHits.facets_stats['aa_pricing_info.offers.base']
        }>
        <SearchMap
          showMap={showMap}
          setShowMap={setShowMap}
          geoSearchParams={initialFilters.around}
        />
        <div className="container search-page">
          <ScrollTo>
            <MobileFilters
              showMobileMenu={showMobileMenu}
              closeMobileMenu={() => setShowMobileMenu(false)}
              locationFilter={locationFilter()}
              featureFilter={featureFilter()}
              categoryFilter={categoryFilter()}
              searchBox={searchBox()}
              nbHits={currentResults().nbHits}
              clearRefinementsMobile={clearRefinementsMobile()}
            />
          </ScrollTo>

          <div className="row searchPage-Results">
            <div className="searchPage-sideFilters col-sm-3 hidden-xs">
              <SideFilters categoryFilter={categoryFilter()} />
            </div>
            <div ref={topRef} className="searchPage-content col-sm-9">
              <div className="searchPage-topFilters hidden-xs row">
                <div className="col-xs-12 col-sm-4">{searchBox()}</div>
                <div className="col-xs-12 col-sm-4">{locationFilter()}</div>
                <div className="col-xs-12 col-sm-4">{featureFilter()}</div>
              </div>
              <div className="hidden-xs row my-4">
                <div className="col-xs-12">{currentRefinement()}</div>
              </div>
              <div className="content-head">
                <div>
                  <Stats nbHits={currentResults().nbHits} />
                  <button
                    type="button"
                    className="show-map btn btn-md btn-info btn-outline hidden-xs ml-3"
                    onClick={() => setShowMap(true)}>
                    <span className="glyphicon glyphicon-globe mr-1"></span>
                    Afficher la carte
                  </button>
                </div>

                <SortBy
                  defaultRefinement={AlgoliaHelper.getIndexName(
                    'Activity_popularity_desc'
                  )}
                  items={[
                    {
                      value: AlgoliaHelper.getIndexName(
                        'Activity_popularity_desc'
                      ),
                      label: 'Popularité'
                    },
                    {
                      value: AlgoliaHelper.getIndexName('Activity_note_desc'),
                      label: 'Note'
                    }
                  ]}
                />
              </div>
              <div className="row search-results-row">
                <ListFull hits={currentResults().hits} />
              </div>
              <Pagination />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {reasons_title && (
                <SearchResultsReasonsBox
                  title={reasons_title}
                  body={reasons_body}
                />
              )}
              {category_kezako && (
                <KezakoBox body={category_kezako} title={category_name} />
              )}
              {faq && <Faq title={faq.title} items={faq.items} />}
            </div>
          </div>
        </div>

        <div className="visible-xs mobile-fix-footer">
          <div className="row">
            <div className="col-xs-6">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={() => setShowMap(true)}>
                Afficher la carte
              </button>
            </div>
            <div className="col-xs-6">
              <ShowFiltersButtonMobile
                openMobileMenu={openMobileMenu}
                initialAroundLatLng={initialFilters.aroundLatLng}
              />
            </div>
          </div>
        </div>
      </SchemaOrg>
    </Sentry.ErrorBoundary>
  )
}

SearchPage.propTypes = {
  metadata: PropTypes.object,
  initialFilters: PropTypes.object,
  initialHits: PropTypes.object,
  searchState: PropTypes.object,
  searchableCategories: PropTypes.array,
  searchResults: PropTypes.object,
  faq: PropTypes.object,
  showMap: PropTypes.bool,
  setShowMap: PropTypes.func
}

const SearchPageWithResults = connectHits(connectStateResults(SearchPage))

const appId = process.env.ALGOLIA_APP_ID
const apiKey = process.env.ALGOLIA_SEARCH_API_KEY
const searchClient = algoliasearch(appId, apiKey)

const InstantSearchPage = (props) => {
  const [showMap, setShowMap] = useState(false)

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={AlgoliaHelper.getIndexName('Activity_popularity_desc')}>
      <Configure
        hitsPerPage={showMap ? 100 : props.initialHits.hitsPerPage}
        restrictSearchableAttributes={['name', 'aa_description']}
      />
      <SearchPageWithResults
        {...props}
        showMap={showMap}
        setShowMap={setShowMap}
      />
    </InstantSearch>
  )
}

InstantSearchPage.defaultProps = {
  initialFilters: {}
}

InstantSearchPage.propTypes = {
  initialFilers: PropTypes.object,
  initialHits: PropTypes.object
}

export default InstantSearchPage
