import React from 'react'
import { map } from 'lodash'
import display from 'lib/Display'
import { fullDateWithTime } from 'lib/TimeHelper'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'

const BookingHeader = ({
  steps,
  currentStepIndex,
  maxStepReachedIndex,
  goToStep,
  chosenDate,
  chosenTime,
  variantQuantities,
  activity,
  giftMode,
  infosFunnel
}) => {
  const headerStep = (step, stepIndex) => {
    if (stepIndex <= maxStepReachedIndex) {
      return (
        <div key={stepIndex} className="col-xs-3 step-index-container">
          <a className="link-unstyled" onClick={() => goToStep(stepIndex)}>
            <span
              className={classNames('step-index', {
                active: stepIndex === currentStepIndex
              })}>
              {stepIndex + 1}
            </span>
            <br className="visible-xs visible-sm" />
            {step.displayName}
          </a>
        </div>
      )
    } else {
      return (
        <div key={stepIndex} className="col-xs-3 step-index-container">
          <span className="step-index disabled">{stepIndex + 1}</span>
          <br className="visible-xs visible-sm" />
          <span className="baba-dark-grey">{step.displayName}</span>
        </div>
      )
    }
  }

  return (
    <div className="booking-recap">
      <div className="row mb-3">
        {map(steps, (step, stepIndex) => headerStep(step, stepIndex))}
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="hidden-xs hidden-sm mr-4 pull-left">
            <img
              className="img-responsive"
              src={activity.image}
              alt={activity.name}
            />
          </div>
          <div className="text-left">
            <strong className="">{activity.name}</strong>
            <div className="baba-dark-grey3 mb-3">
              {!giftMode && infosFunnel && (
                <div
                  className="baba-dark-grey infos-funnel"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(infosFunnel)
                  }}
                />
              )}
            </div>
            {map(
              variantQuantities.filter(
                (variantQuantity) => variantQuantity.quantity > 0
              ),
              (variantQuantity, index) => {
                return (
                  <div key={index} className="step-recap">
                    {variantQuantity.name}
                    {' : '}
                    {display.pluralizeQuantityType(
                      variantQuantity.quantity_type,
                      parseInt(variantQuantity.quantity),
                      true
                    )}
                  </div>
                )
              }
            )}
            {chosenDate && (
              <div className="step-recap">
                Le {fullDateWithTime(chosenDate, chosenTime)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingHeader
