import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'

var FormLine = createReactClass({
  displayName: 'FormLine',

  getDefaultProps: function () {
    return {
      hidden: false
    }
  },

  render: function () {
    var count = 0
    React.Children.forEach(this.props.children, function (el) {
      if (el) {
        count++
      }
    })
    var colSize = Math.floor(12 / count)
    var styles = classNames('col-sm-' + colSize, {
      hidden: this.props.hidden
    })
    var childrenNodes = React.Children.map(
      this.props.children,
      function (el, index) {
        if (el) {
          return (
            <div key={index} className={styles}>
              {el}
            </div>
          )
        } else {
          return null
        }
      }
    )
    return <div className="row">{childrenNodes}</div>
  }
})

export default FormLine
