import React, { Fragment } from 'react'
import createReactClass from 'create-react-class'
import { filter } from 'lodash'
import BabaForm from 'components/forms/Index'
import display from 'lib/Display'

var OrderPayWithGiftCardForm = createReactClass({
  displayName: 'OrderPayWithGiftCardForm',

  getInitialState: function () {
    return {
      showGiftCardForm: false
    }
  },

  handleAddGiftCardClick: function () {
    this.setState({ showGiftCardForm: !this.state.showGiftCardForm })
  },

  handleGiftCardTokenChange: function (object, field, value) {
    this.setState({ giftCardToken: value })
  },

  successGiftCard: function (data) {
    this.props.onOrderModification(data)
  },

  errorGiftCard: function (xhr, status, err) {
    this.setState({ isLoading: false })
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    this.setState({ isLoading: false })
  },

  handleGiftCardTokenValidate: function () {
    this.setState({ isLoading: true })
    $.ajax({
      url: this.props.urls.useGiftCardURL,
      method: 'POST',
      cache: false,
      data: { gift_card_order: { token: this.state.giftCardToken } },
      dataType: 'json',
      success: this.successGiftCard,
      error: this.errorGiftCard
    })
  },

  renderUsedGiftCard: function (el) {
    return (
      <div>
        Carte Cadeau{' '}
        <strong>
          {el.giftCardToken} - {display.currency(el.amount)}
        </strong>
      </div>
    )
  },

  render: function () {
    const showGiftCardArrow = this.state.showGiftCardForm ? (
      <span className="glyphicon glyphicon-menu-up" />
    ) : (
      <span className="glyphicon glyphicon-menu-down" />
    )
    const giftCardPayments = filter(this.props.order.payments, function (c) {
      return c.giftCardToken !== undefined
    })
    const errorMessage = this.props.order.giftCardErrorMessage
    const giftCardNodes = giftCardPayments.map(this.renderUsedGiftCard)

    return (
      <Fragment>
        <div className="clickable mb-2" onClick={this.handleAddGiftCardClick}>
          Utiliser une carte cadeau
          {showGiftCardArrow}
        </div>
        <div className={this.state.showGiftCardForm ? '' : 'hidden'}>
          <div className="row">
            <div className="col-xs-6">
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="text"
                  objectName="order"
                  fieldName="gift_card"
                  validate={false}
                  placeHolder="Numéro de la carte"
                  hideLabel={true}
                  onChange={this.handleGiftCardTokenChange}
                  required={false}
                  onEnterPress={this.handleGiftCardTokenValidate}
                />
              </BabaForm.FormLine>
            </div>
            <div className="col-xs-6">
              <button
                onClick={this.handleGiftCardTokenValidate}
                className="btn btn-primary inline-btn">
                Valider
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {errorMessage && (
            <div className="col-xs-12 text-danger">{errorMessage}</div>
          )}
          <div className="col-xs-12">{giftCardNodes}</div>
        </div>
      </Fragment>
    )
  }
})
export default OrderPayWithGiftCardForm
