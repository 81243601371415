import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Star from 'images/icons/star.svg'
import HalfStar from 'images/icons/half-star.svg'
import EmptyStar from 'images/icons/empty-star.svg'
const STARS = [EmptyStar, HalfStar, Star]

const computeRating = (rating) => {
  const formattedRating = Math.round(rating * 2) / 2
  const stars = []
  for (let index = 1; index <= 5; index++) {
    let star = 2
    if (formattedRating < index) {
      star = formattedRating === index - 0.5 ? 1 : 0
    }
    stars.push(star)
  }
  return stars
}

const NoRatings = () => (
  <div className="stars-container">
    <ul className="list-inline">
      {[...Array(5).keys()].map((index) => (
        <li key={index}>
          <img src={Star} alt="Note de l'activité" />
        </li>
      ))}
    </ul>
  </div>
)

const Rating = (ratingInfo) => {
  if (!ratingInfo.rating) {
    return <NoRatings />
  }
  return (
    <Fragment>
      <div>
        <ul className="list-inline">
          {computeRating(ratingInfo.rating.avg).map((starIndex, index) => (
            <li key={index}>
              <img src={STARS[starIndex]} alt="Note de l'activité" />
            </li>
          ))}
        </ul>
      </div>
      <div>
        <strong>{ratingInfo.rating.avg}</strong>&nbsp;
        {`(${ratingInfo.rating.count} avis)`}
      </div>
    </Fragment>
  )
}

Rating.propTypes = {
  ratingInfo: PropTypes.object
}

export default Rating
