import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { minBy, maxBy, map, capitalize } from 'lodash'

import Store from './store/Store'
import PricePolicyForm from './PricePolicyForm'
import calendarIcon from 'images/icons/calendar.svg'
import editIcon from 'images/icons/edit-pen.svg'

class Season extends Component {
  constructor(props) {
    super(props)

    this.store = new Store(
      {
        season: props.season,
        activity: props.activity,
        offer: props.offer
      },
      this.props.form_authenticity_token
    )

    this.state = {
      saving: false,
      season: this.store.season,
      offer: this.store.offer,
      isEditingSeason: false,
      editedPricePolicyId: null,
      errorMessage: null
    }
  }

  savePricePolicyRemovalToStore(pricePolicyId) {
    this.setState({ saving: true })
    this.store.destroyPricePolicy(pricePolicyId).then((response) => {
      if (response) {
        window.scrollTo(0, 0) // scroll top
        this.setState({ errorMessage: response.error.message, saving: false })
      }
    })

    // Forces rerender
    this.setState({ store: this.store })
  }

  savePricePolicyToStore() {
    this.setState({ saving: true })
    this.store
      .savePricePolicy(this.state.editedPricePolicyId)
      .then((response) => {
        if (response) {
          window.scrollTo(0, 0) // scroll top
          this.setState({ errorMessage: response.error.message, saving: false })
        }
      })

    // Forces rerender
    this.setState({ store: this.store })
  }

  saveSeasonToStore() {
    this.store.saveSeason().then((response) => {
      if (response) {
        window.scrollTo(0, 0) // scroll top
        this.setState({ errorMessage: response.error.message })
      }
    })

    // Forces rerender
    this.setState({ store: this.store })
  }

  createPricePolicy(e) {
    const newPolicy = this.store.offer.buildPricePolicy(this.state.season.id)
    this.setState({ editedPricePolicyId: newPolicy.id })
  }

  editPricePolicy(e, pricePolicyId) {
    if (this.state.editedPricePolicyId === pricePolicyId) {
      this.setState({ editedPricePolicyId: null })
    } else {
      this.setState({ editedPricePolicyId: pricePolicyId })
    }
  }

  deletePricePolicy(e, pricePolicyId) {
    if (
      confirm(
        'Êtes-vous sûr ? \n' +
          'Une fois la programmation supprimée, impossible de revenir en arrière !'
      ) === true
    ) {
      this.store.offer.deletePricePolicy(pricePolicyId)
      this.setState({ editedPricePolicyId: null })

      this.savePricePolicyRemovalToStore(pricePolicyId)
    }
  }

  saveSeason() {
    this.toggleSeasonEdition()

    this.store.season = this.state.season
    this.saveSeasonToStore()
  }

  deleteSeason() {
    if (
      confirm(
        'Êtes-vous sûr ? \n' +
          'Si vous supprimez cette saison, cela la supprimera pour toutes les autres offres de cette activité !',
      ) === true
    ) {
      this.store.destroySeason()
    }
  }

  toggleSeasonEdition(e) {
    this.setState({ isEditingSeason: !this.state.isEditingSeason })
  }

  updateSeason(e, field) {
    var { season } = this.state

    season[field] = e.target.value

    this.setState({ season })
  }

  renderPricePolicyForm(pricePolicy) {
    const { editedPricePolicyId } = this.state

    if (editedPricePolicyId === pricePolicy.id) {
      return (
        <PricePolicyForm
          pricePolicy={this.store.offer.findPricePolicy(pricePolicy.id)}
          store={this.store}
          season={this.state.season}
        />
      )
    }
  }

  formatVariantPriceRange(priceTiers) {
    const min = minBy(priceTiers, (pt) => pt.lower_echelon)
    const max = maxBy(priceTiers, (pt) => pt.upper_echelon)

    if (min && max && min.price !== max.price) {
      return `${min.price} € - ${max.price} €`
    } else {
      return `${min ? min.price : 0} €`
    }
  }

  renderErrors() {
    if (this.state.errorMessage) {
      return <div className="errors">{this.state.errorMessage}</div>
    }
  }

  render() {
    const { offer, season } = this.state

    if (this.state.isEditingSeason) {
      var seasonHeader = (
        <div>
          <img className="icon" src={calendarIcon} />
          <input
            type="text"
            onChange={(e) => this.updateSeason(e, 'name')}
            value={season.name}
          />
          &nbsp;:&nbsp;
          <input
            type="date"
            onChange={(e) => this.updateSeason(e, 'begin_date')}
            value={season.begin_date}
          />
          &nbsp;-&nbsp;
          <input
            type="date"
            onChange={(e) => this.updateSeason(e, 'end_date')}
            value={season.end_date}
          />
          <button
            className="btn btn-primary btn-floating btn-save-season"
            onClick={(e) => this.saveSeason()}>
            Sauvegarder
          </button>
          <button
            className="btn btn-secondary btn-floating btn-cancel"
            onClick={(e) => this.toggleSeasonEdition()}>
            Annuler
          </button>
        </div>
      )
    } else {
      var seasonHeader = (
        <div>
          <img className="icon" src={calendarIcon} />{' '}
          <strong>{season.name}</strong> :{' '}
          {moment(season.begin_date).format('LL')} -{' '}
          {moment(season.end_date).format('LL')}
          <button
            className="btn btn-icon btn-floating pull-left"
            onClick={(e) => this.toggleSeasonEdition()}>
            <img src={editIcon} alt="edit" />
          </button>
          <a
            className="btn btn-delete btn-floating"
            onClick={(e) => this.deleteSeason()}>
            X
          </a>
        </div>
      )
    }

    return (
      <div className="form-season">
        <div className="season-header">{seasonHeader}</div>
        {this.renderErrors()}
        <div className="policies-list">
          {offer.pricePoliciesForSeason(season).map((pricePolicy) => {
            return (
              <Fragment key={`policy-${pricePolicy.id}`}>
                <div className="policy-summary">
                  <div className="days-summary">
                    <strong>{pricePolicy.time_mask.formattedDays()}</strong>
                  </div>
                  <div className="timeslots-summary">
                    {pricePolicy.time_mask.formattedTimeslots()}
                  </div>
                  <div className="variants-summary">
                    {map(
                      pricePolicy.priceTiersByVariant(),
                      (priceTiers, variantId) => (
                        <span
                          className="summary-element"
                          key={`variant-summary-${variantId}`}>
                          {capitalize(priceTiers[0].price_variant.name)} :{' '}
                          {this.formatVariantPriceRange(priceTiers)}
                        </span>
                      )
                    )}
                  </div>
                  <a
                    className="btn btn-delete btn-floating"
                    onClick={(e) => this.deletePricePolicy(e, pricePolicy.id)}>
                    X
                  </a>
                  <button
                    className="btn btn-icon btn-floating pull-left"
                    onClick={(e) => this.editPricePolicy(e, pricePolicy.id)}>
                    <img src={editIcon} alt="edit" />
                  </button>
                </div>
                <div className="policy-form-container">
                  {this.renderPricePolicyForm(pricePolicy)}
                </div>
              </Fragment>
            )
          })}
        </div>
        <button
          className="btn btn-tertiary add-price-policy"
          onClick={(e) => this.createPricePolicy(e)}>
          + Ajouter une programmation
        </button>
        {this.state.editedPricePolicyId ? (
          <button
            className={`btn btn-tertiary btn-save ${
              this.state.saving ? 'btn-saving' : ''
            }`}
            onClick={(e) => this.savePricePolicyToStore(e)}>
            {this.state.saving ? (
              <span className="save-loader-text">Sauvegarde...</span>
            ) : (
              'Enregistrer'
            )}
          </button>
        ) : (
          ''
        )}
      </div>
    )
  }
}

Season.propTypes = {
  activity: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  season: PropTypes.object.isRequired
}

Season.defaultProps = {}

export default Season
