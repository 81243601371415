import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BookingFaqsAccordion from 'components/bookings/BookingFaqsAccordion'

class BookingFaqs extends Component {
  render() {
    const title = "Besoin d'aide ?"

    let q1 = ''
    let a1 = ''
    let q2 = ''
    let a2 = ''
    let q3 = ''
    let a3 = ''
    let q4 = ''
    let a4 = ''
    let q5 = ''
    let a5 = ''
    let q6 = ''
    let a6 = ''
    let faqs = []
    if (this.props.type === 'gift_card') {
      q1 = 'Sous quel format la carte cadeau est-elle disponible ?'
      a1 =
        'Vous recevrez immédiatement après avoir finalisé votre achat un e-mail contenant une pièce jointe au format PDF. Vous pourrez donc l’imprimer directement.'
      q2 = 'Quelle est la durée de validité d’une carte cadeau ?'
      a2 = 'La carte cadeau est valable 1 an et renouvelable 12 mois. '
      q3 = 'Mon paiement est-il sécurisé ?'
      a3 =
        'Le cadenas en haut à gauche de votre navigateur et le « https » certifie que vous êtes sur une page web utilisant un protocole sécurisé.\n' +
        'Le paiement est entièrement sécurisé et opéré par notre partenaire le Crédit Mutuel, vos données bancaires sont anonymes et ne sont pas sauvegardées sur notre plateforme.'
      faqs = [
        [q1, a1],
        [q2, a2],
        [q3, a3]
      ]
    } else if (this.props.type === 'order') {
      q1 = 'Vais-je payer des frais de réservation ?'
      a1 =
        "Vous ne payez aucun frais de réservation sur Babasport. Tous nos prix sont garantis et les mêmes qu'en direct avec le partenaire."
      q2 = 'Est-ce que je peux réserver à la dernière minute ?  '
      a2 =
        "La plupart des activités peuvent être réservées à la dernière minute en fonction des disponibilités. Faites votre demande, on s'occupe du reste !"
      q3 =
        "Où trouver l'adresse précise du rendez-vous pour faire l'activité ?  "
      a3 =
        "L'adresse précise du lieu de votre activité vous est communiquée dans le mail de confirmation de réservation que vous recevrez après avoir finalisé votre réservation."
      q4 = '' + 'Mon paiement est-il sécurisé ?'
      a4 =
        'Le cadenas en haut à gauche de votre navigateur et le « https » certifient que vous êtes sur une page web utilisant un protocole sécurisé.\n' +
        'Le paiement est entièrement sécurisé et opéré par notre partenaire le Crédit Mutuel, vos données bancaires sont anonymes et ne sont pas sauvegardées sur notre plateforme.'
      q5 = 'A quel moment ma carte de crédit est-elle débitée ?'
      a5 =
        'Vous serez débité lorsque votre demande de réservation sera acceptée, pas avant.\n' +
        'Lorsque vous indiquez vos informations bancaires, vous faites une autorisation de prélèvement. \n' +
        "Ce n'est qu'une fois votre demande confirmée que le montant sera débité."
      q6 = 'Comment saurais-je si ma réservation est confirmée ?'
      a6 =
        'Vous réalisez une demande de réservation qui sera envoyée à notre partenaire. Cette demande sera confirmée en général sous quelques heures, et dans un délai max de 48h. Une fois confirmée vous recevrez alors votre bon de réservation par email.'
      faqs = [
        [q1, a1],
        [q2, a2],
        [q3, a3],
        [q4, a4],
        [q5, a5],
        [q6, a6]
      ]
    } else if (this.props.type === 'gift_order') {
      q1 = "Quand reçoit-on le bon cadeau qu'on a commandé ?"
      a1 =
        "Dès que la commande est finalisée, vous recevez un email de confirmation qui vous permet de télécharger immédiatement votre bon cadeau. Si vous avez choisi l'option pochette cadeau, le bon quittera nos entrepots d'ici 2 jours ouvrés."
      q2 = 'Quelle est la durée de validité d’un bon cadeau ?'
      a2 = 'Le bon cadeau est valable 1 an et renouvelable 12 mois.'
      q3 = 'Mon paiement est-il sécurisé ?'
      a3 =
        'Le cadenas en haut à gauche de votre navigateur et le « https » certifie que vous êtes sur une page web utilisant un protocole sécurisé.\n' +
        'Le paiement est entièrement sécurisé et opéré par notre partenaire le Crédit Mutuel, vos données bancaires sont anonymes et ne sont pas sauvegardées sur notre plateforme.'
      q4 =
        "Où trouver l'adresse précise du rendez-vous pour faire l'activité ? "
      a4 =
        "L'adresse précise du lieu de l'activité sera communiquée au bénéficiaire du bon cadeau dans son mail de confirmation de réservation, qu'il recevra après avoir choisi une date."
      faqs = [
        [q1, a1],
        [q2, a2],
        [q3, a3],
        [q4, a4]
      ]
    }

    return (
      <div className="funnel-faqs p-3">
        <div className="mb-4">
          <strong>{title}</strong>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-8">
            <BookingFaqsAccordion faqs={faqs} />
          </div>
          <div className="col-xs-12 col-sm-4 p-2">
            <div className="row">
              <div className="col-xs-12">
                {this.props.babaPhoneNumber && (
                  <Fragment>
                    <div>
                      <strong>Contactez notre service client</strong>
                    </div>
                    <div>À votre écoute du Lundi au Vendredi de 9h à 19h.</div>
                    <div className="mt-2">
                      <span className="glyphicon glyphicon-earphone" />
                      <a
                        className="customer-service-link"
                        href={`tel:${this.props.babaPhoneNumber.replace(
                          /\s/g,
                          ''
                        )}`}>
                        {this.props.babaPhoneNumber}
                      </a>
                      <span> - Appel local non surtaxé</span>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

BookingFaqs.propTypes = {
  type: PropTypes.string
}

export default BookingFaqs
