import React from 'react'
import createReactClass from 'create-react-class'
import BabaLabel from './BabaLabel'

var BabaRadio = createReactClass({
  displayName: 'BabaRadio',

  getDefaultProps: function () {
    return {
      hidden: false
    }
  },

  render: function () {
    var actualInputType = this.props.hidden ? 'hidden' : 'radio'
    var inputName = this.props.objectName + '[' + this.props.fieldName + ']'
    var inputIdentifier =
      this.props.objectName +
      '_' +
      this.props.fieldName +
      '_' +
      this.props.value
    var defaultChecked = this.props.defaultCheckedValue === this.props.value
    return (
      <div>
        <input
          type={actualInputType}
          value={this.props.value}
          name={inputName}
          id={inputIdentifier}
          defaultChecked={defaultChecked}
        />
        {!this.props.hidden && (
          <BabaLabel
            htmlFor={inputIdentifier}
            label={this.props.label}
            className="radio-label"
          />
        )}
      </div>
    )
  }
})

export default BabaRadio
