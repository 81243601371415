import React, { useState } from 'react'
import PropTypes from 'prop-types'
import BabaForm from 'components/forms/Index'

const AdminPublicQuoteForm = ({ authenticityToken, publicQuote }) => {
  const baseURL = '/admin/public_quotes'
  const isExistingRecord = publicQuote.id !== null
  const method = isExistingRecord ? 'PATCH' : 'POST'
  const formURL = isExistingRecord ? baseURL + '/' + publicQuote.id : baseURL
  const formId = isExistingRecord ? 'edit_public_quote' : 'new_public_quote'
  const [onlineChecked, setOnlineChecked] = useState(publicQuote.online)

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-8">
          <BabaForm.BabaForm
            id={formId}
            action={formURL}
            method={method}
            authenticity_token={authenticityToken}>
            <div className="form-group">
              <BabaForm.FormLine>
                <BabaForm.BabaLabel label="Type d'évènement" />
                <select
                  className="form-control"
                  id="public_quote_event_type"
                  name="public_quote[event_type]"
                  defaultValue={publicQuote.event_type}
                  required={true}>
                  <option value="evg">EVG</option>
                  <option value="evjf">EVJF</option>
                </select>
              </BabaForm.FormLine>
            </div>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="text"
                objectName="public_quote"
                fieldName="public_title"
                defaultValue={publicQuote.public_title}
                placeHolder="Titre publique"
                required
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="text"
                objectName="public_quote"
                fieldName="admin_title"
                defaultValue={publicQuote.admin_title}
                placeHolder="Titre administrateur"
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="text"
                objectName="public_quote"
                fieldName="forename"
                placeHolder="Prénom"
                defaultValue={publicQuote.forename}
              />

              <BabaForm.BabaInput
                type="text"
                objectName="public_quote"
                fieldName="name"
                defaultValue={publicQuote.name}
                placeHolder="Nom"
              />
            </BabaForm.FormLine>

            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="email"
                objectName="public_quote"
                fieldName="email"
                defaultValue={publicQuote.email}
                placeHolder="Email"
              />
              <BabaForm.BabaInput
                type="tel"
                objectName="public_quote"
                fieldName="phone"
                defaultValue={publicQuote.phone}
                placeHolder="Téléphone"
              />
            </BabaForm.FormLine>

            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="text"
                objectName="public_quote"
                fieldName="localisation"
                defaultValue={publicQuote.localisation}
                placeHolder="Ville / Région souhaitée"
              />
            </BabaForm.FormLine>

            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="number"
                objectName="public_quote"
                fieldName="participants_count"
                defaultValue={publicQuote.participants_count || 2}
                placeHolder="Nombre de personnes"
                required
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="textarea"
                objectName="public_quote"
                fieldName="detailed_request"
                defaultValue={publicQuote.detailed_request}
                placeHolder="La demande :"
                rows={7}
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="textarea"
                objectName="public_quote"
                fieldName="admin_notes"
                defaultValue={publicQuote.admin_notes}
                placeHolder="Notes des admins sur la demande"
                rows={7}
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaLabel
                htmlFor="public_quote_desired_date"
                label="Date demandée"
              />
              <BabaForm.BabaDatePicker
                datepickerId="admin-public-quote-desired-date"
                date={publicQuote.desired_date}
                objectName="public_quote"
                fieldName="desired_date"
                showClearDate
                allowPastDates
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="text"
                objectName="public_quote"
                fieldName="desired_duration"
                defaultValue={publicQuote.desired_duration}
                placeHolder="Durée demandée"
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="text"
                objectName="public_quote"
                fieldName="desired_budget"
                defaultValue={publicQuote.desired_budget}
                placeHolder="Budget demandée"
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="number"
                objectName="public_quote"
                fieldName="promotion_amount"
                step={0.01}
                defaultValue={publicQuote.promotion_amount}
                placeHolder="Promotion (€)"
              />
            </BabaForm.FormLine>
            <BabaForm.FormLine>
              <BabaForm.BabaInput
                type="textarea"
                objectName="public_quote"
                fieldName="payment_terms"
                row={5}
                defaultValue={publicQuote.payment_terms}
                placeHolder="Conditions de paiement"
              />
            </BabaForm.FormLine>
            <div className="checkbox">
              <label>
                <input
                  type="hidden"
                  name="public_quote[online]"
                  value={false}
                />
                <input
                  type="checkbox"
                  name="public_quote[online]"
                  id="public_quote_online"
                  checked={onlineChecked}
                  onClick={() => {
                    setOnlineChecked(!onlineChecked)
                  }}
                />
                En ligne
              </label>
            </div>

            <BabaForm.FormLine>
              <BabaForm.BabaSubmit label="Enregistrer" />
            </BabaForm.FormLine>
          </BabaForm.BabaForm>
        </div>
      </div>
    </div>
  )
}

AdminPublicQuoteForm.propTypes = {
  authenticityToken: PropTypes.string,
  publicQuote: PropTypes.object
}

export default AdminPublicQuoteForm
