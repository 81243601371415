import React from 'react'
import createReactClass from 'create-react-class'

var BabaErrors = createReactClass({
  render: function () {
    // TODO REFACTOR DUPLICATE CODE WITH BABAFORM ?
    var errorNode =
      !this.props.errors || this.props.errors.length === 0 ? null : (
        <div>
          <div className="alert alert-danger" role="alert">
            <span className="sr-only">Error:</span>
            <ul>
              {this.props.errors.map(function (el, i) {
                var label = el.field + ' : ' + el.message
                return <li key={i}>{label}</li>
              })}
            </ul>
          </div>
        </div>
      )
    return errorNode
  }
})

export default BabaErrors
