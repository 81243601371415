import React from 'react'
import PropTypes from 'prop-types'
import BookingFaqsAccordionPanel from 'components/bookings/BookingFaqsAccordionPanel'

class BookingFaqsAccordion extends React.Component {
  render() {
    const faqs = this.props.faqs
    const accordionId = 'faq-accordion'
    let i
    const panels = []
    for (i in faqs) {
      panels.push(
        <BookingFaqsAccordionPanel
          key={i}
          parentId={accordionId}
          headingId={'faqHeading' + i}
          collapseId={'faqCollapse' + i}
          question={faqs[i][0]}
          answer={faqs[i][1]}
        />
      )
    }
    return (
      <div
        className="row checkout-faq-row"
        id={accordionId}
        role="tablist"
        aria-multiselectable="true">
        {panels}
      </div>
    )
  }
}

BookingFaqsAccordion.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.array)
}

export default BookingFaqsAccordion
