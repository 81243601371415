import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import createReactClass from 'create-react-class'
import BabaForm from 'components/forms/Index'
import CardRegistrationForm from './CardRegistrationForm'
import OrderPayWithGiftCardForm from './OrderPayWithGiftCardForm'
import OrderCheckoutValidationCouponForm from './OrderCheckoutValidationCouponForm'
import display from 'lib/Display'
import apiHelper from 'lib/ApiHelper'
import MangopayHelper from 'lib/MangopayHelper'
import googleTracker from 'lib/trackers/googleTracker'
import NavigationButtons from 'components/bookings/NavigationButtons'

var PaymentForm = createReactClass({
  displayName: 'PaymentForm',

  contextTypes: {
    handleValidationSuccessful: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
    pushComponent: PropTypes.func
  },

  getInitialState: function () {
    const initialState = this.recomputeState(this.props.order)
    if (this.props.order.paymentErrorMessage) {
      initialState.errors = [
        { field: 'Erreur', message: this.props.order.paymentErrorMessage }
      ]
    } else {
      initialState.errors = []
    }
    return initialState
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    this.setState(this.recomputeState(nextProps.order))
  },

  recomputeState: function (order) {
    return {
      paymentSuccess: this.isPaymentSuccess(order),
      paymentError: this.isPaymentError(order),
      showPaymentForm: this.hasToPay(order)
    }
  },

  hasToPay: function (order) {
    return (
      order.orderStatus === 'in_creation' ||
      order.orderStatus === 'pre_auth_error' ||
      order.orderStatus === 'payment_error'
    )
  },
  isPaymentSuccess: function (order) {
    return (
      order.orderStatus !== 'in_creation' &&
      order.orderStatus !== 'pre_auth_error' &&
      order.orderStatus !== 'payment_error'
    )
  },
  isPaymentError: function (order) {
    return (
      order.orderStatus === 'pre_auth_error' ||
      order.orderStatus === 'payment_error'
    )
  },

  getDefaultProps: function () {
    return {
      giftCardMode: false
    }
  },
  showOverlay: function () {
    this.context.startLoading()
  },
  errorOverlay: function (response) {
    this.context.stopLoading()
    this.setState({ errors: apiHelper.parseErrorResponse(response) })
  },
  resetErrors: function () {
    this.setState({ errors: [] })
  },
  paymentSuccess: function (data) {
    this.context.stopLoading()
    this.setState({ paymentSuccess: true })
  },

  handleSubmit: function () {
    if (this.state.paymentSuccess) {
      if (this.context.handleValidationSuccessful) {
        this.context.handleValidationSuccessful()
      }
    }
  },

  handleValidate: function (event) {
    this.finalizeCheckout()
  },

  cardRegistrationSuccess: function (mangopayId, cardId) {
    this.finalizeCheckout({
      mangopayId: mangopayId,
      cardId: cardId,
      mangopay_browser_info: MangopayHelper.getBrowserInfo(navigator, screen)
    })
  },

  finalizeCheckout: function (data) {
    if (this.props.trackingInfo) {
      googleTracker.trackAddPaymentInfo(this.props.trackingInfo)
    }
    var paymentSuccessFn = this.paymentSuccess
    var showOverlayFn = this.showOverlay
    var errorOverlayFn = this.errorOverlay
    var proceedPaymentUrl = this.props.urls.finalizeOrderURL

    this.resetErrors()
    showOverlayFn()

    $.ajax({
      url: proceedPaymentUrl,
      type: 'POST',
      cache: false,
      data: data
    })
      .done(function (data) {
        if (data.redirect) {
          window.location.href = data.url
        } else {
          paymentSuccessFn(data)
        }
      })
      .fail(function (msg) {
        errorOverlayFn(msg)
      })
  },

  render: function () {
    var callToActionText = `Payer ${display.currency(
      this.props.order.paymentPolicy.leftToPayOnline
    )}`
    var leftToPayOnline = parseFloat(
      this.props.order.paymentPolicy.leftToPayOnline
    )
    var actualPaymentNode = null
    if (leftToPayOnline > 0) {
      actualPaymentNode = (
        <CardRegistrationForm
          callToActionText={callToActionText}
          startLoading={this.context.startLoading}
          stopLoading={this.context.stopLoading}
          cardRegistrationSuccess={this.cardRegistrationSuccess}
          images={this.props.images}
          isGift={this.props.order.isGift}
          urls={this.props.urls}
          priceAfter={this.props.order.priceAfter}
        />
      )
    } else {
      actualPaymentNode = (
        <NavigationButtons
          priceAfter={this.props.order.priceAfter}
          goPrevious={this.context.goPrevious}
          submitLabel="Valider la demande"
          handleValidate={this.handleValidate}
        />
      )
    }

    if (this.state.showPaymentForm) {
      return (
        <div data-rspec="checkout-payment-container">
          <div className="step-title">Paiement</div>
          <div className="row">
            <div className="col-xs-12">
              <BabaForm.BabaErrors errors={this.state.errors} />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xs-12">
              <OrderPayWithGiftCardForm
                order={this.props.order}
                urls={this.props.urls}
                onOrderModification={this.props.onOrderModification}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xs-12">
              <OrderCheckoutValidationCouponForm
                order={this.props.order}
                onOrderModification={this.props.onOrderModification}
                urls={this.props.urls}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">{actualPaymentNode}</div>
          </div>
        </div>
      )
    } else if (this.state.paymentSuccess) {
      if (this.props.order.isGift) {
        return (
          <Fragment>
            <p>
              <strong>Merci pour votre achat et votre confiance.</strong>
            </p>
            <p>
              Vous allez recevoir par e-mail le bon cadeau avec votre message
              personnalisé si vous l’avez spécifié.
            </p>
            <p>
              Vous pourrez ainsi directement l’imprimer pour le donner en main
              propre ou transférer l’e-mail directement au bénéficiaire.
            </p>
            <p>
              Vous avez également la possibilité de télécharger le bon cadeau en
              vous rendant{' '}
              <a href={this.props.urls.orderURL}>
                {' '}
                sur la page dédiée à votre bon cadeau
              </a>
              .
            </p>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <p>
              <strong>
                Merci pour votre demande de réservation, celle-ci a bien été
                enregistrée.
              </strong>
            </p>
            <p>
              Un récapitulatif est consultable{' '}
              <a href={this.props.urls.orderURL}>
                sur la page dédiée à votre réservation
              </a>
              .
            </p>
            <p>
              Nous l’avons transmise à notre partenaire qui va pouvoir confirmer
              sa disponibilité aussi vite que possible.
            </p>
            <p>
              <strong>
                Votre carte de crédit sera débitée uniquement si notre
                partenaire confirme votre réservation.
              </strong>
            </p>
            <p>
              Une fois celle-ci confirmée, vous recevrez un e-mail contenant
              toutes les informations nécessaires (adresse, numéro de
              l’animateur …) au bon déroulement de votre activité.
            </p>
            <p>
              Si l’animateur n’est pas disponible au créneau demandé, il pourra
              soit vous proposer un nouveau rendez-vous, soit refuser votre
              demande, auquel cas aucun prélèvement ne sera effectué sur votre
              carte bancaire.
            </p>
          </Fragment>
        )
      }
    }
  }
})

export default PaymentForm
