import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import BabaInput from './BabaInput'
import moment from 'moment'
import { SingleDatePicker, isInclusivelyAfterDay } from 'react-dates'
import BabaLabel from './BabaLabel'
import * as Sentry from '@sentry/react'

class BabaDatePicker extends React.Component {
  constructor(props) {
    super(props)
    let initDate = null
    if (props.date) {
      initDate = moment(props.date)
      initDate = initDate.isValid() ? initDate : null
    }
    this.state = {
      date: initDate,
      datePickerFocused: false
    }
  }

  handleFocusChange = (bool) => {
    const shouldFocus = bool.focused === true
    const shouldChange =
      bool.focused !== null && shouldFocus !== this.state.datePickerFocused
    if (shouldChange) {
      this.setState({
        datePickerFocused: shouldFocus
      })
    }
  }

  handleDateChange = (date) => {
    this.setState({
      date: date,
      datePickerFocused: false
    })
  }

  handleDayBlocked = (day) => {
    return !this.props.openDays[day.isoWeekday()]
  }

  isOutsideRange = (day) => {
    if (this.props.allowPastDates) {
      return false
    } else {
      return !isInclusivelyAfterDay(day, moment())
    }
  }

  render() {
    const hiddenValueStoreNode = (
      <BabaInput
        type="hidden"
        objectName={this.props.objectName}
        fieldName={this.props.fieldName}
        controlled
        value={this.state.date ? this.state.date.format('DD-MM-YYYY') : null}
      />
    )

    const required = this.props.required === true
    const hidden = this.props.type === 'hidden'
    const label = this.props.label
    const labelNode =
      label && !this.props.hideLabel && !hidden ? (
        <Fragment>
          <BabaLabel
            htmlFor={this.inputIdentifier}
            label={label}
            required={required}
          />
          <br />
        </Fragment>
      ) : null
    return (
      <Sentry.ErrorBoundary fallback="An error has occured">
        {hiddenValueStoreNode}
        <div className="form-group">
          {labelNode}
          <SingleDatePicker
            focused={this.state.datePickerFocused}
            onFocusChange={this.handleFocusChange}
            displayFormat="DD-MM-YYYY"
            date={this.state.date}
            isOutsideRange={this.isOutsideRange}
            onDateChange={this.handleDateChange}
            placeholder={this.props.placeHolder}
            id={this.props.datepickerId}
            numberOfMonths={1}
            isDayBlocked={this.handleDayBlocked}
            firstDayOfWeek={1}
            hideKeyboardShortcutsPanel
            showClearDate={this.props.showClearDate}
            required
            block
            small
          />
        </div>
      </Sentry.ErrorBoundary>
    )
  }
}

BabaDatePicker.propTypes = {
  datepickerId: PropTypes.string,
  allowPastDates: PropTypes.bool,
  openDays: PropTypes.array
}

BabaDatePicker.defaultProps = {
  allowPastDates: false,
  openDays: [true, true, true, true, true, true, true, true]
}

export default BabaDatePicker
