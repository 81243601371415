import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BabaForm from 'components/forms/Index'
import UserFormFields from 'components/users/UserFormFields'
import NavigationButtons from 'components/bookings/NavigationButtons'
import OrderCommentsInput from 'components/bookings/OrderCommentsInput'

class EditUserAndOrderForm extends Component {
  success = (orderOrUser, status, xhr) => {
    this.context.stopLoading()
    this.props.informationWasModified(orderOrUser)
  }

  error = (xhr, status, error) => {
    this.context.stopLoading()
  }

  render() {
    // The url is different for each funnel: OrderCheckout / FastCheckout / ClaimGift
    // Depending on the endpoint, it might return an order or a user
    const baseURL = this.props.urls.update
    const method = 'PATCH'
    const formId = 'edit_information'
    const actionURL = `${baseURL}.json`

    const fieldsToShow = {
      phone: true
    }

    return (
      <Fragment>
        <div className="step-title">Informations</div>
        <BabaForm.BabaForm
          name="information"
          remote
          didSubmit={this.context.startLoading}
          success={this.success}
          error={this.error}
          id={formId}
          action={actionURL}
          method={method}
          authenticity_token={this.props.authenticityToken}>
          <p>
            <strong>
              <em>{`Vous êtes connecté(e) en tant que ${this.props.user.email}`}</em>
            </strong>
          </p>
          <p>Veuillez renseigner ou vérifier votre numéro de téléphone</p>
          <input type="hidden" name="claim_gift" value={this.props.claimGift} />
          <UserFormFields
            show={fieldsToShow}
            user={this.props.user}
            urls={this.props.urls}
          />
          {this.props.order && (
            <OrderCommentsInput
              order={this.props.order}
              claimGift={this.props.claimGift}
            />
          )}
          <NavigationButtons
            previousStepURL={this.props.previousStepURL}
            priceAfter={this.props.priceAfter}
            goPrevious={this.context.goPrevious}
            submitLabel="Suivant"
            handleValidate={null}
          />
        </BabaForm.BabaForm>
      </Fragment>
    )
  }
}

EditUserAndOrderForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  user: PropTypes.shape({
    fname: PropTypes.string,
    lname: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number.isRequired
  }),
  urls: PropTypes.object.isRequired,
  order: PropTypes.object,
  informationWasModified: PropTypes.func,
  priceAfter: PropTypes.string,
  claimGift: PropTypes.bool,
  previousStepURL: PropTypes.string
}

EditUserAndOrderForm.contextTypes = {
  handleValidationSuccessful: PropTypes.func,
  startLoading: PropTypes.func,
  stopLoading: PropTypes.func,
  goPrevious: PropTypes.func
}

export default EditUserAndOrderForm
