import React, { Fragment } from 'react'
import {
  range,
  map,
  cloneDeep,
  some,
  isEmpty,
  findIndex,
  orderBy
} from 'lodash'
import display from 'lib/Display'

const QuantityPicker = ({
  giftMode,
  stepIndex,
  nextStep,
  goToStep,
  isCurrent,
  offer,
  chosenDate,
  setChosenDate,
  chosenTime,
  setChosenTime,
  availabilities,
  setAvailabilities,
  hourlyPrices,
  setHourlyPrices,
  priceSummary,
  setPriceSummary,
  variantQuantities,
  setVariantQuantities,
  setError,
  setMaxStepReachedIndex,
  fetchedMonths,
  setFetchedMonths
}) => {
  const variantQuantityOptions = (variant) => {
    const { min: minQuantity, max: maxQuantity } =
      offer.minimumAndMaximumQuantityForRelevantPriceVariants[variant.id]

    const quantityOptions = range(minQuantity, maxQuantity + 1)
    quantityOptions.unshift(0)
    return map(quantityOptions, (quantity) => (
      <option key={quantity} value={quantity}>
        {display.pluralizeQuantityType(variant.quantity_type, quantity, true)}
      </option>
    ))
  }

  const updateVariantQuantity = (variantId, quantity) => {
    const newVariantQuantities = cloneDeep(variantQuantities)
    const variantIndex = findIndex(
      newVariantQuantities,
      (variant) => variant.id === variantId
    )
    newVariantQuantities[variantIndex].quantity = quantity
    setVariantQuantities(newVariantQuantities)
    setChosenDate(null)
    setChosenTime(null)
    setAvailabilities({})
    setFetchedMonths([])
    setHourlyPrices({})
    setPriceSummary(null)
    setMaxStepReachedIndex(stepIndex)
  }

  if (variantQuantities.length > 0) {
    if (isCurrent) {
      return (
        <div className="step">
          <h3>Sélectionnez le nombre de participants par formule :</h3>
          {map(orderBy(variantQuantities, ['option']), (variant, index) => (
            <Fragment key={`quantity-variant-${variant.id}`}>
              <div className="variant-quantity row text-left">
                <div className="col-xs-6">
                  <div>
                    {variant.name}
                    {variant.option && <em> - en option</em>}
                  </div>
                  <div>
                    {isEmpty(variant.description)
                      ? null
                      : `(${variant.description})`}
                  </div>
                  {offer.minPricePerVariants[variant.id] && (
                    <em className="baba-dark-grey">
                      {offer.showFromPrice && 'À partir de '}
                      {display.currency(offer.minPricePerVariants[variant.id])}{' '}
                      par{' '}
                      {display.pluralizeQuantityType(
                        variant.quantity_type,
                        1,
                        false
                      )}
                    </em>
                  )}
                </div>
                <div className="col-xs-6 col-md-4 col-md-offset-2">
                  <select
                    name={`variant_${variant.id}_quantity`}
                    className="form-control"
                    value={
                      variantQuantities.find(
                        (variantQuantity) => variantQuantity.id === variant.id
                      ).quantity
                    }
                    onChange={(e) =>
                      updateVariantQuantity(variant.id, e.target.value)
                    }>
                    {variantQuantityOptions(variant)}
                  </select>
                </div>
              </div>
              {index + 1 !== variantQuantities.length && <hr />}
            </Fragment>
          ))}
        </div>
      )
    } else {
      return null
    }
  } else {
    return (
      <div className="step">
        <h2 className="text-center">
          Cette offre est temporairement indisponible
        </h2>
        <p>
          Contactez-nous au
          <a href="tel:0184804030"> 01 84 80 40 30 </a>
          ou par email :
          <a href="mailto: hello@babasport.fr"> hello@babasport.fr</a>
        </p>
      </div>
    )
  }
}

export const QuantityPickerValidator = (
  variantQuantities,
  chosenDate,
  chosenTime
) => {
  const anyVariantHasQuantity = some(
    variantQuantities,
    (variant) => variant.quantity > 0
  )

  if (anyVariantHasQuantity) {
    return {
      valid: true,
      message: null
    }
  } else {
    return {
      valid: false,
      message: 'Merci de choisir une quantité'
    }
  }
}

export default QuantityPicker
