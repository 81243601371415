import Base from './base'
import { filter, find, remove } from 'lodash'
import AvailableModels from './collection'

class Offer extends Base {
  availableModels() {
    return AvailableModels()
  }

  pricePoliciesForSeason(season) {
    return filter(this.price_policies, (pp) => pp.season_id === season.id)
  }

  findPricePolicy(pricePolicyId) {
    return find(
      this.price_policies,
      (pricePolicy) => pricePolicy.id === pricePolicyId
    )
  }

  buildPricePolicy(seasonId) {
    const pricePolicyClass = this.availableModels().PricePolicy
    const pricePolicy = new pricePolicyClass({ offer_id: this.id })

    pricePolicy.season_id = seasonId
    pricePolicy.time_mask.season_id = seasonId

    this.price_policies = this.price_policies || []
    this.price_policies = this.price_policies.concat([pricePolicy])

    return pricePolicy
  }

  deletePricePolicy(pricePolicyId) {
    return remove(this.price_policies, (pp) => pp.id === pricePolicyId)
  }
}

export default Offer
