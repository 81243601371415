import React, { Component } from 'react'
import classNames from 'classnames'
import QuoteForm from 'components/quotes/QuoteForm'

class ModalFaq extends Component {
  UNSAFE_componentWillMount = () => {
    this.parentAccordionId = 'accordion'
  }

  renderSection = (id, title, content, active) => {
    return this.renderSectionVisible(id, title, content, active, true)
  }

  renderSectionVisible = (id, title, content, active, visible) => {
    const targetHref = '#' + id
    const parentHref = '#' + this.parentAccordionId
    return (
      <div className={classNames('panel panel-default', { hidden: !visible })}>
        <div className="panel-heading">
          <h4
            className={classNames('panel-title accordion-toggle', {
              collapsed: !active
            })}
            data-toggle="collapse"
            data-parent={parentHref}
            href={targetHref}>
            <a>{title}</a>
          </h4>
        </div>
        <div
          id={id}
          className={classNames('panel-collapse collapse', { in: active })}>
          <div className="panel-body">{content}</div>
        </div>
      </div>
    )
  }

  render = () => {
    const modalLabelId = 'quote_form_modalLabel'
    const titleLabel = 'DEVIS GROUPES ET ENTREPRISES'

    const quoteForm = (
      <div>
        <p>
          Vous pouvez remplir le formulaire ci-dessous pour nous préciser votre
          demande, nous vous répondrons dans les plus brefs délais.
        </p>
        <QuoteForm
          b2bMode={true}
          quote={{ activity_id: this.props.id, text_activity: this.props.name }}
        />
      </div>
    )
    const bachelorContent = (
      <span>
        {'Besoin d’aide pour l’orga de l’EVG ?'}
        <br />
        {
          'Demandez votre devis personnalisé et bénéficiez de promos jusqu’à 20% en cliquant sur le lien suivant : '
        }
        <a href="https://bit.ly/3vxb2mR" target="_blank" rel="noreferrer">
          https://bit.ly/3vxb2mR
        </a>
      </span>
    )
    const bacheloretteContent = (
      <span>
        {'Besoin d’aide pour l’orga de l’EVJF ?'}
        <br />
        {
          'Demandez votre devis personnalisé et bénéficiez de promos jusqu’à 20% en cliquant sur le lien suivant : '
        }
        <a href="https://bit.ly/3KbnGMn" target="_blank" rel="noreferrer">
          https://bit.ly/3KbnGMn
        </a>
      </span>
    )
    const userQuoteForm = (
      <QuoteForm
        b2bMode={false}
        quote={{ activity_id: this.props.id, text_activity: this.props.name }}
      />
    )
    const allSections = (
      <div className="panel">
        {this.renderSection(
          'bachelor',
          '' + 'Enterrement de vie de garçon - EVG',
          bachelorContent
        )}
        {this.renderSection(
          'bachelorette',
          '' + 'Enterrement de vie de jeune fille - EVJF',
          bacheloretteContent
        )}
        {this.renderSectionVisible(
          'poser-une-question',
          'Poser une question ?',
          userQuoteForm,
          false
        )}
      </div>
    )
    return (
      <div className="modal-faq">
        <div
          className="modal fade"
          id="quote_form_modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby={modalLabelId}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <span
                  className="search-modal-title modal-title"
                  id={modalLabelId}>
                  {titleLabel}
                </span>
              </div>
              <div className="modal-body">
                <div
                  className="panel-group ask-quote-container"
                  id={this.parentAccordionId}>
                  {this.renderSection(
                    'devis',
                    'Team building et Séminaire',
                    quoteForm
                  )}
                  {allSections}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal">
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalFaq
