import React from 'react'
import PropTypes from 'prop-types'

const SideFilters = ({ categoryFilter }) => (
  <div className="search-filters">
    <div className="label-filters">Catégories</div>
    <div className="search-filters-box">{categoryFilter}</div>
  </div>
)

SideFilters.propTypes = {
  categoryFilter: PropTypes.node
}

export default SideFilters
