import PropTypes from 'prop-types'
import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch/connectors'

const ClearRefinementsMobile = ({ items, refine }) => (
  <button
    type="button"
    className="btn btn-link btn-lg btn-outline-pink"
    onClick={() => refine(items)}
    disabled={!items.length}>
    Réinitialiser les filtres
  </button>
)

ClearRefinementsMobile.propTypes = {
  items: PropTypes.array,
  refine: PropTypes.func
}

export default connectCurrentRefinements(ClearRefinementsMobile)
