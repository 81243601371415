import React from 'react'
import createReactClass from 'create-react-class'

const SearchFilterSubCategoryCheckbox = createReactClass({
  handleClick: function () {
    this.props.handleClick(this.props.category.id, !this.props.selected)
  },

  render: function () {
    const fieldIdentifier =
      'categories_' +
      this.props.parentCategory.id +
      '_sub_' +
      this.props.category.id
    return (
      <div className="sub-category search-checkbox">
        <input
          id={fieldIdentifier}
          type="checkbox"
          checked={this.props.selected}
          onChange={this.handleClick}
        />
        <label htmlFor={fieldIdentifier}>{this.props.category.name}</label>
      </div>
    )
  }
})
export default SearchFilterSubCategoryCheckbox
