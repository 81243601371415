import React, { useState, useEffect } from 'react'
import { map } from 'lodash'
import moment from 'moment'

const OrderSummary = ({
  giftMode,
  stepIndex,
  nextStep,
  goToStep,
  isCurrent,
  offer,
  chosenDate,
  setChosenDate,
  chosenTime,
  setChosenTime,
  availabilities,
  setAvailabilities,
  hourlyPrices,
  setHourlyPrices,
  priceSummary,
  setPriceSummary,
  variantQuantities,
  setVariantQuantities,
  setError,
  setMaxStepReachedIndex,
  fetchedMonths,
  setFetchedMonths
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!priceSummary && isCurrent) {
      fetchPriceSummary()
    }
  }, [isCurrent])

  const fetchPriceSummary = () => {
    setLoading(true)
    const date = moment(chosenDate).format('YYYY-MM-DD')
    const variants = map(
      variantQuantities,
      (variant) => `variants[${variant.id}]=${variant.quantity}`
    ).join('&')

    let fetchUrl
    if (giftMode) {
      fetchUrl = `/activities/${offer.activityId}/offers/${offer.id}/price_summary?gift_mode=true&${variants}`
    } else {
      fetchUrl = `/activities/${offer.activityId}/offers/${offer.id}/price_summary?date=${date}&time=${chosenTime}&${variants}`
    }

    fetch(fetchUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          setLoading(false)
          setError(
            'Une erreur est survenue lors de la récupération du total. Veuillez réessayer.'
          )
        }
      })
      .then((body) => {
        setLoading(false)
        setPriceSummary(body.price_summary)
      })
      .catch((error) => {
        setLoading(false)
        setError(error.message)
      })
  }

  if (isCurrent) {
    return (
      <div className="step">
        {loading && (
          <div className="loading-blur">
            <div className="loading-content">
              <span className="loading-message">Chargement</span>
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default OrderSummary
