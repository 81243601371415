import Base from './base'
import AvailableModels from './collection'

class Season extends Base {
  availableModels() {
    return AvailableModels()
  }
}

export default Season
