import React from 'react'
import PropTypes from 'prop-types'
import CurrentRefinements from 'components/search/CurrentRefinements'

const MobileFilters = ({
  showMobileMenu,
  closeMobileMenu,
  featureFilter,
  locationFilter,
  categoryFilter,
  searchBox,
  nbHits,
  clearRefinementsMobile
}) => (
  <div className={`row ${showMobileMenu ? '' : 'hidden'}`}>
    <div className="mobile-filters hidden-sm hidden-md hidden-lg">
      <div className="title">FILTRER</div>
      <button className="close" onClick={closeMobileMenu} />
      <CurrentRefinements clearsQuery />
      <div className="filter-container">{searchBox}</div>
      <div className="filter-container">{locationFilter}</div>
      <div className="filter-container">{featureFilter}</div>
      <p className="filter-title">CATÉGORIES</p>
      <div className="filter-container">{categoryFilter}</div>
    </div>
    <div className="visible-xs col-xs-12 mobile-fix-footer mobile-filter-footer">
      <div className="row">
        <div className="col-xs-6">{clearRefinementsMobile}</div>
        <div className="col-xs-6">
          <button onClick={closeMobileMenu} className="btn btn-primary btn-lg">
            {nbHits > 1 && `Voir ${nbHits} activités`}
            {nbHits === 1 && `Voir l'activité`}
            {nbHits === 0 && `Pas de résultat`}
          </button>
        </div>
      </div>
    </div>
  </div>
)

MobileFilters.propTypes = {
  showMobileMenu: PropTypes.bool,
  closeMobileMenu: PropTypes.func,
  featureFilter: PropTypes.node,
  locationFilter: PropTypes.node,
  searchBox: PropTypes.node,
  categoryFilter: PropTypes.node,
  nbHits: PropTypes.number,
  clearRefinementsMobile: PropTypes.node
}

export default MobileFilters
