import React from 'react'
import algoliasearch from 'algoliasearch'
import '@algolia/autocomplete-theme-classic'
import { getAlgoliaResults } from '@algolia/autocomplete-js'
import AlgoliaHelper from 'lib/AlgoliaHelper'
import Autocomplete from 'components/admin/search/Autocomplete'
import ResultItem from 'components/admin/search/ResultItem'
import debounced from 'lib/Debounced'

const appId = process.env.ALGOLIA_APP_ID
const apiKey = process.env.ALGOLIA_SEARCH_API_KEY
const searchClient = algoliasearch(appId, apiKey)

const AdminSearch = () => (
  <Autocomplete
    autoFocus={false}
    placeholder="Admin Search"
    openOnFocus={false}
    getSources={({ query }) =>
      debounced([
        {
          sourceId: 'adminSearch',
          getItemUrl({ item }) {
            return item.aa_admin_path || item.aa_public_path
          },
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: AlgoliaHelper.getIndexName('Order'),
                  query,
                  params: {
                    attributesToRetrieve: [
                      'id',
                      'aa_admin_path',
                      'aa_user.email',
                      'aa_user.phone',
                      'aa_doer.email',
                      'aa_doer.phone',
                      'gift_token'
                    ],
                    restrictSearchableAttributes: [
                      'id',
                      'aa_user.email',
                      'aa_user.phone',
                      'aa_doer.email',
                      'aa_doer.phone',
                      'gift_token'
                    ]
                  }
                },
                {
                  indexName: AlgoliaHelper.getIndexName('Activity'),
                  query,
                  params: {
                    attributesToRetrieve: [
                      'id',
                      'aa_public_path',
                      'name',
                      'aa_center_name'
                    ],
                    restrictSearchableAttributes: [
                      'id',
                      'name',
                      'aa_center_name'
                    ]
                  }
                },
                {
                  indexName: AlgoliaHelper.getIndexName('Quote'),
                  query,
                  params: {
                    attributesToRetrieve: [
                      'id',
                      'aa_admin_path',
                      'email',
                      'name',
                      'phone'
                    ],
                    restrictSearchableAttributes: ['email', 'name', 'phone']
                  }
                },
                {
                  indexName: AlgoliaHelper.getIndexName('GiftCardOrder'),
                  query,
                  params: {
                    attributesToRetrieve: ['id', 'aa_admin_path', 'token'],
                    restrictSearchableAttributes: ['id', 'token']
                  }
                },
                {
                  indexName: AlgoliaHelper.getIndexName('Contractor'),
                  query,
                  params: {
                    attributesToRetrieve: [
                      'aa_admin_path',
                      'name',
                      'email',
                      'lname',
                      'phone',
                      'aa_center_names'
                    ],
                    restrictSearchableAttributes: [
                      'name',
                      'email',
                      'lname',
                      'phone',
                      'aa_center_names'
                    ]
                  }
                }
              ],
              transformResponse({ hits }) {
                return hits.map((group) => {
                  return group.map((hit) => ({
                    ...hit,
                    type:
                      (/^Activity_/.test(hit.__autocomplete_indexName) &&
                        'activity') ||
                      (/^Order_/.test(hit.__autocomplete_indexName) &&
                        'order') ||
                      (/^Quote_/.test(hit.__autocomplete_indexName) &&
                        'quote') ||
                      (/^GiftCardOrder_/.test(hit.__autocomplete_indexName) &&
                        'giftCardOrder') ||
                      (/^Contractor_/.test(hit.__autocomplete_indexName) &&
                        'contractor')
                  }))
                })
              }
            })
          },
          templates: {
            item({ item, components }) {
              return <ResultItem item={item} components={components} />
            }
          }
        }
      ])
    }
  />
)

export default AdminSearch
