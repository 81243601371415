const CATEGORIES = {
  ORDER: 'Réservation',
  QUOTE: 'Devis',
  RECOMMENDATION: 'Recommandation'
}
const ACTIONS = {
  ORDER_ADD_TO_CART: 'Ajout panier',
  QUOTE_CREATE: 'Envoi demande devis',
  RECOMMENDATION_CLICK_ACTIVITY: 'Voir activité'
}

const getPathAsLabel = () =>
  window ? window.location.pathname : 'no path information available'

export default { ...CATEGORIES, ...ACTIONS, getPathAsLabel }
