import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BabaForm from 'components/forms/Index'

class UserFormFields extends Component {
  emailValidation = (value, successFn, noValidationFn, errorFn) => {
    if (this.props.shouldValidateFields) {
      this.lastCheckedEmail = value
      $.ajax({
        url: this.props.urls.lookup,
        method: 'POST',
        dataType: 'json',
        cache: false,
        data: { email: value },
        success: function (data) {
          if (this.lastCheckedEmail === data.value) {
            if (!data.available) {
              errorFn('Il y a déjà un utilisateur avec cet email')
            }
            if (!data.valid) {
              errorFn('L\'email n\'est pas valide')
            }
            if (data.available && data.valid) {
              successFn()
            }
          }
        }.bind(this),
        error: function (xhr, status, err) {
          errorFn('Il y a eu une erreur')
        }
      })
    } else {
      noValidationFn()
    }
  }

  passwordValidation = (value, successFn, noValidationFn, errorFn) => {
    if (this.props.shouldValidateFields) {
      value.length > 7
        ? successFn()
        : errorFn('Le mot de passe doit contenir au moins 8 caractères')
    } else {
      noValidationFn()
    }
  }

  phoneValidation = (value, successFn, noValidationFn, errorFn) => {
    if (this.props.shouldValidateFields) {
      value.match(/^(\+[1-9]{1}[0-9]{3,14})?([0-9]+)$/)
        ? successFn()
        : errorFn("Le numéro de téléphone n'est pas valide")
    } else {
      noValidationFn()
    }
  }

  render() {
    return (
      <Fragment>
        <BabaForm.FormLine>
          {this.props.show.fname && (
            <BabaForm.BabaInput
              type="text"
              objectName="user"
              fieldName="fname"
              placeHolder="Prénom"
              defaultValue={this.props.user.fname}
              hideLabel
              required
            />
          )}
          {this.props.show.lname && (
            <BabaForm.BabaInput
              type="text"
              objectName="user"
              fieldName="lname"
              placeHolder="Nom"
              defaultValue={this.props.user.lname}
              hideLabel
              required
            />
          )}
        </BabaForm.FormLine>

        {this.props.show.phone && (
          <div className="row">
            <div className="col-sm-6">
              <BabaForm.BabaInput
                type="tel"
                objectName="user"
                fieldName="phone"
                placeHolder="Téléphone"
                defaultValue={this.props.user.phone}
                validate={true}
                validationFn={this.phoneValidation}
                hideLabel
                required
              />
            </div>
            <div className="col-sm-6 help-block mt-0">
              Nous vous contacterons uniquement en cas de besoin pour le suivi
              de votre commande
            </div>
          </div>
        )}
        {this.props.show.email && (
          <div className="row">
            <div className="col-sm-6">
              <BabaForm.BabaInput
                type="email"
                objectName="user"
                fieldName="email"
                placeHolder="Email"
                defaultValue={this.props.user.email}
                hideLabel
                required
                validationFn={this.emailValidation}
              />
            </div>
          </div>
        )}
        <BabaForm.FormLine>
          {this.props.show.password && (
            <div className="row">
              <div className="col-sm-6">
                <BabaForm.BabaInput
                  type="password"
                  objectName="user"
                  fieldName="password"
                  placeHolder="Mot de passe"
                  validationFn={this.passwordValidation}
                  hideLabel
                  required
                />
              </div>
            </div>
          )}
          {this.props.show.password_confirmation && (
            <div className="row">
              <div className="col-sm-6">
                <BabaForm.BabaInput
                  type="password"
                  objectName="user"
                  fieldName="password_confirmation"
                  placeHolder="Confirmer mot de passe"
                  hideLabel
                  required
                />
              </div>
            </div>
          )}
        </BabaForm.FormLine>
      </Fragment>
    )
  }
}
UserFormFields.propTypes = {
  user: PropTypes.shape({
    fname: PropTypes.string,
    lname: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  show: PropTypes.shape({
    fname: PropTypes.bool,
    lname: PropTypes.bool,
    phone: PropTypes.bool,
    email: PropTypes.bool,
    password: PropTypes.bool,
    password_confirmation: PropTypes.bool,
    reset_password: PropTypes.bool
  }),
  urls: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string,
  shouldValidateFields: PropTypes.bool.isRequired
}
UserFormFields.defaultProps = {
  shouldValidateFields: true
}

export default UserFormFields
