import React, { Component } from 'react'
import classNames from 'classnames'

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggled: false
    }

    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !event.target.classList.contains('pac-item')
    ) {
      this.setState({ toggled: false })
    }
  }

  toggleDropdown() {
    const isSelected = !this.state.toggled
    this.setState({
      toggled: isSelected
    })
  }

  render() {
    const { borderless, right } = this.props
    const selectClassNames = classNames(
      this.props.selectClassName || 'baba-select',
      {
        borderless,
        selected: this.state.toggled && !borderless,
        refined: this.props.refined
      }
    )

    return (
      <div
        className={classNames('search-dropdown', this.props.className)}
        ref={this.setWrapperRef}>
        <div
          className={selectClassNames}
          id={this.props.id || ''}
          onClick={this.toggleDropdown}>
          {this.props.title}
        </div>

        <div
          className={`search-dropdown-menu ${
            right ? 'align-right' : 'align-left'
          } ${this.state.toggled ? 'toggled' : ''}`}>
          <div onClick={this.toggleDropdown}>{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default Dropdown
