import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Season from './Season'
import * as Sentry from '@sentry/react'

class Seasons extends Component {
  constructor(props) {
    super(props)

    this.seasonRef = React.createRef()

    this.state = {
      activeSeasons: props.activeSeasons,
      activableSeasons: props.activableSeasons,
      activeActivableSeason: null
    }
  }

  activateSeason(e) {
    this.setState({
      needsSaving: true,
      activeActivableSeason:
        this.seasonChoice.options[this.seasonChoice.selectedIndex].value
    })
  }

  componentDidUpdate() {
    if (this.seasonRef.current && this.state.needsSaving) {
      this.setState({ needsSaving: null })
    }
  }

  render() {
    const { activeSeasons, activableSeasons, activeActivableSeason } =
      this.state
    const hasDisplayableSeasons =
      activeSeasons.length > 0 || activeActivableSeason
    let seasonChoice

    if (activableSeasons.length > 0) {
      seasonChoice = (
        <div>
          <select ref={(el) => (this.seasonChoice = el)}>
            {activableSeasons.map((season) => (
              <option value={season.id} key={`option-${season.id}`}>
                {season.name}
              </option>
            ))}
          </select>
          <button
            className="btn btn-tertiary activate-season"
            onClick={(e) => this.activateSeason(e)}>
            + Ajouter cette période
          </button>
        </div>
      )
    } else {
      seasonChoice = (
        <div>
          Toutes les périodes pour cette activité sont déjà configurées.
        </div>
      )
    }

    return (
      <Sentry.ErrorBoundary fallback="An error has occured">
        <div className="admin-seasons-container">
          <div
            className={`seasons-list ${hasDisplayableSeasons ? '' : 'empty'}`}>
            <div className="active-seasons">
              {activeSeasons.map((season) => (
                <Season
                  activity={this.props.activity}
                  offer={this.props.offer}
                  season={season}
                  key={season.id}
                  form_authenticity_token={this.props.form_authenticity_token}
                />
              ))}
            </div>
            <div className="activeable-seasons">
              {activableSeasons.map((season) => {
                return season.id.toString() === activeActivableSeason ? (
                  <Season
                    ref={this.seasonRef}
                    activity={this.props.activity}
                    offer={this.props.offer}
                    season={season}
                    key={season.id}
                    form_authenticity_token={this.props.form_authenticity_token}
                  />
                ) : (
                  ''
                )
              })}
            </div>
          </div>

          <div className="season-choice-container">
            <div className="season-choice">{seasonChoice}</div>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    )
  }
}

Seasons.propTypes = {
  form_authenticity_token: PropTypes.string.isRequired,
  activity: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  activeSeasons: PropTypes.array.isRequired,
  activableSeasons: PropTypes.array.isRequired
}

export default Seasons
