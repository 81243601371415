import React from 'react'
import createReactClass from 'create-react-class'
import StarRating from 'components/shared/StarRating'
import * as Sentry from '@sentry/react'

var RatingsForm = createReactClass({
  getInitialState: function () {
    return {
      note: this.props.note
    }
  },
  handleChangedRating: function (note) {
    this.setState({ note: note })
  },
  submitRating: function () {
    var req = {
      rating: {
        toto: 'tata',
        note: this.state.note
        // comment: this.refs.ratingComment.value
      }
    }
    $.ajax({
      url: '/ratings/',
      data: req,
      method: 'POST',
      dataType: 'json',
      cache: false,
      success: function (data) {}
    })
  },
  render: function () {
    return (
      <Sentry.ErrorBoundary fallback="An error has occured">
        <div>
          <StarRating
            note={this.state.note}
            editable={true}
            handleChangedRating={this.handleChangedRating}
          />
          <button onClick={this.submitRating}>SUBMIT</button>
        </div>
      </Sentry.ErrorBoundary>
    )
  }
})

export default RatingsForm
