import React, { Fragment } from 'react'
import OrderCheckoutTotals from './OrderCheckoutTotals'
import moment from 'moment/moment'
import { getSpecialPeriodName } from 'lib/TimeHelper'
import display from 'lib/Display'
import PropTypes from 'prop-types'
import PriceOrFree from 'components/prices/PriceOrFree'

const OrderRecap = ({ order }) => {
  const renderLineItem = (lineItem, index) => (
    <div key={index} className="row mb-2">
      <div className="col-xs-8">
        {`${lineItem.variantName} : ${display.pluralizeQuantityType(
          lineItem.variantQuantityType,
          lineItem.quantity
        )}`}
      </div>
      <div className="col-xs-4 text-right">
        {display.currency(lineItem.priceBefore)}
      </div>
    </div>
  )

  return (
    <div className="order-recap-container">
      <div className="img-container">
        <img
          src={order.activity.image}
          className="img-responsive"
          alt={order.activity.name}
        />
      </div>
      <div className="content">
        <div id="transaction_id" data-transaction-id={order.id} />
        {order.isGift ? (
          <Fragment>
            <div id="purchase_type" data-purchase-type="gift" />
            <div
              id="conversion_value"
              data-conversion-value={order.adwordsConversionAmount}
            />
            <p>
              <strong>{order.activity.name}</strong>
            </p>
            {order.lineItems.map(renderLineItem)}
            {order.shippingMethod && (
              <Fragment>
                <div className="row">
                  <div className="col-xs-8">
                    <p>{order.shippingMethod.label}</p>
                  </div>
                  <div className="col-xs-4 text-right">
                    <PriceOrFree amount={order.shippingMethod.amount} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-8">
                    <p>{order.shippingMethod.shippingDescription}</p>
                  </div>
                  <div className="col-xs-4 text-right">
                    <PriceOrFree
                      amount={
                        order.shipment?.price ||
                        order.shippingMethod.shippingAmount
                      }
                    />
                  </div>
                </div>
              </Fragment>
            )}
            {order.cancellationPolicy && (
              <div className="row">
                <div className="col-xs-8">
                  <p>{order.cancellationPolicy.label}</p>
                </div>
                <div className="col-xs-4 text-right">
                  {display.currency(order.cancellationPolicy.price)}
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <div id="purchase_type" data-purchase-type="booking" />
            <div
              id="conversion_value"
              data-conversion-value={order.adwordsConversionAmount}
            />
            <p>
              <strong>{order.activity.name}</strong>
            </p>
            {order.lineItems.map(renderLineItem)}
            <p>
              Date : {moment(order.activityDatetime).format('Do MMMM YYYY')}
              {order.activityDatetimeSpecialDuration && (
                <Fragment>
                  ,{' '}
                  {getSpecialPeriodName(order.activityDatetimeSpecialDuration)}
                </Fragment>
              )}
              {!order.activityDatetimeSpecialDuration && (
                <Fragment>
                  <br />
                  Heure : {moment(order.activityDatetime).format('HH:mm')}
                </Fragment>
              )}
            </p>
            {order.cancellationPolicy && (
              <div className="row">
                <div className="col-xs-8">
                  <p>{order.cancellationPolicy.label}</p>
                </div>
                <div className="col-xs-4 text-right">
                  {display.currency(order.cancellationPolicy.price)}
                </div>
              </div>
            )}
          </Fragment>
        )}
        <OrderCheckoutTotals order={order} />
      </div>
    </div>
  )
}

OrderRecap.propTypes = {
  order: PropTypes.object.isRequired
}

OrderRecap.displayName = 'OrderRecap'
export default OrderRecap
