import React from 'react'
import PropTypes from 'prop-types'
import BabaForm from 'components/forms/Index'

const OrderCommentsInput = ({ order, claimGift }) => {
  if (order.isGift && !claimGift) return null
  if (!order.specialCheckoutCategory) return null

  let label = ''
  let placeHolder = ''
  switch (order.specialCheckoutCategory) {
    case 'air':
      label =
        'Veuillez préciser le nom, prénom, âge, poids, taille des participants.'
      placeHolder = 'John Doe, 35 ans,  80kg, 180cm.'
      break
    case 'strip':
      label =
        "Veuillez préciser l'adresse où vous souhaitez faire l'activité ou autres informations utiles."
      placeHolder = 'Domicile, 50 rue de la gare, Biarritz'
      break
    case 'dance':
      label =
        "Si besoin d’une salle → Veuillez préciser la zone où vous souhaiteriez faire l'activité ou autres informations utiles.\n" +
        "Si pas besoin d’une salle → Veuillez préciser l'adresse où vous souhaitez faire l’activité ou autres informations utiles."
      placeHolder =
        'Salle à louer à proximité de Deauville.\nDans notre location au 50 rue des pins, Arcachon.'
      break
    case 'limo':
      label =
        'Veuillez préciser votre adresse de départ + adresse de dépose.\n' +
        "Si transfert aéroport ajouter : n° de vol, terminal + heure d'arrivée.\nOu autres informations utiles."
      placeHolder =
        'Aéroport CDG, 10h30, Terminal 5. Dépose au 40 rue de la gare, Budapest'
      break
  }
  return (
    <div>
      <BabaForm.BabaInput
        label={label}
        labelClassNames="whitespace-pre-wrap"
        type="textarea"
        objectName="order"
        fieldName="comments"
        rows={4}
        validate={false}
        defaultValue={order.comments}
        placeHolder={placeHolder}
        required={true}
      />
    </div>
  )
}

OrderCommentsInput.propTypes = {
  order: PropTypes.object.isRequired,
  claimGift: PropTypes.bool.isRequired
}

OrderCommentsInput.displayName = 'OrderCommentsInput'
export default OrderCommentsInput
