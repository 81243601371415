import Base from './base'
import { find, findIndex } from 'lodash'
import AvailableModels from './collection'

class Activity extends Base {
  availableModels() {
    return AvailableModels()
  }

  timeMasksForSeason(seasonId) {
    const season = find(this.seasons, (season) => season.id === seasonId)
    return season.time_masks
  }

  // arg: updated variant object
  updateVariant(variant) {
    const variantIndex = findIndex(
      this.price_variants,
      (v) => v.id === variant.id
    )
    this.price_variants[variantIndex] = variant

    return variant
  }

  createNewVariant() {
    const PriceVariantClass = this.availableModels().PriceVariant
    const priceVariant = new PriceVariantClass({ activity_id: this.id })

    this.price_variants.push(priceVariant)

    return priceVariant
  }
}

export default Activity
