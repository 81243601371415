import React, { Component } from 'react'
import { each } from 'lodash'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'

class Map extends Component {
  constructor(props) {
    super(props)
    this.mapRef = React.createRef()
  }

  componentDidMount() {
    this.defaultPin = new L.Icon.Default()
    this.center = this.createCenter()
    this.map = this.createMap()
    this.drawStuff()
  }

  componentDidUpdate(prevProps, prevState) {
    this.drawStuff()
  }

  drawStuff = () => {
    this.createMarker(this.center)
    if (this.props.markers.length > 0) {
      each(this.props.markers, this.createMarker)
    }
  }

  createMap = () => {
    const mapOptions = {
      zoom: this.props.zoom,
      maxZoom: this.props.maxZoom,
      center: this.createCenter(),
      layers: [L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png')],
      dragging: this.props.draggable,
      scrollWheelZoom: false,
      zoomControl: this.props.zoomControl,
      doubleClickZoom: this.props.doubleClickZoom,
      keyboard: this.props.keyboard,
      touchZoom: this.props.touchZoom
    }
    return new L.map(this.mapRef.current, mapOptions)
  }

  createCenter = () => {
    return new L.latLng(
      this.props.initialCenter.lat,
      this.props.initialCenter.lng
    )
  }

  createMarker = (position) => {
    return new L.Marker(position, {
      icon: this.defaultPin,
      draggable: true
    }).addTo(this.map)
  }

  render() {
    return (
      <Sentry.ErrorBoundary fallback="An error has occured">
        <div className="leaflet-map" ref={this.mapRef} />
      </Sentry.ErrorBoundary>
    )
  }
}

Map.propTypes = {
  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
  draggable: PropTypes.bool,
  markers: PropTypes.array,
  zoomControl: PropTypes.bool,
  doubleClickZoom: PropTypes.bool,
  keyboard: PropTypes.bool,
  touchZoom: PropTypes.bool
}
Map.defaultProps = {
  zoom: 12,
  maxZoom: 14,
  draggable: false,
  markers: [],
  zoomControl: false,
  doubleClickZoom: false,
  keyboard: false,
  touchZoom: false
}

export default Map
