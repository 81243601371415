// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'moment/locale/fr'
import * as Sentry from '@sentry/react'

const componentRequireContext = require.context('components', true)

const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

if (typeof SentryConfig !== 'undefined') {
  Sentry.init({
    dsn: SentryConfig.dsn,
    release: SentryConfig.release,
    environment: SentryConfig.environment
  })
  window.Sentry = Sentry
}
