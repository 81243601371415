import React from 'react'
import createReactClass from 'create-react-class'
import * as Sentry from '@sentry/react'

var SearchSelectActivities = createReactClass({
  displayName: 'SearchSelectActivities',

  handleSelect: function (item) {
    if (this.props.handleSelectedActivity) {
      this.props.handleSelectedActivity(item.object)
    }
  },

  componentDidMount: function () {
    $(this.refs.targetInput).autocomplete({
      autoFocus: true,
      select: function (event, ui) {
        this.handleSelect(ui.item)
      }.bind(this),

      source: function (request, response) {
        $.ajax({
          url: '/admin/api/activities/',
          data: { filter_token: request.term },
          method: 'GET',
          dataType: 'json',
          cache: false,
          success: function (data) {
            return response(
              $.map(data.activities, function (item) {
                var label = item.id + ' - ' + item.name
                return {
                  label: label,
                  value: label,
                  object: item
                }
              })
            )
          }
        })
      }
    })
  },

  render: function () {
    return (
      <Sentry.ErrorBoundary fallback="An error has occured">
        <input
          type="text"
          ref="targetInput"
          placeholder="Chercher activité"
          className="form-control"
        />
      </Sentry.ErrorBoundary>
    )
  }
})

export default SearchSelectActivities
