import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server'
import ActivityPopin from 'components/geo/ActivityPopin'
import { connectHits, connectGeoSearch } from 'react-instantsearch/connectors'
import { /* debounce, */ isEmpty } from 'lodash'
import markerIconBlue from 'images/map/marker-icon-2x-blue.png'
import markerIconOrange from 'images/map/marker-icon-2x-orange.png'
import markerShadow from 'images/map/marker-shadow.png'

const isBrowser = typeof window !== 'undefined'
const getCenter = (bounds) => {
  const southWest = L.latLng(bounds.southWest.lat, bounds.southWest.lng)
  const northEast = L.latLng(bounds.northEast.lat, bounds.northEast.lng)
  return L.latLngBounds(southWest, northEast).getCenter()
}

const SearchMap = ({
  showMap,
  setShowMap,
  geoSearchParams,
  hits,
  // refine,
  currentRefinement
}) => {
  const mapRef = useRef(null)
  const [map, setMap] = useState(null)
  const [shouldCenterView, setShouldCenterView] = useState(false)
  const markerGroupRef = useRef(isBrowser ? L.featureGroup() : null)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const prevActivitytRef = useRef()
  const initialLoad = useRef(true) // Flag to skip initial moveend event
  const previousBoundsRef = useRef(null)

  // const debouncedRefine = useMemo(
  //   () =>
  //     debounce((bounds, showMap) => {
  //       refine(bounds)
  //     }, 500),
  //   [refine]
  // )

  useEffect(() => {
    if (!map) return null
    if (!showMap) return null

    if (isEmpty(currentRefinement)) {
      map.setView(new L.LatLng(48.8566, 2.3522), 6)
    } else {
      map.setView(getCenter(currentRefinement), 10)
    }
  }, [map, showMap])

  useEffect(() => {
    if (!isBrowser) return

    const lat = geoSearchParams ? geoSearchParams.lat : 48.8566
    const lng = geoSearchParams ? geoSearchParams.lng : 2.3522
    const zoom = geoSearchParams ? 10 : 6
    const mapOptions = {
      layers: [L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png')],
      attributionControl: false,
      center: [lat, lng],
      zoom: zoom,
      dragging: true,
      scrollWheelZoom: true,
      zoomControl: true,
      maxZoom: 14
    }

    const leafletMap = L.map('map-container', mapOptions)
    setMap(leafletMap)

    const handleKeyUp = (event) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        setShowMap(false)
      }
    }
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keyup', handleKeyUp)
      leafletMap.off()
      leafletMap.remove()
      setMap(null)
    }
  }, [setShowMap])

  useEffect(() => {
    if (!map) return

    mapRef.current = map
    const closeButton = L.easyButton({
      position: 'topright',
      states: [
        {
          stateName: 'close-map',
          icon: '<span class="btn btn-lg btn-info">Afficher la liste</span>',
          title: 'Vue Liste',
          onClick: () => {
            if (map) {
              setShowMap(false)
            }
          }
        }
      ]
    })
    closeButton.button.style.width = '50px'
    closeButton.button.style.height = '40px'
    closeButton.addTo(map)

    const handleMoveEnd = () => {
      if (initialLoad.current) {
        initialLoad.current = false // Skip the first moveend event
        return
      }
      const bounds = map.getBounds()
      // Check if bounds have changed
      const northEast = bounds.getNorthEast()
      const southWest = bounds.getSouthWest()
      const prevBounds = previousBoundsRef.current
      if (
        prevBounds &&
        northEast.lat === prevBounds.northEast.lat &&
        northEast.lng === prevBounds.northEast.lng &&
        southWest.lat === prevBounds.southWest.lat &&
        southWest.lng === prevBounds.southWest.lng
      ) {
        return
      }

      previousBoundsRef.current = { northEast, southWest }
      // debouncedRefine(
      //   {
      //     northEast,
      //     southWest
      //   }
      // )
    }

    map.on('moveend', handleMoveEnd)
    map.on('zoomend', handleMoveEnd)

    return () => {
      map.off('moveend', handleMoveEnd)
      map.off('zoomend', handleMoveEnd)
      // debouncedRefine.cancel() // Cancel any pending debounced calls
    }
  }, [map /*, debouncedRefine */, setShowMap])

  useEffect(() => {
    if (!map || !markerGroupRef.current) return

    markerGroupRef.current.clearLayers()
    const markers = hits
      .filter((hit) => hit.type !== 'promo')
      .map((hit) =>
        createMarker(L.latLng(hit._geoloc.lat, hit._geoloc.lng), hit)
      )

    markers.forEach((marker) => markerGroupRef.current.addLayer(marker))

    if (markers.length > 0) {
      markerGroupRef.current.addTo(map)
    }
  }, [hits, map])

  useEffect(() => {
    if (prevActivitytRef.current && markerGroupRef.current) {
      const layer = markerGroupRef.current.getLayer(
        prevActivitytRef.current.layerId
      )
      layer && layer.setIcon(defaultIcon())
    }
    if (selectedActivity && markerGroupRef.current) {
      const layer = markerGroupRef.current.getLayer(selectedActivity.layerId)
      if (layer) {
        layer.setIcon(highlightIcon())
        layer.bindPopup(ReactDOMServer.renderToString(popin))
        layer.openPopup()
      }
    }
    prevActivitytRef.current = selectedActivity
  }, [selectedActivity])

  // Handle map display change
  useEffect(() => {
    if (map && markerGroupRef.current.getLayers().length > 0) {
      map.invalidateSize()
      setShouldCenterView(false)
    }
  }, [shouldCenterView, map])

  useEffect(() => {
    if (showMap) {
      setShouldCenterView(true)
    }
  }, [showMap])

  const popin = useMemo(
    () => <ActivityPopin activity={selectedActivity} />,
    [selectedActivity]
  )

  const createMarker = (position, activity) => {
    const marker = new L.Marker(position, {
      icon: defaultIcon(),
      id: activity.id
    })

    marker.on('click', () => {
      onMarkerClick(activity, marker._leaflet_id)
    })

    return marker
  }

  const onMarkerClick = (activity, layerId) => {
    activity.layerId = layerId
    setSelectedActivity(activity)
  }

  const defaultIcon = () =>
    new L.Icon({
      iconUrl: markerIconBlue,
      shadowUrl: markerShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    })

  const highlightIcon = () =>
    new L.Icon({
      iconUrl: markerIconOrange,
      shadowUrl: markerShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    })

  return (
    <div
      hidden={!showMap}
      style={{
        height: '100vh',
        width: '100vw',
        top: '0',
        left: '0',
        zIndex: '9999',
        position: 'absolute'
      }}>
      <div
        ref={mapRef}
        id="map-container"
        style={{ height: '100%', width: '100%', position: 'fixed!important' }}
      />
    </div>
  )
}

SearchMap.propTypes = {
  showMap: PropTypes.bool,
  setShowMap: PropTypes.func,
  geoSearchParams: PropTypes.object,
  hits: PropTypes.arrayOf(PropTypes.object),
  refine: PropTypes.func,
  currentRefinement: PropTypes.object
}

export default connectGeoSearch(connectHits(SearchMap))
