import PropTypes from 'prop-types'
import display from 'lib/Display'

const PriceOrFree = ({ amount }) => {
  if (parseInt(amount) === 0) {
    return 'Offert'
  } else {
    return display.currency(amount)
  }
}

PriceOrFree.propTypes = {
  amount: PropTypes.string.isRequired
}

export default PriceOrFree
