import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'
import display from 'lib/Display'

var OrderCheckoutTotals = createReactClass({
  displayName: 'OrderCheckoutTotals',

  renderSubtotalLine: function (el, index) {
    const amountLabel = display.currency(el.amount)
    const lineClasses = classNames('subtotal-line row', {
      highlight: el.highlight
    })
    return (
      <div className={lineClasses} key={index}>
        <div className="col-xs-6">{el.label}</div>
        <div className="col-xs-6 text-right">{amountLabel}</div>
      </div>
    )
  },

  render: function () {
    const depositAmount = this.props.order.paymentPolicy.online
    const totalAmount = this.props.order.priceAfter
    const totalBefore = this.props.order.priceBefore
    const hasPromotion = totalBefore !== totalAmount
    const hideSubTotals = depositAmount === 0 || depositAmount === totalAmount
    const subtotalLines = [
      {
        label: 'Montant à régler maintenant',
        amount: this.props.order.paymentPolicy.online
      },
      {
        label: "Montant à régler le jour de l'activité",
        amount: this.props.order.paymentPolicy.offline
      }
    ]
    const subtotalLinesNodes = subtotalLines.map(this.renderSubtotalLine)
    const totalWording = hasPromotion ? 'Total après promotion' : 'Total'

    return (
      <div className="totals-component">
        <div className={classNames('subtotals', { hidden: hideSubTotals })}>
          {subtotalLinesNodes}
        </div>
        <div className="totals">
          <div
            className={classNames('total-before-promo row', {
              hidden: !hasPromotion
            })}>
            <div className="col-xs-8 total-wording">Total avant promotion</div>
            <div className="col-xs-4 text-right total-amount">
              {display.currency(totalBefore)}
            </div>
          </div>
          <div className="total-final row">
            <div className="col-xs-8 total-wording">{totalWording}</div>
            <div className="col-xs-4 text-right total-amount">
              {display.currency(totalAmount)}
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
    )
  }
})
export default OrderCheckoutTotals
