import React, { Component } from 'react'
import BabaForm from 'components/forms/Index'
import PropTypes from 'prop-types'
import isEmail from 'validator/lib/isEmail'
import NavigationButtons from 'components/bookings/NavigationButtons'

class PasswordResetForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      isFetching: false,
      showError: false,
      errorMessage: ''
    }
  }

  closeAndGoPrevious = (e) => {
    this.props.backToLoginMode(e)
    this.context.goPrevious()
  }

  handleResetPassword = (event) => {
    event.preventDefault()
    if (this.state.isFetching === true) {
      return
    }

    if (isEmail(this.state.email)) {
      this.setState({ isFetching: true })
      fetch(this.props.urls.userPassword, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.props.authenticityToken
        },
        body: JSON.stringify({ user: { email: this.state.email } })
      })
        .then((response) => {
          if (response.ok) {
            this.props.onResetPasswordSuccess(
              'Vous allez recevoir sous quelques minutes un email vous indiquant comment réinitialiser votre mot de passe.'
            )
            return response.json()
          } else {
            this.setState({
              showError: true,
              errorMessage:
                "L'utilisateur correspondant à cette adresse mail n'a pas été trouvé"
            })
          }
        })
        .then((body) => {
          this.setState({ isFetching: false })
        })
    } else {
      this.setState({
        isFetching: false,
        showError: true,
        errorMessage: "L'addresse email n'est pas valide"
      })
    }
  }

  render() {
    return (
      <div>
        {this.state.showError && (
          <div className="alert alert-danger alert-dismissible" role="alert">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            {this.state.errorMessage}
          </div>
        )}
        <div className="row">
          <div className="col-sm-6">
            <BabaForm.BabaInput
              type="email"
              placeHolder="Email"
              hideLabel
              onChange={(object, field, email) => {
                this.setState({ email: email })
              }}
              controlled
              required
              validationFn={isEmail}
            />
          </div>
          <div className="col-sm-6 help-block mt-0">
            Veuillez renseigner votre e-mail, nous vous enverrons les
            instructions pour réinitialiser votre mot de passe
          </div>
        </div>
        <div className="mb-3">
          <a className="light-blue" onClick={this.props.onOpenResetPassword}>
            Retour au formulaire de connexion
          </a>
        </div>
        <NavigationButtons
          previousStepURL={this.props.previousStepURL}
          goPrevious={this.closeAndGoPrevious}
          submitLabel="Réinitialiser"
          handleValidate={this.handleResetPassword}
          disabled={this.state.isFetching}
        />
      </div>
    )
  }
}

PasswordResetForm.propTypes = {
  backToLoginMode: PropTypes.func.isRequired,
  onOpenResetPassword: PropTypes.func.isRequired,
  onResetPasswordSuccess: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  urls: PropTypes.object.isRequired,
  previousStepURL: PropTypes.string
}
PasswordResetForm.contextTypes = {
  goPrevious: PropTypes.func
}

PasswordResetForm.displayName = 'PasswordResetForm'

export default PasswordResetForm
