import React from 'react'
import createReactClass from 'create-react-class'
import { findIndex, sortBy, filter } from 'lodash'
import SearchFilterFeatureCheckbox from './SearchFilterFeatureCheckbox'
import SearchFilterCategoryCheckbox from './SearchFilterCategoryCheckbox'

const SearchFiltersBox = createReactClass({
  handleFeatureEventClick: function (id, checked) {
    this.props.onAddEventFeature(id, true, true)
  },

  isFeatureEventSelected: function (id) {
    return this.isInList(this.props.selectedEvents, id)
  },

  isInList: function (list, id) {
    const index = findIndex(list, function (el) {
      return el === id
    })
    return index !== -1
  },

  renderEventFeature: function (feature) {
    return (
      <SearchFilterFeatureCheckbox
        key={feature.id}
        feature={feature}
        selected={this.isFeatureEventSelected(feature.id)}
        handleClick={this.handleFeatureEventClick}
      />
    )
  },

  renderCategory: function (category, index) {
    return (
      <SearchFilterCategoryCheckbox
        key={index}
        category={category}
        onAddCategory={this.props.onAddCategory}
        onSelectCategories={this.props.onSelectCategories}
        onRemoveCategories={this.props.onRemoveCategories}
        selectedCategories={this.props.selectedCategories}
        categoriesDictionary={this.props.filters.categories}
      />
    )
  },

  render: function () {
    const eventNodes = sortBy(
      this.props.filters.event_features,
      function (event) {
        return event.label.length
      }
    ).map(this.renderEventFeature)
    var topLevelCategories = filter(
      this.props.filters.categories,
      function (c) {
        return c.parents.length === 0
      }
    )
    var categoryNodes = topLevelCategories.map(this.renderCategory)
    return (
      <div>
        <div className="label-filters">Évènement</div>
        <div className="search-filters-box event-types-filter">
          {eventNodes}
        </div>
        <div className="label-filters">Catégorie</div>
        <div className="search-filters-box">{categoryNodes}</div>
      </div>
    )
  }
})
export default SearchFiltersBox
