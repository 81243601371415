import React from 'react'
import createReactClass from 'create-react-class'

var CouponAutoCompleteBundle = createReactClass({
  componentDidMount: function () {
    $('#coupon_bundle').autocomplete({
      source: function (request, response) {
        $.ajax({
          url: '/admin/coupons/list_bundles',
          method: 'GET',
          data: {
            term: request.term
          },
          success: function (data) {
            response(data)
          }
        })
      }
    })
  },

  render: function () {
    return <div />
  }
})

export default CouponAutoCompleteBundle
