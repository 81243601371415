import React from 'react'
import PropTypes from 'prop-types'
import { find } from 'lodash'
import Dropdown from 'components/search/Dropdown'
import { connectRefinementList } from 'react-instantsearch/connectors'

const Filter = ({ items, initialItems, defaultLabel, refine }) => {
  const handleClick = (value) => {
    refine(value)
  }

  const selectedValue = () => {
    const { label } = find(initialItems || items, { isRefined: true }) || {
      label: defaultLabel
    }
    return label
  }

  return (
    <Dropdown
      title={selectedValue()}
      refined={selectedValue() !== defaultLabel}
      id="feature-toggle"
      selectClassName="input-select feature-select">
      <div className="feature-filter">
        {(initialItems || items).map((item) => (
          <div
            className={`feature-option ${
              selectedValue() === item.label ? 'refined' : ''
            }`}
            key={item.label}
            onClick={() => handleClick(item.label)}>
            {item.label}
          </div>
        ))}
      </div>
    </Dropdown>
  )
}

Filter.propTypes = {
  items: PropTypes.array,
  initialItems: PropTypes.array,
  defaultLabel: PropTypes.string,
  refine: PropTypes.func
}

export default connectRefinementList(Filter)
