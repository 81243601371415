import Activity from './activity'
import Offer from './offer'
import PricePolicy from './price_policy'
import PriceTier from './price_tier'
import PriceVariant from './price_variant'
import TimeMask from './time_mask'
import Timeframe from './timeframe'
import Season from './season'

// Returns a lis of all available models, a bit quirky but it works well
export default function () {
  return {
    Season,
    Activity,
    Offer,
    PricePolicy,
    PriceTier,
    PriceVariant,
    TimeMask,
    Timeframe
  }
}
