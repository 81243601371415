import React, { useEffect } from 'react'
import BookingHeader from './BookingHeader'
import { ceil, flatten, map, sum, values, isEmpty } from 'lodash'
import display from 'lib/Display'

const Modal = ({
  modalId,
  giftMode,
  activity,
  infosFunnel,
  children,
  steps,
  currentStepIndex,
  maxStepReachedIndex,
  goToStep,
  chosenDate,
  chosenTime,
  variantQuantities,
  displayNextButton,
  onButtonClick,
  onClose,
  modalRef,
  priceSummary,
  publicQuoteId
}) => {
  const isLast = currentStepIndex === steps.length - 1
  const buttonLabel = isLast
    ? publicQuoteId
      ? 'Ajouter au devis'
      : 'Ajouter au panier'
    : 'Suivant'

  useEffect(() => {
    $(modalRef.current).on('hidden.bs.modal', onClose)
  }, [])
  const buttonDisabled = priceSummary && isEmpty(priceSummary)

  const computeTotalPrice = () => {
    if (priceSummary) {
      return ceil(
        sum(
          flatten(
            map(priceSummary, function (v, k) {
              return values(v)
            })
          )
        ),
        2
      )
    } else {
      return 0
    }
  }

  return (
    <div
      id={modalId}
      className="modal fade booking-module"
      ref={modalRef}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="bookingModal"
      data-rspec={modalId}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header px-5-md py-0">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body py-0 px-5-md">
            <BookingHeader
              steps={steps}
              currentStepIndex={currentStepIndex}
              maxStepReachedIndex={maxStepReachedIndex}
              goToStep={goToStep}
              chosenDate={chosenDate}
              chosenTime={chosenTime}
              variantQuantities={variantQuantities}
              activity={activity}
              giftMode={giftMode}
              infosFunnel={infosFunnel}
            />
            {children}
          </div>
          {displayNextButton && (
            <div className="modal-footer px-5-md">
              <div className="row">
                <div className="col-sm-6">
                  {isLast && (
                    <strong>
                      Total : {display.currency(computeTotalPrice())}
                    </strong>
                  )}
                </div>
                <div className="col-sm-6">
                  <button
                    onClick={onButtonClick}
                    type="button"
                    className="btn btn-primary btn-cta"
                    disabled={buttonDisabled}>
                    {buttonLabel}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
