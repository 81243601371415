import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { capitalize, findIndex, isEmpty, map, sortBy } from 'lodash'
import PriceTierForm from './PriceTierForm'

class PriceVariants extends Component {
  constructor(props) {
    super(props)

    this.store = this.props.store

    this.state = {
      pricePolicy: props.pricePolicy,
      activity: this.store.activity
    }
  }

  // Save the activity & price policy
  saveToStore() {
    var pricePolicyIndex = findIndex(
      this.store.offer.price_policies,
      (policy) => policy.id === this.state.pricePolicy.id
    )
    this.store.offer.price_policies[pricePolicyIndex] = this.state.pricePolicy
    this.store.activity = this.state.activity
  }

  createPriceTierForVariant(variant) {
    var { pricePolicy, activity } = this.state

    pricePolicy.buildPriceTierForVariant(activity, variant)

    this.setState({ pricePolicy, activity })
    this.saveToStore()
  }

  createPriceTierWithNewVariant() {
    var { pricePolicy, activity } = this.state

    pricePolicy.buildPriceTierForVariant(activity)

    this.setState({ pricePolicy, activity })
    this.saveToStore()
  }

  deletePriceTier(priceTier) {
    var { pricePolicy, activity } = this.state

    pricePolicy.deletePriceTier(priceTier, activity)

    this.setState({ pricePolicy })
    this.saveToStore()
  }

  toggleVariantUsage(variant) {
    var { pricePolicy, activity } = this.state

    if (pricePolicy.isUsingVariant(variant.id)) {
      pricePolicy.deletePriceTiersForVariant(variant, activity)
    } else {
      pricePolicy.buildPriceTierForVariant(activity, variant)
    }

    this.setState({ pricePolicy })
    this.saveToStore()
  }

  deleteVariantUsage(variant) {
    if (
      confirm(
        'Êtes-vous sûr ? \n' +
          'Si vous désactivez cette variante, vous perdrez tous les tarifs associés.'
      ) === true
    ) {
      this.toggleVariantUsage(variant)
    }
  }

  updateVariant(e, variant, field) {
    var { activity, pricePolicy } = this.state
    variant[field] = field === 'option' ? e.target.checked : e.target.value

    activity.updateVariant(variant)
    pricePolicy.updateVariant(variant)

    this.setState({ activity, pricePolicy })
    this.saveToStore()
  }

  archiveVariant(variant) {
    var { activity, pricePolicy } = this.state

    variant.should_archive = true

    activity.updateVariant(variant)
    pricePolicy.updateVariant(variant)

    this.setState({ activity, pricePolicy })
    this.saveToStore()
  }

  render() {
    const { pricePolicy, activity } = this.state

    return (
      <div>
        <div className="price-variants">
          {activity.price_variants
            .filter(
              (priceVariant) =>
                !priceVariant.archived_at && !priceVariant.should_archive
            )
            .map((variant) => (
              <span
                className={`price-variant mini-card ${
                  isEmpty(variant.name) ? 'hide' : ''
                } ${pricePolicy.isUsingVariant(variant.id) ? 'active' : ''}`}
                key={`variant-${variant.id}`}>
                <label htmlFor={`input-variant-${variant.id}`}>
                  {capitalize(variant.name)}
                </label>
                <input
                  type="checkbox"
                  onChange={(e) => this.toggleVariantUsage(variant)}
                  checked={pricePolicy.isUsingVariant(variant.id)}
                  id={`input-variant-${variant.id}`}
                />
                {!pricePolicy.isUsingVariant(variant.id) && (
                  <button onClick={() => this.archiveVariant(variant)}>
                    X
                  </button>
                )}
              </span>
            ))}
          {pricePolicy
            .priceVariants()
            .filter((priceVariant) => !priceVariant.archived_at)
            .map((variant) => (
              <div
                className="form-inline mt-1"
                key={`variant-fields-${variant.id}`}>
                <div className="form-group">
                  <input
                    className="form-control mr-1"
                    type="text"
                    onChange={(e) => this.updateVariant(e, variant, 'name')}
                    defaultValue={variant.name}
                    placeholder="Groupe, enfant..."
                  />
                </div>
                <div className=" form-group mr-1">
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) =>
                      this.updateVariant(e, variant, 'description')
                    }
                    defaultValue={variant.description}
                    placeholder="Description"
                  />
                </div>
                <div className=" form-group mr-1">
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) =>
                      this.updateVariant(e, variant, 'quantity_type')
                    }
                    defaultValue={variant.quantity_type}
                    placeholder="Unité"
                  />
                </div>
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => this.updateVariant(e, variant, 'option')}
                      defaultChecked={variant.option}
                      placeholder="Option"
                      id={`input-variant-${variant.id}`}
                    />
                    Option
                  </label>
                </div>
              </div>
            ))}
          <a onClick={(e) => this.createPriceTierWithNewVariant()}>
            Ajouter une variante
          </a>
        </div>
        <div className="price-tiers">
          <h4>Tarification échelonnée :</h4>
          {map(pricePolicy.priceTiersByVariant(), (priceTiers, variantId) => (
            <div className="price-tier" key={`price-tier-variant-${variantId}`}>
              <h5>
                {capitalize(priceTiers[0].price_variant.name || '_______')}
              </h5>
              <a
                className="btn btn-delete btn-floating"
                onClick={(e) =>
                  this.deleteVariantUsage(priceTiers[0].price_variant)
                }>
                X
              </a>
              {map(
                sortBy(priceTiers, (pt) => pt.lower_echelon),
                (priceTier) => (
                  <PriceTierForm
                    store={this.store}
                    pricePolicy={pricePolicy}
                    priceTier={priceTier}
                    deletePriceTier={this.deletePriceTier.bind(this)}
                    key={`price-tier-form-${priceTier.id}`}
                  />
                )
              )}
              <a
                onClick={(e) =>
                  this.createPriceTierForVariant(priceTiers[0].price_variant)
                }>
                Ajouter un échelon
              </a>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

PriceVariants.propTypes = {
  store: PropTypes.object.isRequired,
  pricePolicy: PropTypes.object.isRequired
}

PriceVariants.defaultProps = {}

export default PriceVariants
