import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const get = (obj, path, defaultValue = undefined) => {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      )
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

const ResultMarkup = ({ link, children }) => (
  <a href={link} className="aa-ItemLink" target="_blank" rel="noreferrer">
    <div className="aa-ItemContent">
      <div className="aa-ItemContentBody">
        <div className="aa-ItemContentTitle">{children}</div>
      </div>
    </div>
    <div className="aa-ItemActions">
      <button
        className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
        type="button"
        title="Select">
        <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
          {' '}
          <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />{' '}
        </svg>
      </button>
    </div>
  </a>
)

const ShowHighlightConditionally = ({ item, target, components }) => {
  const highlight = get(item._highlightResult, target)
  if (!highlight || highlight.matchLevel === 'none') {
    return null
  }
  return (
    <Fragment>
      {' - '}
      <components.Highlight hit={item} attribute={target} />
    </Fragment>
  )
}

const Activity = ({ item, components }) => {
  return (
    <ResultMarkup link={item.aa_public_path}>
      <span className="label label-primary">Activité</span>{' '}
      <components.Highlight hit={item} attribute="id" />
      {' - '}
      <components.Highlight hit={item} attribute="aa_center_name" />
      {' - '}
      <components.Highlight hit={item} attribute="name" />
    </ResultMarkup>
  )
}

const Order = ({ item, components }) => {
  return (
    <ResultMarkup link={item.aa_admin_path}>
      <span className="label label-default">Réservation</span>{' '}
      <components.Highlight hit={item} attribute="id" />
      {' - '}
      <components.Highlight hit={item} attribute={['aa_user', 'email']} />
      <ShowHighlightConditionally
        components={components}
        item={item}
        target={['aa_user', 'phone']}
      />
      <ShowHighlightConditionally
        components={components}
        item={item}
        target={['aa_doer', 'email']}
      />
      <ShowHighlightConditionally
        components={components}
        item={item}
        target={['aa_doer', 'phone']}
      />
      <ShowHighlightConditionally
        components={components}
        item={item}
        target={['gift_token']}
      />
    </ResultMarkup>
  )
}

const Quote = ({ item, components }) => {
  return (
    <ResultMarkup link={item.aa_admin_path}>
      <span className="label label-info">Demande de devis</span> {item.id}
      {' - '}
      <components.Highlight hit={item} attribute="email" />
      <ShowHighlightConditionally
        components={components}
        item={item}
        target={['name']}
      />
      <ShowHighlightConditionally
        components={components}
        item={item}
        target={['phone']}
      />
    </ResultMarkup>
  )
}

const GiftCardOrder = ({ item, components }) => {
  return (
    <ResultMarkup link={item.aa_admin_path}>
      <span className="label label-success">Carte cadeau</span>{' '}
      <components.Highlight hit={item} attribute="id" />
      {' - '}
      <components.Highlight hit={item} attribute="token" />
    </ResultMarkup>
  )
}

const Contractor = ({ item, components }) => {
  return (
    <ResultMarkup link={item.aa_admin_path}>
      <span className="label label-warning">Prestataire</span>{' '}
      <components.Highlight hit={item} attribute="name" />
      {' - '}
      <components.Highlight hit={item} attribute="email" />
      <ShowHighlightConditionally
        components={components}
        item={item}
        target={['lname']}
      />
      <ShowHighlightConditionally
        components={components}
        item={item}
        target={['phone']}
      />
      {item.aa_center_names.map((_center, i) => (
        <ShowHighlightConditionally
          key={i}
          components={components}
          item={item}
          target={['aa_center_names', i]}
        />
      ))}
    </ResultMarkup>
  )
}

const ResultItem = ({ item, components }) => {
  return (
    <Fragment>
      {(item.type === 'activity' && (
        <Activity item={item} components={components} />
      )) ||
        (item.type === 'order' && (
          <Order item={item} components={components} />
        )) ||
        (item.type === 'quote' && (
          <Quote item={item} components={components} />
        )) ||
        (item.type === 'giftCardOrder' && (
          <GiftCardOrder item={item} components={components} />
        )) ||
        (item.type === 'contractor' && (
          <Contractor item={item} components={components} />
        ))}
    </Fragment>
  )
}

ResultMarkup.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string
}

ShowHighlightConditionally.propTypes = {
  item: PropTypes.object,
  components: PropTypes.object,
  target: PropTypes.array
}

ResultItem.propTypes =
  Order.propTypes =
  Activity.propTypes =
  Quote.propTypes =
  GiftCardOrder.propTypes =
  Contractor.propTypes =
    {
      item: PropTypes.object,
      components: PropTypes.object
    }

export default ResultItem
