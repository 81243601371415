import EVENTS from './constants'

const googleTracker = {
  enabled: () => typeof gtag !== 'undefined',

  trackCompletedQuote: () => {
    if (googleTracker.enabled()) {
      googleTracker._trackEvent(
        EVENTS.QUOTE,
        EVENTS.QUOTE_CREATE,
        EVENTS.getPathAsLabel()
      )
    }
  },

  trackInitiateCheckout: (info) => {
    if (googleTracker.enabled()) {
      googleTracker._trackCheckoutStep(info, 'add_to_cart')
    }
  },

  trackValidatedCart: (info) => {
    if (googleTracker.enabled()) {
      googleTracker._trackCheckoutStep(info, 'begin_checkout')
    }
  },

  trackCompletedRegistration: (info) => {
    if (googleTracker.enabled()) {
      googleTracker._trackCheckoutStep(info, 'add_shipping_info')
    }
  },

  trackAddPaymentInfo: (info) => {
    if (googleTracker.enabled()) {
      googleTracker._trackCheckoutStep(info, 'add_payment_info')
    }
  },

  trackPurchase: (info) => {
    if (googleTracker.enabled()) {
      googleTracker._trackCheckoutStep(info, 'purchase')
    }
  },

  trackPageView: (path) => {
    if (googleTracker.enabled()) {
      if (typeof path !== 'undefined')
        gtag('send', {
          hitType: 'pageview',
          page: path
        })
      else {
        gtag('send', 'pageview')
      }
    }
  },

  _trackEvent: (category, action, label) => {
    gtag('send', 'event', category, action, label, {
      nonInteraction: true
    })
  },

  _trackCheckoutStep: (info, step) => {
    gtag('event', step, {
      currency: 'EUR',
      value: info.price,
      coupon: info.coupon,
      type: info.isGift,
      ...(step === 'purchase' && { transaction_id: info.orderId }),
      items: [
        {
          item_id: info.activityId,
          item_name: info.activityName,
          location_id: info.centerId,
          coupon: info.coupon,
          price: info.price,
          quantity: 1
        }
      ]
    })
    gtag('send', 'pageview')
  }
}

export default googleTracker
