import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'

var BabaSubmit = createReactClass({
  displayName: 'BabaSubmit',

  render: function () {
    var buttonClasses = classNames(
      'btn btn-primary',
      this.props.buttonClasses
    )
    return (
      <div className="form-group">
        <input
          type="submit"
          name="commit"
          value={this.props.label}
          className={buttonClasses}
          disabled={this.props.disabled}
        />
      </div>
    )
  }
})

export default BabaSubmit
