import moment from 'moment'

var Display = {
  addIfNotEmpty: function (input, number, suffix, addSuffixOnlyIfFirst) {
    if (number > 0) {
      var result = input + number
      if (!addSuffixOnlyIfFirst || (addSuffixOnlyIfFirst && input === '')) {
        result += suffix
      }
      return result
    } else {
      return input
    }
  },

  duration: function (duration, fullDay) {
    if (fullDay) {
      return '1j'
    } else {
      var duration = moment.duration(duration, 'seconds')
      var days = duration.days()
      var hours = duration.hours()
      var minutes = duration.minutes()
      var seconds = duration.seconds()
      var result = ''
      result = this.addIfNotEmpty(result, days, 'j')
      result = this.addIfNotEmpty(result, hours, 'h')
      result = this.addIfNotEmpty(result, minutes, 'min', true)
      result = this.addIfNotEmpty(result, seconds, 'sec', true)

      return result
    }
  },

  hour: function (hour, minutes) {
    const date = moment().hours(hour).minutes(minutes)
    return date.format('HH:mm')
  },

  currency: function (price, decimals) {
    if (arguments.length === 1) {
      decimals = 2
    }
    var value = parseFloat(price)
    var rounded = this._round(value, decimals)
    var formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })

    return formatter.format(rounded)
  },

  pluralizeQuantityType: function (quantityType, count, withQuantity = true) {
    const [singular, plural] = quantityType.split('/').map((el) => el.trim())
    const type = count === 1 || count === 0 ? singular : plural
    if (withQuantity) {
      return `${count} ${type}`
    } else {
      return type
    }
  },

  _round: function (number, decimals) {
    var multiplier = Math.pow(10, decimals)
    return Math.round(number * multiplier) / multiplier
  }
}

export default Display
