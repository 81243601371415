import PropTypes from 'prop-types'
import React from 'react'
import createReactClass from 'create-react-class'
import CardRegistrationForm from 'components/bookings/CardRegistrationForm'
import display from 'lib/Display'
import apiHelper from 'lib/ApiHelper'
import * as Sentry from '@sentry/react'
import MangopayHelper from 'lib/MangopayHelper'

var GiftCardPaymentForm = createReactClass({
  displayName: 'GiftCardPaymentForm',

  contextTypes: {
    handleValidationSuccessful: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
    pushComponent: PropTypes.func
  },

  getInitialState: function () {
    return {
      showPaymentForm: true,
      paymentSuccess: false,
      paymentError: false
    }
  },

  getDefaultProps: function () {
    return {
      giftCardMode: true
    }
  },
  errorOverlay: function (msg) {
    this.context.stopLoading()
    this.setState({ errors: apiHelper.parseErrorResponse(msg) })
  },
  paymentSuccess: function () {
    this.context.stopLoading()
    this.setState({ paymentSuccess: true, showPaymentForm: false })
  },

  handleSubmit: function () {
    if (this.state.paymentSuccess) {
      if (this.context.handleValidationSuccessful) {
        this.context.handleValidationSuccessful()
      }
    }
  },

  cardRegistrationSuccess: function (mangopayId, cardId) {
    var paymentSuccessFn = this.paymentSuccess
    var errorOverlayFn = this.errorOverlay

    var proceedPaymentUrl = '/fast_checkout/create/' + this.props.amount

    $.ajax({
      url: proceedPaymentUrl,
      type: 'POST',
      cache: false,
      data: {
        mangopayId: mangopayId,
        cardId: cardId,
        personalizedMessage: this.props.persoMsg,
        mangopay_browser_info: MangopayHelper.getBrowserInfo(navigator, screen)
      }
    })
      .done(function (data) {
        if (data.redirect) {
          window.location.href = data.url
        } else {
          paymentSuccessFn(data)
        }
      })
      .fail(function (msg) {
        errorOverlayFn(msg)
      })
  },

  render: function () {
    var callToActionText
    var successText
    var subtitleText

    callToActionText = 'Payer ' + display.currency(this.props.amount)
    subtitleText = null
    successText = 'Voir la carte cadeau'

    if (this.state.showPaymentForm) {
      return (
        <Sentry.ErrorBoundary fallback="An error has occured">
          <div className="checkout-payment">
            <div className="row">
              <div className="col-xs-12">
                <CardRegistrationForm
                  callToActionText={callToActionText}
                  subtitleText={subtitleText}
                  startLoading={this.context.startLoading}
                  stopLoading={this.context.stopLoading}
                  cardRegistrationSuccess={this.cardRegistrationSuccess}
                  images={this.props.images}
                  isGift
                  urls={this.props.urls}
                  priceAfter={this.props.amount}
                />
              </div>
            </div>
          </div>
        </Sentry.ErrorBoundary>
      )
    } else if (this.state.paymentSuccess) {
      return (
        <Sentry.ErrorBoundary fallback="An error has occured">
          <div>
            Votre paiement a été validé. <br />
            <br />
            <button
              className="btn baba-primary btn-cta btn-lg"
              onClick={this.handleSubmit}>
              {successText}
            </button>
          </div>
        </Sentry.ErrorBoundary>
      )
    } else {
      return (
        <Sentry.ErrorBoundary fallback="An error has occured">
          <div className="text-center">
            Il y a eu une erreur lors de votre paiement. <br />
            <br />
          </div>
        </Sentry.ErrorBoundary>
      )
    }
  }
})

export default GiftCardPaymentForm
