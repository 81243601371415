import PropTypes from 'prop-types'
import React from 'react'
import createReactClass from 'create-react-class'
import OrderCheckoutValidationCancellationPolicyForm from 'components/bookings/OrderCheckoutValidationCancellationPolicyForm'
import OrderCheckoutValidationShippingMethodForm from 'components/bookings/OrderCheckoutValidationShippingMethodForm'
import BabaForm from 'components/forms/Index'
import googleTracker from 'lib/trackers/googleTracker'
import NavigationButtons from 'components/bookings/NavigationButtons'

const OrderCheckoutValidation = createReactClass({
  displayName: 'OrderCheckoutValidation',

  contextTypes: {
    handleValidationSuccessful: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func
  },

  handleContinue: function () {
    this.context.startLoading()
    this.context.stopLoading()
    this.context.handleValidationSuccessful()
    if (this.props.trackingInfo) {
      googleTracker.trackValidatedCart(this.props.trackingInfo)
    }
  },

  render: function () {
    const emailShippingMethod = {
      baseAmount: '0',
      code: 'email',
      headline: 'A imprimer chez soi',
      name: 'Email',
      selected: !this.props.order.shippingMethod,
      shippingAmount: '0'
    }
    const availableShippingMethods = [emailShippingMethod].concat(
      this.props.order.availableShippingMethods
    )

    return (
      <div
        className="checkout-validation"
        data-rspec="checkout-validation-container">
        <BabaForm.BabaForm onSubmit={this.handleContinue}>
          <div className="step-title">Vos options</div>
          {this.props.order.isGift && (
            <div className="mb-2">
              <div className="mb-2">Sélectionnez vos options cadeau</div>
              {availableShippingMethods.map(
                (availableShippingMethod, index) => {
                  return (
                    <OrderCheckoutValidationShippingMethodForm
                      key={index}
                      shippingMethod={availableShippingMethod}
                      onOrderModification={this.props.onOrderModification}
                      shippingMethodUpdateURL={
                        this.props.urls.shippingMethodUpdateURL
                      }
                      selectedShippingMethodCode={
                        this.props.order.selectedShippingMethodCode
                      }
                    />
                  )
                }
              )}
            </div>
          )}
          {this.props.order.availableCancellationPolicies.map(
            (availableCancellationPolicy, index) => {
              return (
                <OrderCheckoutValidationCancellationPolicyForm
                  key={index}
                  cancellationPolicy={availableCancellationPolicy}
                  onOrderModification={this.props.onOrderModification}
                  updateCancellationPolicyUrl={
                    this.props.urls.cancellationPolicyUpdateURL
                  }
                />
              )
            }
          )}
          <NavigationButtons
            priceAfter={this.props.order.priceAfter}
            goPrevious={this.context.goPrevious}
            submitLabel="Suivant"
            handleValidate={null}
            previousStepURL={this.props.previousStepURL}
          />
        </BabaForm.BabaForm>
      </div>
    )
  }
})
export default OrderCheckoutValidation
