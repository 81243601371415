import React from 'react'
import imageUrl from 'images/gift_cards/card-100.png'
import { isMobile } from 'react-device-detect'

const GiftCardNode = () => (
  <a
    className="link-unstyled"
    href="/cadeaux/cartes-cadeaux"
    target={isMobile ? '_self' : '_blank'}
    rel={isMobile ? '' : 'noreferrer'}>
    <div className="hit-card hit-card-promo">
      <div className="img-container">
        <img src={imageUrl} alt="Carte cadeau Babasport" />
      </div>
      <div className="content">
        <div className="promo-title">Carte cadeau Babasport</div>

        <div className="hit-features">
          <div>+ de 2000 activités en France et en Europe</div>
          <div>Valable 1 an, renouvelable 12 mois</div>
          <div>Pour 1 ou plusieurs personnes</div>
        </div>
      </div>
      <div className="hit-card-bottom-container"></div>
    </div>
  </a>
)

export default GiftCardNode
