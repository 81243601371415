import { mixin, each, isObjectLike, chain, isArray } from 'lodash'
import LodashInflector from 'lodash-inflection'
import uuid from 'lib/uuid'

// Adds .singularize to lodash
mixin(LodashInflector)

/*
 * Base class for all models used in the store
 * When instantiated, will look for objects and arrays and use the key's name
 * to find the model type and instantiate a new model object.
 * This means that for instance:
 * (provided that `PriceTier` and `TimeMask` both extend `Base`)
 *
 * price_tier = new PriceTier({
 *   time_masks: [{...}, {...}]
 * });
 *
 * Will instantiate a `new PriceTier()` and two `new TimeMask({...})`
 * This allows us to create helper methods for every models while
 * being able to build our model and relations from just one big json object.
 *
 * This means that after that you will be able to do
 *
 * price_tier.someHelperMethod();
 * price_tier.time_masks[0].someOtherHelperMethod();
 *
 * provided that `someHelperMethod();` and `someOtherHelperMethod();`
 * are defined in the respective model classes.
 */
class Base {
  constructor(attributes) {
    each({ ...this.defaultAttributes(), ...attributes }, (value, key) => {
      if (isObjectLike(value)) {
        const modelName = chain(key)
          .camelCase()
          .upperFirst()
          .singularize()
          .value()
        const FoundRelationClass = this.availableModels()[modelName]

        if (FoundRelationClass) {
          if (isArray(value)) {
            this[key] = value.map((record) => new FoundRelationClass(record))
          } else {
            this[key] = new FoundRelationClass(value)
          }
        } else {
          this[key] = value
        }
      } else {
        this[key] = value
      }
    })

    if (!this.id) {
      this.id = uuid()
    }
  }

  // Default attributes are used when instantiating a new model
  // Each subclass can implement it
  defaultAttributes() {
    return {}
  }

  availableModels() {
    // Quirky but needs to be implemented in subclasses because of circular dependencies issues.
    // A class cannot be imported before it's parent class is defined
    // this we do the import in the child classes, the models.
    // Every model needs to implement this method with this code:
    // import AvailableModels from './collection';
    // AvailableModels();
  }
}
export default Base
