import React, { Fragment } from 'react'
import createReactClass from 'create-react-class'
import MultipleStepsForm from 'components/funnel/MultipleStepsForm'
import InformationForm from 'components/bookings/InformationForm'
import MessageForm from 'components/bookings/MessageForm'
import GiftCardPaymentForm from 'components/bookings/GiftCardPaymentForm'
import display from 'lib/Display'
import BookingFaqs from 'components/bookings/BookingFaqs'
import * as Sentry from '@sentry/react'
import lockIcon from 'images/icons/lock.svg'

var FastCheckout = createReactClass({
  displayName: 'FastCheckout',

  getInitialState: function () {
    return {
      order: this.props.order,
      user: this.props.user
    }
  },

  componentDidMount: function () {
    const script = document.createElement('script')
    script.src =
      'https://widgets.rr.skeepers.io/generated/e0619e69-b4b3-dd44-911b-45b8a77e037e/e36c503a-fa16-45d8-b180-be41115fd0e4.js'
    script.async = true
    document.body.appendChild(script)
  },

  handlePersonalizedMessageChange: function (message) {
    this.setState({
      order: { total: this.state.order.total, personalized_message: message }
    })
  },

  handleUserModification: function (data) {
    this.setState({ user: data })
  },

  render: function () {
    const informationForm = (
      <InformationForm
        authenticityToken={this.props.authenticity_token}
        user={this.state.user}
        onUserModification={this.handleUserModification}
        urls={this.props.urls}
        priceAfter={this.props.order.total}
      />
    )
    const messageForm = (
      <MessageForm
        authenticityToken={this.props.authenticity_token}
        priceAfter={this.props.order.total}
        personalizedMessage={this.props.order.personalized_message}
        didFinishEditing={this.handlePersonalizedMessageChange}
        giftCardsURL={this.props.urls.giftCardsURL}
      />
    )
    const cardForm = (
      <GiftCardPaymentForm
        images={this.props.images}
        amount={this.state.order.total}
        persoMsg={this.state.order.personalized_message}
        urls={this.props.urls}
      />
    )

    return (
      <Sentry.ErrorBoundary fallback="An error has occured">
        <div className="container mb-3">
          <MultipleStepsForm
            images={this.props.images}
            homepageURL={this.props.urls.homepageURL}
            steps={[
              {
                name: 'Personnalisation',
                content: messageForm,
                url: this.props.steps.messageUrl
              },
              {
                name: 'Informations',
                content: informationForm,
                url: this.props.steps.informationUrl
              },
              {
                name: 'Paiement',
                content: cardForm,
                url: this.props.steps.paymentUrl
              }
            ]}
            initialStep={this.props.initialStep}>
            <Fragment>
              <div className="order-recap-container hidden-xs">
                <div className="gift-card-example">
                  <img
                    src={this.props.images.gift_card_image}
                    className="img-responsive img-center"
                    alt="Carte cadeau"
                  />
                </div>
                <div className="content">
                  <div className="totals-component">
                    <div className="totals">
                      <div className="total-final row">
                        <div className="col-xs-8 total-wording">Total :</div>
                        <div className="col-xs-4 text-right total-amount">
                          {display.currency(this.state.order.total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row secure-payment-banner text-center">
                <div className="col-xs-12">
                  <img
                    src={lockIcon}
                    alt="Paiement sécurisé"
                    className="mr-2"
                    height="12px"
                    width="12px"
                  />
                  <strong>Paiement 100% sécurisé</strong>
                </div>
              </div>
              <div className="col-xs-12 text-center">
                <div
                  className="center-child-link"
                  id="e36c503a-fa16-45d8-b180-be41115fd0e4"></div>
              </div>
            </Fragment>
          </MultipleStepsForm>
          <BookingFaqs
            type="gift_card"
            babaPhoneNumber={this.props.baba_phone_number}
          />
        </div>
      </Sentry.ErrorBoundary>
    )
  }
})

export default FastCheckout
