import React from 'react'
import PropTypes from 'prop-types'

const Faq = ({ title, items }) => {
  return (
    <div
      className="faq-section"
      itemScope
      itemType="https://schema.org/FAQPage">
      <h2>{title}</h2>
      <div
        className="panel-group"
        id="faq-section"
        role="tablist"
        aria-multiselectable="true">
        {items.map((item, id) => (
          <div
            className="panel"
            key={id}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question">
            <a
              className="collapsed"
              role="button"
              data-toggle="collapse"
              data-parent="faq-section"
              href={'#faq-collapse-' + id}
              aria-expanded="true"
              aria-controls="faqCollapseOne">
              <div
                className="panel-heading"
                role="tab"
                id={'faq-heading-' + id}>
                <h3 className="panel-title">
                  <span className="glyphicon glyphicon-check" />
                  <span itemProp="name">{item.question}</span>
                  <span className="glyphicon glyphicon-menu-down" />
                  <span className="glyphicon glyphicon-menu-up" />
                </h3>
              </div>
            </a>
            <div
              itemScope
              itemProp="acceptedAnswer"
              itemType="https://schema.org/Answer"
              id={'faq-collapse-' + id}
              className="panel-collapse collapse in"
              role="tabpanel">
              <div
                itemProp="text"
                dangerouslySetInnerHTML={{ __html: item.answer }}
                className="panel-body"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Faq.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array
}

Faq.defaultProps = {}

export default Faq
