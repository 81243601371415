import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'

const SearchFilterFeatureCheckbox = createReactClass({
  handleClick: function () {
    this.props.handleClick(this.props.feature.id, !this.props.selected)
  },

  render: function () {
    var selectedUrl = this.props.feature.icon
    var unselectedUrl = this.props.feature.grey_icon || this.props.feature.icon
    var url = this.props.selected ? selectedUrl : unselectedUrl
    return (
      <div
        className={classNames('feature-filter', {
          'filter-selected': this.props.selected
        })}
        onClick={this.handleClick}>
        <img
          className="feature-filter-img"
          src={url}
          alt={this.props.feature.label}
        />
        <img
          className="feature-filter-img-hover"
          src={selectedUrl}
          alt={this.props.feature.label}
        />
        <label className="feature-filter-label">
          {this.props.feature.label}
        </label>
      </div>
    )
  }
})

export default SearchFilterFeatureCheckbox
