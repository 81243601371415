import PropTypes from 'prop-types'
import React, { Component } from 'react'
import CreateSessionForm from '../users/CreateSessionForm'
import EditUserAndOrderForm from './EditUserAndOrderForm'

class InformationForm extends Component {
  displayName = 'UserForm'

  constructor(props) {
    super(props)
    this.state = {
      user: props.user,
      order: props.order
    }
  }

  userDidLogIn = (orderOrUser, skipValidation = false) => {
    this.context.stopLoading()
    let order = null
    let user = null
    if (Object.hasOwn(orderOrUser, 'user')) {
      order = orderOrUser
      user = orderOrUser.user
    } else {
      user = orderOrUser
    }
    this.setState({ order: order, user: user }, () => {
      this.props.onUserModification(user)
      if (this.props.onOrderModification) {
        this.props.onOrderModification(order)
      }
      if (skipValidation && this.context.handleValidationSuccessful) {
        this.context.handleValidationSuccessful()
      }
    })
  }

  informationWasModified = (orderOrUser) => {
    let order = null
    let user = null
    if (Object.hasOwn(orderOrUser, 'user')) {
      order = orderOrUser
      user = orderOrUser.user
    } else {
      user = orderOrUser
    }
    this.setState({ order: order, user: user }, () => {
      this.props.onUserModification(user)
      if (this.props.onOrderModification) {
        this.props.onOrderModification(order)
      }
      if (this.context.handleValidationSuccessful) {
        this.context.handleValidationSuccessful()
      }
    })
  }

  render = () => {
    const isExistingRecord =
      this.state.user !== undefined && this.state.user.id !== null

    if (!isExistingRecord) {
      return (
        <CreateSessionForm
          urls={this.props.urls}
          user={this.state.user}
          userDidLogIn={this.userDidLogIn}
          authenticityToken={this.props.authenticityToken}
          order={this.props.order}
          priceAfter={this.props.priceAfter}
          claimGift={this.props.claimGift}
          previousStepURL={this.props.previousStepURL}
        />
      )
    } else {
      return (
        <EditUserAndOrderForm
          user={this.state.user}
          urls={this.props.urls}
          authenticityToken={this.props.authenticityToken}
          order={this.props.order}
          informationWasModified={this.informationWasModified}
          priceAfter={this.props.priceAfter}
          claimGift={this.props.claimGift}
          previousStepURL={this.props.previousStepURL}
        />
      )
    }
  }
}

InformationForm.propTypes = {
  user: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  urls: PropTypes.object,
  onUserModification: PropTypes.func,
  trackingInfo: PropTypes.object,
  onOrderModification: PropTypes.func,
  order: PropTypes.object,
  priceAfter: PropTypes.string,
  claimGift: PropTypes.bool
}

InformationForm.defaultProps = {
  claimGift: false
}

InformationForm.contextTypes = {
  handleValidationSuccessful: PropTypes.func,
  startLoading: PropTypes.func,
  stopLoading: PropTypes.func
}

export default InformationForm
