import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'
import { findWhere, findIndex, sortBy } from 'lodash'
import SearchFilterSubCategoryCheckbox from 'components/search/SearchFilterSubCategoryCheckbox'

const SearchFilterCategoryCheckbox = createReactClass({
  getInitialState: function () {
    return {
      expanded: true
    }
  },

  handleSubCategoryClick: function (id) {
    this.props.onAddCategory(id, true, true)
  },

  handleParentCategoryClick: function () {
    const wasSelected = this.isCategorySelected(this.props.category.id)
    const idsArray = [this.props.category.id].concat(
      this.props.category.children
    )
    if (!wasSelected) {
      this.props.onSelectCategories(idsArray, true)
    } else {
      this.props.onRemoveCategories(idsArray, true)
    }
  },

  handleExpandClick: function () {
    this.setState({ expanded: !this.state.expanded })
  },

  getCategory: function (id) {
    return findWhere(this.props.categoriesDictionary, { id: id })
  },

  isCategorySelected: function (id) {
    return (
      findIndex(this.props.selectedCategories, function (el) {
        return el === id
      }) !== -1
    )
  },

  renderSubCategory: function (category, index) {
    if (category) {
      return (
        <SearchFilterSubCategoryCheckbox
          key={index}
          category={category}
          parentCategory={this.props.category}
          selected={this.isCategorySelected(category.id)}
          handleClick={this.handleSubCategoryClick}
        />
      )
    }
  },

  render: function () {
    const isSelected = this.isCategorySelected(this.props.category.id)
    const expandControlCx = classNames('expand-sub-categories', {
      hidden: true
    })
    const subNodes = sortBy(
      this.props.category.children.map(this.getCategory),
      'name'
    ).map(this.renderSubCategory)
    const subCategoriesCx = classNames('sub-categories-container', {
      hidden: !this.state.expanded
    })
    const expandButtonLabel = this.state.expanded ? '-' : '+'
    const fieldName = 'categories_' + this.props.category.id
    const tempName = this.props.category.name

    return (
      <div className="input-group search-categories-list">
        <div className="parent-category">
          <div className="parent-category-filter search-checkbox">
            <input
              id={fieldName}
              type="checkbox"
              checked={isSelected}
              onChange={this.handleParentCategoryClick}
            />
            <label htmlFor={fieldName}>{tempName}</label>
            <span onClick={this.handleExpandClick} className={expandControlCx}>
              {expandButtonLabel}
            </span>
          </div>
        </div>
        <div className={subCategoriesCx}>{subNodes}</div>
      </div>
    )
  }
})
export default SearchFilterCategoryCheckbox
