import React from 'react'
import imageUrl from 'images/search/promotional/canyoning.jpeg'
import { isMobile } from 'react-device-detect'

const CanyoningNode = () => (
  <a
    className="link-unstyled"
    href="/activites/5927-bababox-canyoning"
    target={isMobile ? '_self' : '_blank'}
    rel={isMobile ? '' : 'noreferrer'}>
    <div className="hit-card hit-card-promo">
      <div className="img-container">
        <div className="promo-banner">Promotion</div>
        <img src={imageUrl} alt="Canyoning" />
      </div>
      <div className="content">
        <div className="promo-title">Offrez une Bababox : Canyoning</div>

        <div className="hit-features">
          <div>
            Offrez une expérience aquatique sensationnel riche en émotion.
          </div>
          <div>
            Une expérience à vivre dans des sites d’exception en France.
          </div>
          <div>Valide 1 an, renouvelable 12 mois.</div>
        </div>
      </div>
      <div className="hit-card-bottom-container">
        <div className="pricing text-center">
          <span className="price-from">A partir de&nbsp;</span>
          <div className="price">54 €&nbsp;</div>
          <span className="price-per">par personne</span>
        </div>
      </div>
    </div>
  </a>
)

export default CanyoningNode
