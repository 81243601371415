import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isString, flatten } from 'lodash'
import TimeMask from './TimeMask'
import Timeframes from './Timeframes'
import PriceVariants from './PriceVariants'
import TimeMaskModel from './store/models/time_mask'

class PricePolicyForm extends Component {
  constructor(props) {
    super(props)

    this.store = this.props.store

    var availableMasks = []
    var newMask = null

    // The price policy is a new one, created with an empty time mask
    if (isString(props.pricePolicy.time_mask_id)) {
      newMask = props.pricePolicy.time_mask
    } else {
      newMask = new TimeMaskModel({ season_id: this.store.season.id })
    }

    newMask._isNew = true

    availableMasks.push(newMask)
    availableMasks.push(
      this.store.activity.timeMasksForSeason(this.store.season.id)
    )
    availableMasks = flatten(availableMasks)

    this.state = {
      pricePolicy: props.pricePolicy,
      availableMasks: availableMasks
    }
  }

  updatePricePolicy(pricePolicy, availableMasks) {
    this.setState({ pricePolicy, availableMasks })
  }

  render() {
    const { pricePolicy, availableMasks } = this.state
    const timeMask = pricePolicy.time_mask

    return (
      <div>
        <div className="time-mask">
          <TimeMask
            store={this.store}
            timeMask={timeMask}
            pricePolicy={pricePolicy}
            pricePolicyForm={this}
            availableMasks={availableMasks}
            season={this.props.season}
            key={`timemask-container-${timeMask.id}`}
          />

          <div className="timeframes">
            <h4>Selectionner les horaires où les prix s'appliquent :</h4>
            <Timeframes
              store={this.store}
              timeframes={timeMask.timeframes}
              pricePolicy={pricePolicy}
              key={`timeframes-container-${timeMask.id}`}
            />
          </div>
        </div>
        <div className="price-variants-container">
          <h4>Choisir une ou plusieurs variantes :</h4>
          <PriceVariants store={this.store} pricePolicy={pricePolicy} />
        </div>
      </div>
    )
  }
}

PricePolicyForm.propTypes = {
  pricePolicy: PropTypes.object,
  store: PropTypes.object.isRequired,
  season: PropTypes.object.isRequired
}

PricePolicyForm.defaultProps = {}

export default PricePolicyForm
