import React from 'react'
import createReactClass from 'create-react-class'
import { findIndex } from 'lodash'
import StarRating from 'components/shared/StarRating'
import Pagination from 'components/shared/Pagination'
import * as Sentry from '@sentry/react'

var RatingsAll = createReactClass({
  getInitialState: function () {
    return {
      activities: this.props.activities,
      current_page: this.props.current_page,
      total_pages: this.props.total_pages,
      total_count: this.props.total_count
    }
  },

  componentWillUnmount: function () {
    clearInterval(this.timer)
  },

  filterActivities: function (event) {
    const filter_token = event.target.value
    clearInterval(this.timer)
    this.timer = setInterval(this.triggerSearch.bind(this, filter_token), 300)
  },

  triggerSearch: function (value) {
    clearInterval(this.timer)
    this.setState({ current_page: null, filter_token: value }, function () {
      this.reloadActivities()
    })
  },

  handleChangedRating: function (id, note) {
    this.submitRating(id, note, '')
  },

  handlePageChange: function (pageIndex) {
    $(this.refs.scrollContainer).scrollTop(0, 0)
    window.scrollTo(0, 0)
    this.setState({ current_page: pageIndex }, function () {
      this.reloadActivities()
    })
  },

  reloadActivities: function () {
    var actualFilterToken =
      this.state.filter_token === '' ? null : this.state.filter_token
    $.ajax({
      url: this.props.reload_activities_url,
      data: { page: this.state.current_page, filter_token: actualFilterToken },
      method: 'GET',
      dataType: 'json',
      cache: false,
      success: function (data) {
        this.setState({
          activities: data.activities,
          current_page: data.current_page,
          total_pages: data.total_pages,
          total_count: data.total_count
        })
      }.bind(this)
    })
  },

  submitRating: function (activityId, note, comment) {
    var req = {
      rating: {
        activity_id: activityId,
        note: note,
        comment: comment
      }
    }
    $.ajax({
      url: this.props.create_rating_url,
      data: req,
      method: 'POST',
      dataType: 'json',
      cache: false,
      success: function (data) {
        const index = findIndex(this.state.activities, function (el) {
          return el.id === data.activity.id
        })
        if (index !== -1) {
          const newActivities = this.state.activities
          newActivities[index] = data.activity
          this.setState({ activities: newActivities })
        }
      }.bind(this)
    })
  },

  renderActivityLine: function (data) {
    var displayName = data.highlighted_match || data.name
    return (
      <tr key={data.id}>
        <td>{data.id}</td>
        <td>
          <div dangerouslySetInnerHTML={{ __html: displayName }} />
        </td>
        <td>{data.center}</td>
        <td>
          <StarRating
            note={data.note}
            editable={true}
            handleChangedRating={this.handleChangedRating.bind(this, data.id)}
          />
        </td>
        <td>{data.note}</td>
        <td>{data.ratings_count}</td>
        <td>
          <a href={data.activity_url}>Voir</a>
        </td>
      </tr>
    )
  },

  render: function () {
    var activitiesNodes = this.state.activities.map(this.renderActivityLine)
    return (
      <Sentry.ErrorBoundary fallback="An error has occured">
        <div>
          <Pagination
            windowSize={2}
            currentPage={this.state.current_page}
            totalPages={this.state.total_pages}
            totalCount={this.state.total_count}
            handlePageChange={this.handlePageChange}
          />
          Rechercher (nom d activité) :{' '}
          <input type="text" onChange={this.filterActivities} />
          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Activité</th>
                <th>Centre</th>
                <th>Chaque click envoie une note</th>
                <th>Note Moyenne</th>
                <th>Nb de ratings</th>
                <th>Voir activité</th>
              </tr>
            </thead>
            <tbody>{activitiesNodes}</tbody>
          </table>
          <Pagination
            windowSize={2}
            currentPage={this.state.current_page}
            totalPages={this.state.total_pages}
            totalCount={this.state.total_count}
            handlePageChange={this.handlePageChange}
          />
        </div>
      </Sentry.ErrorBoundary>
    )
  }
})
export default RatingsAll
