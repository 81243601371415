import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'
import BabaRadio from './BabaRadio'

var BabaRadios = createReactClass({
  displayName: 'BabaRadios',

  getDefaultProps: function () {
    return {
      hidden: false
    }
  },

  render: function () {
    var objectName = this.props.objectName
    var fieldName = this.props.fieldName
    var actualHidden = this.props.hidden
    var defaultCheckedValue = this.props.defaultCheckedValue
    var containerClasses = classNames({
      'form-group': !actualHidden,
      hidden: actualHidden
    })
    var radioNodes = this.props.values.map(function (option, index) {
      return (
        <div key={index} className="col-sm-3">
          <BabaRadio
            key={option.id}
            objectName={objectName}
            fieldName={fieldName}
            value={option.id}
            label={option.name}
            hidden={actualHidden}
            defaultCheckedValue={defaultCheckedValue}
          />
        </div>
      )
    })
    return (
      <div className={containerClasses}>
        <div className="row">
          <div className="col-sm-9 col-sm-offset-3">
            <div className="row">{radioNodes}</div>
          </div>
        </div>
      </div>
    )
  }
})
export default BabaRadios
