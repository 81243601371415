class GeocodingSource {
  constructor(didSelectResult) {
    this.didSelectResult = didSelectResult
    this.region = 'FR'
    this.country = 'France'
  }

  source = (request, response) => {
    const provider = new google.maps.Geocoder()
    const searchTerm = request.term
    provider.geocode(
      {
        address: searchTerm,
        region: 'fr',
        language: 'fr'
      },
      (results, status) => {
        if (status === 'OK') {
          response(results.map(this.convertResult))
        }
      }
    )
  }

  convertResult = (googleResult) => {
    const addressComponents = googleResult.address_components
    const components = {}
    addressComponents.forEach((value) => {
      value.types.forEach((valueType) => {
        components[valueType] = value.long_name
        components[valueType + '_short'] = value.short_name
      })
    })
    return {
      label: googleResult.formatted_address,
      value: googleResult.formatted_address,
      resolvedAddress: googleResult.formatted_address,
      houseNumber: components.street_number,
      route: components.route,
      city: components.locality,
      postalCode: components.postal_code,
      country: components.country,
      countryCode: components.country_short,
      state: components.administrative_area_level_1,
      county: components.administrative_area_level_2,
      lat: googleResult.geometry.location.lat(),
      lng: googleResult.geometry.location.lng(),
      administrative_area_level_1: components.administrative_area_level_1,
      administrative_area_level_2: components.administrative_area_level_2,
      administrative_area_level_3: components.administrative_area_level_3
    }
  }

  select = (event, ui) => {
    event.preventDefault()
    const result = ui.item

    this.didSelectResult(result)
  }
}

export default GeocodingSource
