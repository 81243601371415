import React from 'react'
import PropTypes from 'prop-types'

const KezakoBox = ({ title, body }) => (
  <div className="kezako">
    <h2 className="kezako-title">
      FAQ - VOS QUESTIONS, NOS RÉPONSES - {title}
    </h2>
    <div dangerouslySetInnerHTML={{ __html: body }} />
  </div>
)

KezakoBox.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
}

export default KezakoBox
