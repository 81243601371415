import { map, find } from 'lodash'

const AlgoliaHelper = {
  mapFromOriginalRefinementList(collection, selected = []) {
    if (!collection) return null

    return Object.keys(collection).map((label) => {
      return {
        label,
        value: label,
        count: collection[label],
        isRefined: selected.indexOf(label) > -1
      }
    })
  },

  mapFromAllCategories(collection, items, currentRefinement) {
    if (!collection) return []

    return map(collection, (category) => {
      const item = find(items, (c) => {
        return c.label === category.name
      })

      return {
        id: category.id,
        count: (item && item.count) || 0,
        label: category.name,
        value: category.name,
        isRefined: (item && item.isRefined) || false,
        children: map(category.children, (subcategory) => {
          const subItem = find(items, (d) => {
            return d.label === subcategory.name
          })

          return {
            id: subcategory.id,
            count: (subItem && subItem.count) || 0,
            label: subcategory.name,
            value: subcategory.name,
            isRefined: (subItem && subItem.isRefined) || false
          }
        })
      }
    })
  },

  getIndexName(name) {
    return `${name}_${process.env.ALGOLIA_INDICE_SUFFIX}`
  }
}

export default AlgoliaHelper
