import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'

var BabaLabel = createReactClass({
  displayName: 'BabaLabel',

  getDefaultProps: function () {
    return {
      required: false
    }
  },

  render: function () {
    var styles = classNames('control-label', this.props.classNames)
    var label = this.props.label
    if (this.props.required && label) {
      label = label + ' *'
    }
    return (
      <label className={styles} htmlFor={this.props.htmlFor}>
        {label}
      </label>
    )
  }
})

export default BabaLabel
