import React, { useState, useEffect } from 'react'
import { map, values, mean, ceil, includes, compact, merge } from 'lodash'
import moment from 'moment'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { median } from 'lib/Math'
import display from 'lib/Display'

const DatePicker = ({
  giftMode,
  stepIndex,
  nextStep,
  goToStep,
  isCurrent,
  offer,
  chosenDate,
  setChosenDate,
  chosenTime,
  setChosenTime,
  availabilities,
  setAvailabilities,
  hourlyPrices,
  setHourlyPrices,
  priceSummary,
  setPriceSummary,
  variantQuantities,
  setVariantQuantities,
  setError,
  setMaxStepReachedIndex,
  fetchedMonths,
  setFetchedMonths
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isCurrent) {
      const tomorrow = moment().add(1, 'days')
      onNavigation({
        activeStartDate: tomorrow.toDate()
      })
    }
  }, [isCurrent])

  const onChange = (value) => {
    setChosenDate(value)
    setChosenTime(null)
    setHourlyPrices({})
    setPriceSummary(null)
    setMaxStepReachedIndex(stepIndex)
  }

  useEffect(() => {
    if (isCurrent) {
      nextStep()
    }
  }, [chosenDate])

  const onNavigation = (options) => {
    const date = moment(options.activeStartDate).format('YYYY-MM-DD')
    const month = moment(options.activeStartDate).format('YYYY-MM')
    const variants = map(
      variantQuantities,
      (variant) => `variants[${variant.id}]=${variant.quantity}`
    ).join('&')

    // If we haven't fetched the month already
    if (!includes(fetchedMonths, month)) {
      setLoading(true)

      fetch(
        `/activities/${offer.activityId}/offers/${offer.id}/availabilities?start_date=${date}&${variants}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            setLoading(false)
          }
        })
        .then((body) => {
          setAvailabilities(merge(availabilities, body.availabilities))
          fetchedMonths.push(month)
          setLoading(false)
          setFetchedMonths(fetchedMonths)
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)
        })
    }
  }

  const isLowPriceForAvailabilities = (price) => {
    const prices = compact(
      map(values(availabilities), (availability) => availability.price)
    )
    // If the price is equal to the median, we use the average instead to ensure a correct display
    let comparator = median(prices) === price ? mean(prices) : median(prices)
    comparator = ceil(comparator, 2)

    return price <= comparator
  }

  const getContentForDate = (options) => {
    const date = moment(options.date).startOf('day')
    const tomorrow = moment().add(1, 'days').startOf('day')
    const formattedDate = date.format('YYYY-MM-DD')
    const availability = availabilities[formattedDate]

    if (
      availability &&
      availability.price &&
      availability.slots.length > 0 &&
      date >= tomorrow
    ) {
      const isLowest = isLowPriceForAvailabilities(availability.price)

      return (
        <span className={`tile-price ${isLowest ? 'lowest' : 'highest'}`}>
          {display.currency(availability.price)}
        </span>
      )
    } else {
      return <span className="tile-price no-price" />
    }
  }

  const isDisabledForDate = (options) => {
    const date = moment(options.date).startOf('day')
    const tomorrow = moment().add(1, 'days').startOf('day')
    const formattedDate = date.format('YYYY-MM-DD')
    const availability = availabilities[formattedDate]

    if (availability && availability.price && availability.slots.length > 0) {
      return date < tomorrow
    } else {
      return true
    }
  }

  if (isCurrent) {
    return (
      <div className="step">
        {loading && (
          <div className="loading-blur">
            <div className="loading-content">
              <span className="loading-message">Chargement</span>
            </div>
          </div>
        )}
        <h3>Sélectionnez une date :</h3>
        <div className="calendar-container">
          <Calendar
            locale="FR-fr"
            minDetail="month"
            formatShortWeekday={(locale, date) =>
              date.toLocaleString(locale, { weekday: 'short' })[0].toUpperCase()
            }
            showNeighboringMonth={false}
            tileContent={getContentForDate}
            tileDisabled={isDisabledForDate}
            onChange={onChange}
            value={chosenDate}
            onActiveStartDateChange={onNavigation}
            prev2Label={null}
            next2Label={null}
          />
        </div>
      </div>
    )
  } else {
    return null
  }
}

export function DatePickerValidator(variantQuantities, chosenDate, chosenTime) {
  if (chosenDate) {
    return {
      valid: true,
      message: null
    }
  } else {
    return {
      valid: false,
      message: 'Merci de choisir une date'
    }
  }
}

export default DatePicker
