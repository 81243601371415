import React, { useRef } from 'react'
import { useInView } from 'framer-motion/dist/framer-motion'
import display from 'lib/Display'
import BabaForm from 'components/forms/Index'
import classNames from 'classnames'

const NavigationButtons = ({
  previousStepURL,
  priceAfter,
  goPrevious,
  submitLabel,
  handleValidate,
  disableSubmitButton = false
}) => {
  const ref = useRef(null)
  const isInView = useInView(ref)
  const previousStepProp = previousStepURL
    ? { href: previousStepURL }
    : { onClick: goPrevious }

  return (
    <>
      <div
        className={classNames('checkout-cta-container', {
          invisible: !isInView
        })}>
        <div className="row">
          <div className="col-md-6 hidden-xs hidden-sm">
            <a className="link-unstyled previous-step" {...previousStepProp}>
              <span className="glyphicon glyphicon-arrow-left mr-2"></span>
              Précédent
            </a>
          </div>
          <div className="col-md-6">
            <div className="row">
              {priceAfter ? (
                <div className="col-xs-6 total">
                  <strong>Total : {display.currency(priceAfter)}</strong>
                </div>
              ) : (
                <div className="col-xs-6 total">
                  <a
                    className="link-unstyled previous-step visible-xs visible-sm"
                    {...previousStepProp}>
                    <span className="glyphicon glyphicon-arrow-left mr-2"></span>
                    Précédent
                  </a>
                </div>
              )}
              <div className="col-xs-6">
                {handleValidate ? (
                  <button
                    className="btn btn-primary btn-cta btn-lg pull-right"
                    onClick={handleValidate}
                    disabled={disableSubmitButton}>
                    {submitLabel}
                  </button>
                ) : (
                  <BabaForm.BabaSubmit
                    label={submitLabel}
                    buttonClasses="btn btn-primary btn-cta btn-lg pull-right"
                    disabled={disableSubmitButton}
                  />
                )}
              </div>
            </div>
            {priceAfter && (
              <div className="row visible-xs visible-sm">
                <a
                  className="link-unstyled previous-step"
                  {...previousStepProp}>
                  <span className="glyphicon glyphicon-arrow-left mr-2"></span>
                  Précédent
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames('checkout-cta-container fixed', {
          invisible: isInView
        })}>
        <div className="row">
          <div className="col-md-6 hidden-xs hidden-sm">
            <a className="link-unstyled previous-step" {...previousStepProp}>
              <span className="glyphicon glyphicon-arrow-left mr-2"></span>
              Précédent
            </a>
          </div>
          <div className="col-md-6">
            <div className="row">
              {priceAfter ? (
                <div className="col-xs-6 total">
                  <strong>Total : {display.currency(priceAfter)}</strong>
                </div>
              ) : (
                <div className="col-xs-6 total">
                  <a
                    className="link-unstyled previous-step visible-xs visible-sm"
                    {...previousStepProp}>
                    <span className="glyphicon glyphicon-arrow-left mr-2"></span>
                    Précédent
                  </a>
                </div>
              )}
              <div className="col-xs-6">
                {handleValidate ? (
                  <button
                    className="btn btn-primary btn-cta btn-lg pull-right"
                    onClick={handleValidate}
                    disabled={disableSubmitButton}>
                    {submitLabel}
                  </button>
                ) : (
                  <BabaForm.BabaSubmit
                    label={submitLabel}
                    buttonClasses="btn btn-primary btn-cta btn-lg pull-right"
                    disabled={disableSubmitButton}
                  />
                )}
              </div>
            </div>
            {priceAfter && (
              <div className="row visible-xs visible-sm">
                <a
                  className="link-unstyled previous-step"
                  {...previousStepProp}>
                  <span className="glyphicon glyphicon-arrow-left mr-2"></span>
                  Précédent
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div ref={ref} id="is-in-view-probe"></div>
    </>
  )
}

NavigationButtons.displayName = 'NavigationButtons'
export default NavigationButtons
