import React, { Component } from 'react'
import PropTypes from 'prop-types'
import display from 'lib/Display'

class OrderCheckoutValidationCancellationPolicyForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: '',
      showMore: false
    }

    this.handleCancellationPolicyChange =
      this.handleCancellationPolicyChange.bind(this)
    this.updateSuccess = this.updateSuccess.bind(this)
    this.updateError = this.updateError.bind(this)
    this.handleShowMoreClick = this.handleShowMoreClick.bind(this)
  }

  handleShowMoreClick() {
    this.setState({ showMore: !this.state.showMore })
  }

  handleCancellationPolicyChange(event) {
    $.ajax({
      url: this.props.updateCancellationPolicyUrl,
      method: 'POST',
      cache: false,
      data: {
        cancellation_policy: {
          code: this.props.cancellationPolicy.code,
          checked: event.target.checked
        }
      },
      dataType: 'json',
      success: this.updateSuccess,
      error: this.updateError
    })
  }

  updateSuccess(data) {
    this.setState({
      errorMessage: ''
    })
    this.props.onOrderModification(data)
  }

  updateError() {
    this.setState({
      errorMessage: 'Un problème est survenu, veuillez réessayer.'
    })
  }

  render() {
    const showMoreArrow = this.state.showMore ? (
      <span className="glyphicon glyphicon-menu-up" />
    ) : (
      <span className="glyphicon glyphicon-menu-down" />
    )

    return (
      <div className="panel panel-no-border">
        <div className="panel-body">
          <div className="mb-2">
            <div className="mb-2">{this.props.cancellationPolicy.title}</div>
            <div className="row cancellation-policy-row mb-2">
              <div className="col-xs-10">
                <div className="radio-inline">
                  <label
                    htmlFor={`cancellation_policy_${this.props.cancellationPolicy.code}`}>
                    <input
                      type="checkbox"
                      className="mr-2"
                      name={this.props.cancellationPolicy.code}
                      id={`cancellation_policy_${this.props.cancellationPolicy.code}`}
                      onChange={this.handleCancellationPolicyChange}
                      defaultChecked={this.props.cancellationPolicy.selected}
                    />
                    {this.props.cancellationPolicy.label}
                  </label>
                </div>
              </div>
              <div className="col-xs-2 text-right">
                <strong>
                  {display.currency(this.props.cancellationPolicy.price)}
                </strong>
              </div>
            </div>
            <div
              style={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{
                __html: this.props.cancellationPolicy.description
              }}
            />
            <div className="clickable" onClick={this.handleShowMoreClick}>
              <p>
                <u>En savoir plus</u>
                {showMoreArrow}
              </p>
            </div>
            <div
              className={this.state.showMore ? '' : 'hidden'}
              dangerouslySetInnerHTML={{
                __html: this.props.cancellationPolicy.showMore
              }}
            />
            <div className="row">
              {this.state.errorMessage && (
                <div className="col-xs-12 text-danger">
                  {this.state.errorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OrderCheckoutValidationCancellationPolicyForm.propTypes = {
  cancellationPolicy: PropTypes.object.isRequired,
  onOrderModification: PropTypes.func.isRequired,
  updateCancellationPolicyUrl: PropTypes.string.isRequired
}

OrderCheckoutValidationCancellationPolicyForm.defaultProps = {}

export default OrderCheckoutValidationCancellationPolicyForm
