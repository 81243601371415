import React from 'react'
import createReactClass from 'create-react-class'
import classNames from 'classnames'
import BabaLabel from './BabaLabel'

var BabaInput = createReactClass({
  displayName: 'BabaInput',

  getInitialState: function () {
    return {
      showPassword: false,
      showValidationStatus: false,
      valid: false,
      validationMessage: 'OK'
    }
  },

  getDefaultProps: function () {
    return {
      validate: true,
      validationFn: null,
      hideLabel: false
    }
  },

  showSuccess: function () {
    this.setState({ valid: true, showValidationStatus: true })
  },
  showError: function (message) {
    this.setState({
      valid: false,
      showValidationStatus: true,
      validationMessage: message
    })
  },
  removeValidation: function () {
    this.setState({
      valid: true,
      showValidationStatus: false,
      validationMessage: null
    })
  },
  validate: function (target) {
    if (this.props.validate) {
      if (typeof target.validity !== 'undefined') {
        if (target.validity.valid) {
          if (this.props.validationFn) {
            this.props.validationFn(
              target.value,
              this.showSuccess,
              this.removeValidation,
              this.showError,
              this.props.validationContext
            )
          } else {
            this.showSuccess()
          }
        } else {
          this.showError("Ce champ n'est pas valide")
        }
      } else {
        this.showSuccess()
      }
    }
  },
  handleChange: function (event) {
    var target = event.target
    if (this.props.onChange) {
      this.props.onChange(
        this.props.objectName,
        this.props.fieldName,
        target.value
      )
    }
  },

  handleBlur: function (event) {
    var target = event.target
    this.validate(target)
  },

  handleFocus: function () {
    this.removeValidation()
  },

  handleRightGlyphClicked: function () {
    if (this.isPasswordField) {
      this.setState({ showPassword: !this.state.showPassword })
    }
  },

  handleKeyPress: function (event) {
    if (event.key === 'Enter') {
      this.props.onEnterPress()
    }
  },

  UNSAFE_componentWillMount: function () {
    this.inputIdentifier = this.props.objectName + '_' + this.props.fieldName
    this.inputName = this.props.objectName + '[' + this.props.fieldName + ']'
    if (this.props.controlled) {
      this.isControlled = true
    } else {
      this.isControlled = this.props.value != null
    }
  },

  componentDidMount: function () {
    if (this.props.paymentFormat) {
      $(this.refs.input).payment(this.props.paymentFormat)
    }
  },

  render: function () {
    const InputTag = this.props.type === 'textarea' ? 'textarea' : 'input'
    var maxLength = this.props.maxlength || null
    var hidden = this.props.type === 'hidden'
    var placeHolder = this.props.placeHolder || null
    var label = this.props.label || placeHolder
    var readOnly = this.props.readOnly === true
    var required = this.props.required === true
    const pattern = this.props.pattern || null
    const helpBlock = this.props.helpBlock || null
    const rows = this.props.rows || null
    const step = this.props.step || null
    var actualRequired = !hidden && required
    this.isPasswordField = this.props.type === 'password'
    var actualInputType
    if (this.isPasswordField && this.state.showPassword) {
      actualInputType = 'text'
    } else {
      actualInputType = this.props.type
    }
    var attachKeyPressHandler = !!this.props.onEnterPress
    var keyHandler = attachKeyPressHandler ? this.handleKeyPress : null
    if (placeHolder && required) {
      placeHolder = `${placeHolder}*`
    }

    var labelNode = !(label && !this.props.hideLabel && !hidden) ? null : (
      <BabaLabel
        htmlFor={this.inputIdentifier}
        classNames={this.props.labelClassNames}
        label={label}
        required={required}
      />
    )

    var valueProps = {}
    if (this.isControlled) {
      valueProps.value = this.props.value || ''
    } else {
      valueProps.defaultValue = this.props.defaultValue || ''
    }

    var autoCompleteProps = this.props.autoComplete
      ? { autoComplete: this.props.autoComplete }
      : {}
    var leftGlyphiconClasses = this.props.leftGlyphiconName
      ? classNames('glyphicon', this.props.leftGlyphiconName)
      : null
    var rightGlyphiconClasses = null
    if (this.isPasswordField) {
      rightGlyphiconClasses = classNames(
        'glyphicon clickable',
        this.state.viewPassword ? 'glyphicon-eye-close' : 'glyphicon-eye-open'
      )
    } else if (this.state.showValidationStatus) {
      rightGlyphiconClasses = classNames('glyphicon form-control-feedback', {
        'glyphicon-ok': this.state.valid,
        'glyphicon-remove': !this.state.valid
      })
    }

    var inputContainerClasses = classNames({
      'inner-addon': leftGlyphiconClasses || rightGlyphiconClasses,
      'left-right-addon': leftGlyphiconClasses && rightGlyphiconClasses,
      'left-addon': leftGlyphiconClasses && !rightGlyphiconClasses,
      'right-addon': !leftGlyphiconClasses && rightGlyphiconClasses
    })
    var leftGlyph = leftGlyphiconClasses ? (
      <i className={leftGlyphiconClasses} />
    ) : null
    var rightGlyph = rightGlyphiconClasses ? (
      <i
        className={rightGlyphiconClasses}
        onClick={this.handleRightGlyphClicked}></i>
    ) : null

    var containerClasses = classNames({
      'form-group': !hidden,
      'has-feedback': this.state.showValidationStatus,
      'has-success': this.state.showValidationStatus && this.state.valid,
      'has-error': this.state.showValidationStatus && !this.state.valid
    })
    var validationLabel =
      this.state.showValidationStatus && !this.state.valid ? (
        <label className="control-label validation-error-label">
          {this.state.validationMessage}
        </label>
      ) : null
    return (
      <div className={containerClasses}>
        {labelNode}
        <div className={inputContainerClasses}>
          {leftGlyph}
          <InputTag
            className="form-control"
            type={actualInputType}
            maxLength={maxLength}
            placeholder={placeHolder}
            required={actualRequired}
            name={this.inputName}
            id={this.inputIdentifier}
            ref="input"
            rows={rows}
            step={step}
            {...valueProps}
            {...autoCompleteProps}
            readOnly={readOnly}
            pattern={pattern}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onKeyPress={keyHandler}
          />
          {rightGlyph}
        </div>
        {validationLabel}
        {helpBlock && <p className="help-block">{helpBlock}</p>}
      </div>
    )
  }
})

export default BabaInput
