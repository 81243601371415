import React from 'react'
import imageUrl from 'images/search/promotional/bachelorette-party.jpg'
import { isMobile } from 'react-device-detect'

const BachelorettePartyNode = () => (
  <a
    className="link-unstyled"
    href="https://bit.ly/3KbnGMn"
    target={isMobile ? '_self' : '_blank'}
    rel={isMobile ? '' : 'noreferrer'}>
    <div className="hit-card hit-card-promo">
      <div className="img-container">
        <img src={imageUrl} alt="Enterrement de vie de jeune fille" />
      </div>
      <div className="content">
        <div className="promo-title">
          DEVIS EN 1 MIN - ENTERREMENT DE VIE DE JEUNE FILLE
        </div>

        <div className="hit-features">
          <div>En galère pour organiser l&apos;EVJF ou pas le time ?</div>
          <div>Demandez un devis en 5 clics</div>
          <div>Réponse sous 24h</div>
        </div>
      </div>
      <div className="hit-card-bottom-container"></div>
    </div>
  </a>
)

export default BachelorettePartyNode
