import React, { Component } from 'react'
import PropTypes from 'prop-types'
import uuid from 'lib/uuid'
import { find, findIndex, cloneDeep, map, remove, isNumber } from 'lodash'

class TimeMask extends Component {
  constructor(props) {
    super(props)

    this.store = this.props.store

    this.state = {
      pricePolicy: props.pricePolicy,
      newMask: find(props.availableMasks, (mask) => mask._isNew),
      clonedTimeMask: find(props.availableMasks, (mask) => mask._isCloned),
      availableMasks: props.availableMasks
    }
  }

  saveToStore() {
    var pricePolicyIndex = findIndex(
      this.store.offer.price_policies,
      (policy) => policy.id === this.state.pricePolicy.id
    )
    this.store.offer.price_policies[pricePolicyIndex] = this.state.pricePolicy
  }

  chooseTimeMask(e) {
    var { pricePolicy } = this.state
    const timeMaskId = e.target.value

    const timeMask = find(
      this.state.availableMasks,
      (timeMask) => timeMask.id === parseInt(timeMaskId)
    )

    pricePolicy.time_mask_id = timeMask.id
    pricePolicy.time_mask = timeMask

    this.setState({ pricePolicy })
    this.saveToStore()

    this.props.pricePolicyForm.updatePricePolicy(
      pricePolicy,
      this.state.availableMasks
    )
  }

  cloneExistingTimeMask() {
    var pricePolicy = this.state.pricePolicy
    const clonedTimeMask = cloneDeep(this.state.pricePolicy.time_mask)

    // Reset the ids of the time mask and the time frames
    // This will make it so they are considered as new when sent to the server
    clonedTimeMask.id = uuid()
    clonedTimeMask.timeframes.forEach((timeframe) => {
      timeframe.id = uuid()

      // Clean useless attributes
      delete timeframe.timeframeable_id
      delete timeframe.timeframeable_type
      delete timeframe.created_at
      delete timeframe.updated_at
    })

    clonedTimeMask._isCloned = true

    pricePolicy.time_mask_id = clonedTimeMask.id
    pricePolicy.time_mask = clonedTimeMask

    var availableMasks = this.state.availableMasks
    // Remove previously cloned time mask
    if (this.state.clonedTimeMask) {
      remove(availableMasks, (mask) => {
        return mask.id === this.state.clonedTimeMask.id
      })
    }

    availableMasks.push(clonedTimeMask)

    this.setState({
      pricePolicy,
      clonedTimeMask: clonedTimeMask,
      availableMasks: availableMasks
    })
    this.saveToStore()

    this.props.pricePolicyForm.updatePricePolicy(pricePolicy, availableMasks)
  }

  toggleDay(day_name) {
    var pricePolicy = this.state.pricePolicy
    pricePolicy.time_mask[`${day_name}_active`] =
      !pricePolicy.time_mask[`${day_name}_active`]
    this.setState({ pricePolicy })
  }

  toggleZone(zone) {
    var pricePolicy = this.state.pricePolicy
    pricePolicy.time_mask[`zone_${zone}_active`] =
      !pricePolicy.time_mask[`zone_${zone}_active`]
    this.setState({ pricePolicy })
  }

  selectAllForType(type) {
    var pricePolicy = this.state.pricePolicy
    pricePolicy.time_mask.setSelectionForType(type, true)
    this.setState({ pricePolicy })
  }

  selectNoneForType(type) {
    var pricePolicy = this.state.pricePolicy
    pricePolicy.time_mask.setSelectionForType(type, false)
    this.setState({ pricePolicy })
  }

  renderTimeMaskChoice() {
    const { season } = this.props
    const { activity } = this.store

    return (
      <div className="time-mask-choice">
        <h4>Choix de la programmation</h4>
        <select
          onChange={(e) => this.chooseTimeMask(e)}
          value={this.state.pricePolicy.time_mask.id}
          ref={(el) => (this.chosenTimeMaskOption = el)}>
          <option value={this.state.newMask.id}>Nouvelle programmation</option>
          {this.state.clonedTimeMask ? (
            <option value={this.state.clonedTimeMask.id}>
              Copie de {this.state.clonedTimeMask.formattedDays()} -{' '}
              {this.state.clonedTimeMask.formattedTimeslots()}
            </option>
          ) : null}

          {activity.timeMasksForSeason(season.id).map((timeMask) => (
            <option value={timeMask.id} key={`option-${timeMask.id}`}>
              {timeMask.formattedDays()} - {timeMask.formattedTimeslots()}
            </option>
          ))}
        </select>
        {isNumber(this.state.pricePolicy.time_mask.id) ? (
          <button
            className="btn btn-primary time-mask-copy"
            onClick={() => this.cloneExistingTimeMask()}>
            Copier cette programmation
          </button>
        ) : null}
      </div>
    )
  }

  renderDaysSelection(type = null) {
    const { pricePolicy } = this.state
    const timeMask = pricePolicy.time_mask

    if (type === 'closed') {
      var header = 'Jours fériés'
    } else if (type === 'holiday') {
      var header = 'Jours vacances'
    } else {
      var header = 'Jours semaine'
    }

    return (
      <div className={`time-mask-days ${type ? '' : `days-${type}`}`}>
        <h5>{header} :</h5>
        {map(
          timeMask.daysWithTranslations(),
          (translated_day_name, day_name) => {
            const dayNameWithType = type ? `${type}_${day_name}` : day_name
            return (
              <span key={dayNameWithType}>
                <input
                  onChange={(e) => this.toggleDay(dayNameWithType)}
                  type="checkbox"
                  checked={timeMask[`${dayNameWithType}_active`]}
                  id={`input-${dayNameWithType}`}
                />
                <label htmlFor={`input-${dayNameWithType}`}>
                  {translated_day_name}
                </label>
              </span>
            )
          }
        )}
        <span>
          <input
            onChange={() => this.selectAllForType(type)}
            type="checkbox"
            checked={timeMask.hasSelectedAllForType(type)}
            id={`batch-select-all${type ? `-${type}` : ''}`}
          />
          <label htmlFor={`batch-select-all${type ? `-${type}` : ''}`}>
            <strong>Tout</strong>
          </label>
        </span>
        <span>
          <input
            onChange={() => this.selectNoneForType(type)}
            type="checkbox"
            checked={timeMask.hasSelectedNoneForType(type)}
            id={`batch-select-none${type ? `-${type}` : ''}`}
          />
          <label htmlFor={`batch-select-none${type ? `-${type}` : ''}`}>
            <strong>Aucun</strong>
          </label>
        </span>
      </div>
    )
  }

  render() {
    const { pricePolicy } = this.state
    const timeMask = pricePolicy.time_mask

    return (
      <div>
        {this.renderTimeMaskChoice()}
        {this.renderDaysSelection()}
        {this.renderDaysSelection('closed')}
        {this.renderDaysSelection('holiday')}
        <div className="time-mask-days time-mask-zones">
          <h5>Quelle zones :</h5>
          {map(['a', 'b', 'c'], (zone) => {
            return (
              <span key={`zone-${zone}`}>
                <input
                  onChange={(e) => this.toggleZone(zone)}
                  type="checkbox"
                  checked={timeMask[`zone_${zone}_active`]}
                  id={`input-zone-${zone}`}
                />
                <label htmlFor={`input-zone-${zone}`}>
                  {zone.toUpperCase()}
                </label>
              </span>
            )
          })}
        </div>
      </div>
    )
  }
}

TimeMask.propTypes = {
  store: PropTypes.object.isRequired,
  season: PropTypes.object.isRequired,
  timeMask: PropTypes.object.isRequired,
  pricePolicy: PropTypes.object.isRequired,
  pricePolicyForm: PropTypes.object.isRequired
}

TimeMask.defaultProps = {}

export default TimeMask
