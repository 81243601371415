import React, { Component } from 'react'
import { find } from 'lodash'
import { connectSortBy } from 'react-instantsearch/connectors'
import Dropdown from './Dropdown'

class SortBy extends Component {
  handleClick(value) {
    this.props.refine(value)
  }

  get selectedValue() {
    const { label } = find(this.props.items, { isRefined: true })
    return label
  }

  render() {
    const { items } = this.props
    return (
      <div className="sort-by">
        <span className="sort-by-title">Trier par :</span>
        <Dropdown
          borderless
          title={this.selectedValue}
          refined
          id="sort-toggle">
          {items.map((item) => (
            <div key={item.value} className="feature-filter">
              <div
                className="feature-option"
                onClick={() => this.handleClick(item.value)}>
                {item.label}
              </div>
            </div>
          ))}
        </Dropdown>
      </div>
    )
  }
}

export default connectSortBy(SortBy)
