import { isNumber, clone } from 'lodash'

import Offer from './models/offer'
import Activity from './models/activity'
import Season from './models/season'

/* Mini store that is passed along the react tree
 * Every object passed to the store is converted into a model
 * granting it new helpers and methods
 */
class Store {
  constructor(props, form_authenticity_token) {
    this.season = new Season(props.season)
    this.activity = new Activity(props.activity)
    this.offer = new Offer(props.offer)

    this.form_authenticity_token = form_authenticity_token
  }

  savePricePolicy(pricePolicyId) {
    var activity = clone(this.activity)
    // For performance reasons. We don't need seasons.
    activity.seasons = []

    return fetch(
      `/admin/activities/${this.activity.id}/offers/${this.offer.id}/calendar/seasons/${this.season.id}/price_policy`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.form_authenticity_token
        },
        body: this.toJSON({
          activity: activity,
          price_policy: this.offer.findPricePolicy(pricePolicyId)
        })
      }
    ).then((response) => {
      if (response.ok) {
        window.location.reload() // a bit dirty, but that's just easier this way
      } else {
        return response.json()
      }
    })
  }

  destroyPricePolicy(pricePolicyId) {
    // If the id is a number it was persisted because
    // in react we generate string uids for the ID
    if (isNumber(pricePolicyId)) {
      return fetch(
        `/admin/activities/${this.activity.id}/offers/${this.offer.id}/calendar/seasons/${this.season.id}/price_policy/${pricePolicyId}`,
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': this.form_authenticity_token
          }
        }
      ).then((response) => {
        if (response.ok) {
          window.location.reload() // a bit dirty, but that's just easier this way
        } else {
          return response.json()
        }
      })
    }
  }

  saveSeason() {
    return fetch(
      `/admin/activities/${this.activity.id}/offers/${this.offer.id}/calendar/seasons/${this.season.id}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.form_authenticity_token
        },
        body: this.toJSON({ season: this.season })
      }
    ).then((response) => {
      if (response.ok) {
        window.location.reload() // a bit dirty, but that's just easier this way
      } else {
        return response.json()
      }
    })
  }

  destroySeason() {
    return fetch(
      `/admin/activities/${this.activity.id}/offers/${this.offer.id}/calendar/seasons/${this.season.id}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.form_authenticity_token
        }
      }
    ).then((response) => {
      if (response.ok) {
        window.location.reload() // a bit dirty, but that's just easier this way
      } else {
        return response.json()
      }
    })
  }

  toJSON(object) {
    return JSON.stringify(object)
  }
}

export default Store
