var ApiHelper = {
  parseMangopayError: function (customResponse) {
    let field, message

    switch (customResponse.ResultCode) {
      case '105101':
        field = 'Numéro de carte'
        message = 'Numéro de carte invalide'
        break
      case '105102':
        field = 'Nom'
        message = 'Le nom du propriétaire de la carte ne correspond pas'
        break
      case '105103':
        field = 'Code PIN'
        message = 'Code PIN invalide'
        break
      case '105104':
        field = 'Code PIN'
        message = 'Format du code PIN invalide'
        break
      case '105202':
        field = 'Numéro de carte'
        message = 'Format du numéro de carte invalide'
        break
      case '105203':
        field = "Date d'expiration"
        message =
          "La date d'expiration n'est pas dans le futur ou est mal formatée : MM/AA"
        break
      case '105204':
        field = 'Code CVV'
        message = "Le code CVV n'a pas le bon format"
        break
      case '001599':
      case '105299':
        field = 'Carte'
        message =
          "Problème d'enregistrement de la carte, avez-vous vérifié les informations ?"
        break
      default:
        field = '' + customResponse.ResultCode
        message = 'Erreur technique, pouvez-vous réessayer ?'
    }

    return { code: customResponse.resultCode, field: field, message: message }
  },

  parseErrorResponse: function (errorResponse) {
    if (errorResponse && errorResponse.responseJSON) {
      return this.parseXHRJSONError(errorResponse.responseJSON)
    } else {
      return [{ field: 'Erreur', message: 'Une erreur technique a eu lieu' }]
    }
  },

  parseXHRJSONError: function (jsonResponse) {
    var result = []
    var defaultFieldName = 'Erreur'
    if (jsonResponse) {
      if (jsonResponse.error) {
        result.push({ field: defaultFieldName, message: [jsonResponse.error] })
      }
      if (Array.isArray(jsonResponse.errors)) {
        for (let i = 0; i < jsonResponse.errors.length; i++) {
          var error = jsonResponse.errors[i]
          var fieldName = error.field || defaultFieldName
          result.push({ field: fieldName, message: error.message })
        }
      }
      if (
        typeof jsonResponse.errors === 'object' &&
        !Array.isArray(jsonResponse.errors)
      ) {
        for (let fieldName in jsonResponse.errors) {
          result.push({
            field: fieldName,
            message: jsonResponse.errors[fieldName]
          })
        }
      }
    }
    return result
  }
}

export default ApiHelper
