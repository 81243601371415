import React, { Fragment } from 'react'
import createReactClass from 'create-react-class'

var Pagination = createReactClass({
  pageClicked: function (event) {
    this.props.handlePageChange(parseInt($(event.target).attr('data-index')))
  },

  nextPage: function (event) {
    this.props.handlePageChange(this.props.currentPage + 1)
  },

  previousPage: function (event) {
    this.props.handlePageChange(this.props.currentPage - 1)
  },
  renderPageLink: function (index) {
    if (index < 0) {
      return (
        <span key={index} className="page-el gap hidden-xs">
          …
        </span>
      )
    } else if (index === this.props.currentPage) {
      if (index === 1) {
        return (
          <em key={index} className="hidden-xs page-el current">
            {index}
          </em>
        )
      } else {
        return (
          <em key={index} className="hidden-xs page-el current">
            {index}
          </em>
        )
      }
    } else {
      return (
        <span
          key={index}
          className="page-el clickable hidden-xs"
          data-index={index}
          onClick={this.pageClicked}>
          {index}
        </span>
      )
    }
  },

  renderPreviousLink: function () {
    var shortWording = '<'
    var mobileWording = '< Précédent'
    if (this.props.currentPage === 1) {
      return null
    } else {
      return (
        <span
          key="previous"
          className="page-el clickable previous_page"
          onClick={this.previousPage}
          rel="previous">
          <span className="hidden-xs">{shortWording}</span>
          <span className="visible-xs btn btn-info">{mobileWording}</span>
        </span>
      )
    }
  },

  renderNextLink: function () {
    var mobileWording =
      this.props.currentPage === 1 ? 'Page suivante >' : 'Suivant >'
    var shortWording = '>'
    if (this.props.currentPage === this.props.totalPages) {
      return null
    } else {
      return (
        <Fragment>
          <span
            key="next"
            className="page-el clickable next_page"
            onClick={this.nextPage}
            rel="next">
            <span className="hidden-xs">{shortWording}</span>
            <span className="visible-xs btn btn-info">{mobileWording}</span>
          </span>
          <div className="visible-xs clearfix"></div>
        </Fragment>
      )
    }
  },

  render: function () {
    var pageIndices = []
    var windowSize = Math.max(parseInt(this.props.windowSize), 0)
    var lastPage = parseInt(this.props.totalPages)
    var firstPage = 1
    var currentPage = parseInt(this.props.currentPage)
    var shouldShowPage = function (index) {
      if (index === currentPage) {
        return true
      } else if (index < firstPage || index > lastPage) {
        return false
      } else if (index === firstPage || index === lastPage) {
        return true
      } else if (
        index >= currentPage - windowSize &&
        index <= currentPage + windowSize
      ) {
        return true
      } else {
        return false
      }
    }
    var i = 1
    var j = -1
    var previousWasShown = true
    while (i <= this.props.totalPages) {
      if (shouldShowPage(i)) {
        pageIndices.push(i)
        previousWasShown = true
      } else {
        if (previousWasShown) {
          pageIndices.push(j)
          previousWasShown = false
          j--
        }
      }
      i++
    }
    var previousNode = this.renderPreviousLink()
    var nextNode = this.renderNextLink()
    var nodes = pageIndices.map(this.renderPageLink)

    return this.props.totalCount === 0 ? null : (
      <div className="search-pagination">
        {previousNode}
        {nodes}
        {nextNode}
      </div>
    )
  }
})

export default Pagination
