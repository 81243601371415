const MangopayHelper = {
  getBrowserInfo: (navigator, screen) => {
    return {
      javaEnabled: navigator.javaEnabled(),
      language: navigator.language || navigator.userLanguage,
      colorDepth: screen.colorDepth,
      screenHeight: screen.height,
      screenWidth: screen.width,
      timeZoneOffset: new Date().getTimezoneOffset().toString(),
      userAgent: navigator.userAgent,
      javascriptEnabled: true
    }
  }
}

export default MangopayHelper
