import { Component } from 'react'
import PropTypes from 'prop-types'
import GoogleSource from 'components/geo/google/GeocodingSource'

class LocationAutocomplete extends Component {
  didSelectResult = (result) => {
    $(this.props.searchInputSelector).val(result.resolvedAddress)
    $(this.props.selectors.houseNumber).val(result.houseNumber)
    $(this.props.selectors.route).val(result.route)
    $(this.props.selectors.city).val(result.city)
    $(this.props.selectors.postalCode).val(result.postalCode)
    $(this.props.selectors.country).val(result.country)
    $(this.props.selectors.state).val(result.state)
    $(this.props.selectors.county).val(result.county)
    $(this.props.selectors.lat).val(result.lat)
    $(this.props.selectors.lng).val(result.lng)
  }

  componentDidMount() {
    const source = new GoogleSource(this.didSelectResult)
    $(this.props.searchInputSelector).autocomplete(source)
  }

  render() {
    return null
  }
}

LocationAutocomplete.propTypes = {
  searchInputSelector: PropTypes.string.isRequired,
  selectors: PropTypes.shape({
    houseNumber: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    county: PropTypes.string.isRequired,
    lat: PropTypes.string.isRequired,
    lng: PropTypes.string.isRequired
  })
}

export default LocationAutocomplete
