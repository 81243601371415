import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const SchemaOrg = ({
  children,
  title,
  description,
  nbHits,
  priceInfo,
  ratingsAverage,
  ratingsCount
}) => (
  <div itemScope itemType="https://schema.org/Product">
    <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
      <meta itemProp="name" content="Babasport" />
    </div>
    <meta itemProp="name" content={title} />
    <meta itemProp="description" content={description} />
    {nbHits > 0 && (
      <div
        itemProp="offers"
        itemScope
        itemType="https://schema.org/AggregateOffer">
        {priceInfo && (
          <Fragment>
            <meta itemProp="lowPrice" content={priceInfo.min} />
            <meta itemProp="highPrice" content={priceInfo.max} />
          </Fragment>
        )}
        <meta itemProp="offerCount" content={nbHits} />
        <meta itemProp="priceCurrency" content="EUR" />
        <meta itemProp="availability" content="https://schema.org/InStock" />
      </div>
    )}
    {ratingsAverage && ratingsCount && (
      <div
        className="single-result-rating"
        itemProp="aggregateRating"
        itemScope
        itemType="https://schema.org/AggregateRating">
        <meta itemProp="ratingValue" content={ratingsAverage.avg} />
        <meta itemProp="ratingCount" content={ratingsCount.sum} />
        <meta itemProp="worstRating" content={ratingsAverage.min} />
        <meta itemProp="bestRating" content={ratingsAverage.max} />
      </div>
    )}
    {children}
  </div>
)

SchemaOrg.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  nbHits: PropTypes.number,
  priceInfo: PropTypes.object,
  ratingsAverage: PropTypes.object,
  ratingsCount: PropTypes.object
}

export default SchemaOrg
