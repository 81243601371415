import Base from './base'
import AvailableModels from './collection'
import moment from 'moment'
import { isEqual } from 'lodash'

const midnight = {
  hour: 0,
  minute: 0,
  second: 0,
  second_of_day: 0
}

const noon = {
  hour: 12,
  minute: 0,
  second: 0,
  second_of_day: 43200
}

const evening = {
  hour: 20,
  minute: 0,
  second: 0,
  second_of_day: 72000
}

export const halfOrFullDayOptions = {
  day: {
    name: 'Journée',
    begin_time: midnight,
    end_time: midnight
  },
  morning: {
    name: 'Matinée',
    begin_time: midnight,
    end_time: noon
  },
  afternoon: {
    name: 'Après-midi',
    begin_time: noon,
    end_time: evening
  },
  evening: {
    name: 'Soirée',
    begin_time: evening,
    end_time: midnight
  }
}

class Timeframe extends Base {
  defaultAttributes() {
    return {
      begin_time: midnight,
      end_time: null,
      interval: 0
    }
  }

  _parseTime(time) {
    const parsedTime = moment(`01/01/00 ${time.replace('h', ':')}`)

    var midnight = parsedTime.clone().startOf('day')

    // Difference in minutes
    var secondsSinceMidnight = parsedTime.diff(midnight, 'minutes')

    return {
      hour: parsedTime.hour(),
      minute: parsedTime.minute(),
      second: parsedTime.second(),
      second_of_day: secondsSinceMidnight
    }
  }

  setBeginTime(time) {
    return (this.begin_time = this._parseTime(time))
  }

  setEndTime(time) {
    return (this.end_time = this._parseTime(time))
  }

  formattedBeginTime() {
    return `${this.begin_time.hour
      .toString()
      .padStart(2, 0)}h${this.begin_time.minute.toString().padStart(2, 0)}`
  }

  formattedEndTime() {
    return `${this.end_time.hour
      .toString()
      .padStart(2, 0)}h${this.end_time.minute.toString().padStart(2, 0)}`
  }

  formattedSlots(code = false) {
    if (!this.end_time) {
      return this.formattedBeginTime()
    } else if (!this.isHalfOrFullDay()) {
      return `${this.formattedBeginTime()} - ${this.formattedEndTime()} / ${
        this.interval
      } min`
    } else {
      if (this.isFullDay()) {
        if (code) {
          return 'day'
        }
        return halfOrFullDayOptions.day.name
      } else if (this.isMorning()) {
        if (code) {
          return 'morning'
        }
        return halfOrFullDayOptions.morning.name
      } else if (this.isAfternoon()) {
        if (code) {
          return 'afternoon'
        }
        return halfOrFullDayOptions.afternoon.name
      } else if (this.isEvening()) {
        if (code) {
          return 'evening'
        }
        return halfOrFullDayOptions.evening.name
      }
    }
  }

  isHalfOrFullDay() {
    if (this.interval > 0) {
      return false
    }
    return this.isHalfDay() || this.isFullDay()
  }

  isHalfDay() {
    return this.isMorning() || this.isAfternoon() || this.isEvening()
  }

  isFullDay() {
    return (
      isEqual(this.begin_time, midnight) && isEqual(this.end_time, midnight)
    )
  }

  isMorning() {
    return isEqual(this.begin_time, midnight) && isEqual(this.end_time, noon)
  }

  isAfternoon() {
    return isEqual(this.begin_time, noon) && isEqual(this.end_time, evening)
  }

  isEvening() {
    return isEqual(this.begin_time, evening) && isEqual(this.end_time, midnight)
  }

  availableModels() {
    return AvailableModels()
  }
}

export default Timeframe
