import React from 'react'
import BabaForm from 'components/forms/Index'
import PropTypes from 'prop-types'
import NavigationButtons from 'components/bookings/NavigationButtons'

class MessageForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: this.props.personalizedMessage
    }
  }

  static contextTypes = {
    handleValidationSuccessful: PropTypes.func,
    goPrevious: PropTypes.func
  }

  handleChange = (object, field, message) => {
    this.setState({
      message: message
    })
  }

  handleSubmit = () => {
    this.props.didFinishEditing(this.state.message)
    if (this.context.handleValidationSuccessful) {
      this.context.handleValidationSuccessful()
    }
  }

  render() {
    return (
      <BabaForm.BabaForm onSubmit={this.handleSubmit}>
        <div className="step-title">Carte cadeau</div>
        <p>
          Dès que la commande sera finalisée, vous recevrez un e-mail de
          confirmation qui vous permettra de télécharger immédiatement la carte
          cadeau.
          <br />
          Vous pourrez alors imprimer la carte ou la transmettre par e-mail au
          bénéficiaire.
        </p>
        <BabaForm.BabaInput
          id="personalizedMessage"
          type="textarea"
          maxlength={170}
          objectName="order"
          fieldName="personalized_message"
          placeHolder="Message pour le destinataire"
          helpBlock="170 caractères max"
          rows={4}
          hideLabel
          defaultValue={this.state.message}
          onChange={this.handleChange}
        />
        <NavigationButtons
          previousStepURL={this.props.giftCardsURL}
          priceAfter={this.props.priceAfter}
          goPrevious={this.context.goPrevious}
          submitLabel="Suivant"
          handleValidate={null}
        />
      </BabaForm.BabaForm>
    )
  }
}

export default MessageForm
