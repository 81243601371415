import React from 'react'
import { isMobile } from 'react-device-detect'
import {
  BachelorettePartyNode,
  BachelorPartyNode,
  BungeeJumpingNode,
  CanyoningNode,
  GiftCardNode,
  HelicopterNode,
  HotAirBalloonNode,
  ParachuteNode,
  ParaglidingNode,
  SurvivalNode,
  TeamBuildingNode,
  ULMNode,
  RacingNode
} from 'components/search/promotional'
import HitCard from 'components/search/HitCard'

const ListFull = ({ hits }) =>
  hits.map((hit) => {
    if (hit.type === 'promo') {
      switch (hit.node) {
        case 'gift':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <GiftCardNode />
            </div>
          )
        case 'bachelorette':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <BachelorettePartyNode />
            </div>
          )
        case 'bachelor':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <BachelorPartyNode />
            </div>
          )
        case 'team':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <TeamBuildingNode />
            </div>
          )
        case 'hot-air-balloon':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <HotAirBalloonNode />
            </div>
          )
        case 'ulm':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <ULMNode />
            </div>
          )
        case 'helicopter':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <HelicopterNode />
            </div>
          )
        case 'paragliding':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <ParaglidingNode />
            </div>
          )
        case 'parachute':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <ParachuteNode />
            </div>
          )
        case 'bungee-jumping':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <BungeeJumpingNode />
            </div>
          )
        case 'canyoning':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <CanyoningNode />
            </div>
          )
        case 'survival':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <SurvivalNode />
            </div>
          )
        case 'racing':
          return (
            <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key="promo">
              <RacingNode />
            </div>
          )
      }
    }

    return (
      <div className="col-xs-6 col-sm-6 col-md-4 hit-container" key={hit.id}>
        <a
          className="link-unstyled"
          href={hit.aa_public_path}
          target={isMobile ? '_self' : '_blank'}
          rel={isMobile ? '' : 'noreferrer'}>
          <HitCard hit={hit} />
        </a>
      </div>
    )
  })

export default ListFull
