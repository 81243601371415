import React from 'react'
import PropTypes from 'prop-types'
import { connectCurrentRefinements } from 'react-instantsearch/connectors'
import { ClearRefinements } from 'react-instantsearch-dom'
import { uniq } from 'lodash'

const CurrentRefinements = ({ items, refine }) => {
  const arrayUniqueById = [
    ...new Map(items.map((item) => [item.id, item])).values()
  ]

  return (
    <ul className="list-inline refinement-list">
      {arrayUniqueById.map((item) => {
        if (item.id === 'query') {
          return (
            <li className="refinement-pill" key={item.id}>
              {item.currentRefinement}
              <button onClick={() => refine(item.value)}>X</button>
            </li>
          )
        } else if (item.id === 'boundingBox') {
          return (
            <li className="refinement-pill" key={item.id}>
              Recherche géographique
              <button onClick={() => refine(item.value)}>X</button>
            </li>
          )
        } else {
          return uniq(item.items, false, (el) => el.label).map((nest) => (
            <li className="refinement-pill" key={nest.label}>
              {nest.label}
              <button onClick={() => refine(nest.value)}>X</button>
            </li>
          ))
        }
      })}
      {arrayUniqueById.length > 0 && (
        <li className="hidden-xs">
          <ClearRefinements
            translations={{ reset: 'Réinitialiser les filtres' }}
            clearsQuery
          />
        </li>
      )}
    </ul>
  )
}

CurrentRefinements.propTypes = {
  items: PropTypes.array,
  refine: PropTypes.func
}

export default connectCurrentRefinements(CurrentRefinements)
