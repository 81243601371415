import React from 'react'
import createReactClass from 'create-react-class'
import apiHelper from 'lib/ApiHelper'

var BabaForm = createReactClass({
  getInitialState: function () {
    return {
      errors: [],
      authenticityToken: this.props.authenticity_token
    }
  },
  getDefaultProps: function () {
    return {
      remote: false,
      success: null,
      error: null,
      authenticity_token: ''
    }
  },

  UNSAFE_componentWillReceiveProps: function (nextProps) {
    if (nextProps.errors !== null && nextProps.errors !== undefined) {
      this.setState({ errors: nextProps.errors })
    }
  },

  componentDidMount: function () {
    var defaultToken = $('meta[name="csrf-token"]').attr('content')
    if (this.state.authenticityToken === '') {
      this.setState({ authenticityToken: defaultToken })
    }
  },

  submit: function (event) {
    if (this.props.remote) {
      event.preventDefault()
      var form = $(event.target)
      var data = $(this.refs.form).serialize()
      $.ajax({
        url: this.props.action,
        type: this.paramMethod,
        data: data,
        dataType: this.dataType,
        success: function (data, status, xhr) {
          form.trigger('ajax:success', [data, status, xhr])
          if (this.props.success) {
            this.props.success(data, status, xhr)
          }
        }.bind(this),
        complete: function (xhr, status) {
          form.trigger('ajax:complete', [xhr, status])
          if (this.props.complete) {
            this.props.complete(xhr, status)
          }
        }.bind(this),
        error: function (xhr, status, error) {
          form.trigger('ajax:error', [xhr, status, error])
          if (this.props.error) {
            this.props.error(xhr, status, error)
          }
          this.setState({
            errors: apiHelper.parseXHRJSONError(xhr.responseJSON)
          })
        }.bind(this)
      })
    } else {
      if (this.props.onSubmit) {
        event.preventDefault()
        this.props.onSubmit()
      } else {
        event.preventDefault()
        this.refs.form.submit()
      }
    }
    if (this.props.didSubmit) {
      this.props.didSubmit()
    }
  },

  render: function () {
    this.paramMethod = (this.props.method || 'post').toLowerCase()
    this.formMethod = this.paramMethod === 'get' ? 'get' : 'post'
    this.dataType = 'json'
    var methodHiddenInputNode =
      this.paramMethod !== 'get' && this.paramMethod !== 'post' ? (
        <input type="hidden" name="_method" value={this.paramMethod} />
      ) : null

    var errorNode =
      !this.state.errors || this.state.errors.length === 0 ? null : (
        <div className="alert alert-danger" role="alert">
          <span className="sr-only">Error:</span>
          <ul>
            {this.state.errors.map(function (el, i) {
              return <li key={i}>{el.message}</li>
            })}
          </ul>
        </div>
      )
    return (
      <form
        className="baba-form"
        name={this.props.name}
        id={this.props.id}
        ref="form"
        action={this.props.action}
        acceptCharset="UTF-8"
        method={this.formMethod}
        onSubmit={this.submit}
        data-rspec={this.props.id}>
        <input name="utf8" type="hidden" value="&#x2713;" />
        <input
          type="hidden"
          name="authenticity_token"
          value={this.state.authenticityToken}
        />
        {methodHiddenInputNode}
        {errorNode}
        {this.props.children}
      </form>
    )
  }
})

export default BabaForm
