import React from 'react'
import PropTypes from 'prop-types'
import BabaLabel from './BabaLabel'

class BabaSelect extends React.Component {
  state = {}

  handleChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value)
    }
  }

  render() {
    const inputName = `${this.props.objectName}[${this.props.fieldName}]`
    const actualDefaultValue = this.props.defaultValue || ''
    const shouldAddPrompt =
      this.props.prompt !== null && this.props.prompt !== undefined
    const actualPrompt = shouldAddPrompt ? this.props.prompt : null
    const promptOptionNode = shouldAddPrompt ? (
      <option key="-1" value="">
        {actualPrompt}
      </option>
    ) : null
    const optionsNodes = this.props.values.map((val) => {
      return (
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      )
    })

    var required = this.props.required === true
    var hidden = this.props.type === 'hidden'
    var label = this.props.label || this.props.placeHolder
    var labelNode = !(label && !this.props.hideLabel && !hidden) ? null : (
      <BabaLabel
        htmlFor={this.inputIdentifier}
        label={label}
        required={required}
      />
    )

    return (
      <div className={`${this.props.classProps} form-group`}>
        {labelNode}
        <select
          className="form-control"
          style={this.props.selectStyleProps}
          name={inputName}
          onChange={this.handleChange}
          defaultValue={actualDefaultValue}
          required={required}>
          {promptOptionNode}
          {optionsNodes}
        </select>
      </div>
    )
  }
}

BabaSelect.propTypes = {
  onChange: PropTypes.func,
  selectStyleProps: PropTypes.object,
  objectName: PropTypes.string,
  fieldName: PropTypes.string,
  classProps: PropTypes.string,
  values: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  prompt: PropTypes.string
}

BabaSelect.defaultProps = {
  onChange: function () {},
  objectName: '',
  fieldName: '',
  classProps: '',
  defaultValue: null,
  selectStyleProps: {}
}

export default BabaSelect
