import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { map, keys, groupBy, compact, values, mean } from 'lodash'
import moment from 'moment'
import {
  getSpecialPeriodName,
  fullDay,
  morning,
  afternoon,
  evening
} from 'lib/TimeHelper'
import { median } from 'lib/Math'
import display from 'lib/Display'

const TimePicker = ({
  giftMode,
  stepIndex,
  nextStep,
  goToStep,
  isCurrent,
  offer,
  chosenDate,
  setChosenDate,
  chosenTime,
  setChosenTime,
  availabilities,
  setAvailabilities,
  hourlyPrices,
  setHourlyPrices,
  priceSummary,
  setPriceSummary,
  variantQuantities,
  setVariantQuantities,
  setError,
  setMaxStepReachedIndex,
  fetchedMonths,
  setFetchedMonths
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isCurrent && chosenDate) {
      fetchPricesForVariants()
    }
  }, [isCurrent])

  const fetchPricesForVariants = () => {
    setLoading(true)
    const date = moment(chosenDate).format('YYYY-MM-DD')

    const variants = map(
      variantQuantities,
      (variant) => `variants[${variant.id}]=${variant.quantity}`
    ).join('&')

    fetch(
      `/activities/${offer.activityId}/offers/${offer.id}/prices?date=${date}&${variants}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          setLoading(false)
          setError('Une erreur est survenue. Veuillez réessayer.')
        }
      })
      .then((body) => {
        setLoading(false)
        setHourlyPrices(body.hourly_prices[date])
      })
      .catch((error) => {
        setLoading(false)
        setError(error.message)
      })
  }

  const groupedTimeSlots = useMemo(() => {
    const timeSlots =
      keys(hourlyPrices).sort(
        (a, b) =>
          moment(a, moment.HTML5_FMT.TIME_SECONDS) -
          moment(b, moment.HTML5_FMT.TIME_SECONDS)
      ) || []

    const groups = groupBy(timeSlots, (time) => {
      const hour = parseInt(time.slice(0, 2))
      if (isNaN(hour)) {
        return 'special'
      } else if (hour <= 13) {
        return 'morning'
      } else {
        return 'afternoon'
      }
    })

    groups.morning = groups.morning || []
    groups.afternoon = groups.afternoon || []
    groups.special = groups.special || []

    return groups
  }, [hourlyPrices])

  const isLowPriceForDay = (price) => {
    const prices = compact(map(values(hourlyPrices)))
    // If the price is equal to the median, we use the average instead to ensure a correct display
    const comparator = median(prices) === price ? mean(prices) : median(prices)

    return price <= comparator
  }

  const priceForSlot = (slot) => {
    const price = hourlyPrices[slot]

    if (price) {
      return (
        <span
          className={`tile-price ${
            isLowPriceForDay(price) ? 'lowest' : 'highest'
          }`}>
          {display.currency(price)}
        </span>
      )
    } else {
      return <span className="tile-price no-price" />
    }
  }

  const chooseSlot = (slot) => {
    setChosenTime(slot)
    setPriceSummary(null)
    setMaxStepReachedIndex(stepIndex)
  }

  useEffect(() => {
    if (isCurrent) {
      nextStep()
    }
  }, [chosenTime])

  const nameForSlot = (slot) => {
    const specialPeriodName = getSpecialPeriodName(slot)
    if (specialPeriodName) {
      return specialPeriodName
    } else {
      return slot.slice(0, 5)
    }
  }

  if (isCurrent) {
    const morningSlots = groupedTimeSlots.morning
    const afternoonSlots = groupedTimeSlots.afternoon
    const specialSlots = groupedTimeSlots.special
    const hasMorningSpecialSlot = specialSlots.includes(morning)
    const hasAfternoonSpecialSlot = specialSlots.includes(afternoon)
    const hasEveningSpecialSlot = specialSlots.includes(evening)
    const hasDaySpecialSlot = specialSlots.includes(fullDay)
    const showMorningSlots =
      !hasDaySpecialSlot && !hasMorningSpecialSlot && morningSlots.length > 0
    const showAfternoonSlots =
      !hasDaySpecialSlot &&
      !hasAfternoonSpecialSlot &&
      !hasEveningSpecialSlot &&
      afternoonSlots.length > 0
    const gridLength =
      showMorningSlots && showAfternoonSlots ? 'col-sm-6' : 'col-sm-12'

    return (
      <div className="step">
        {loading && (
          <div className="loading-blur">
            <div className="loading-content">
              <span className="loading-message">Chargement</span>
            </div>
          </div>
        )}
        <h3>Sélectionnez un horaire :</h3>
        <div className="time-picker">
          {hasDaySpecialSlot && hourlyPrices[fullDay] && (
            <Fragment>
              <div className="row timepicker-row">
                <div className="time-period col-xs-12 col-sm-12 w-100">
                  <span
                    onClick={() => chooseSlot(fullDay)}
                    className={`special-time-slot time-slot ${
                      chosenTime === fullDay ? 'active' : ''
                    }`}>
                    {nameForSlot(fullDay)}
                    {priceForSlot(fullDay)}
                  </span>
                </div>
              </div>
            </Fragment>
          )}
          <div className="row timepicker-row">
            <div className={`time-period morning col-xs-12 ${gridLength}`}>
              {hasMorningSpecialSlot && hourlyPrices[morning] && (
                <span
                  onClick={() => chooseSlot(morning)}
                  className={`special-time-slot time-slot ${
                    chosenTime === morning ? 'active' : ''
                  }`}>
                  {nameForSlot(morning)}
                  {priceForSlot(morning)}
                </span>
              )}
              {showMorningSlots && (
                <Fragment>
                  {map(morningSlots, (slot, index) => {
                    if (hourlyPrices[slot]) {
                      return (
                        <span
                          key={index}
                          onClick={() => chooseSlot(slot)}
                          className={`time-slot ${
                            chosenTime === slot ? 'active' : ''
                          }`}>
                          {nameForSlot(slot)}
                          {priceForSlot(slot)}
                        </span>
                      )
                    }
                  })}
                </Fragment>
              )}
            </div>
            <div className={`time-period afternoon col-xs-12 ${gridLength}`}>
              {hasAfternoonSpecialSlot && hourlyPrices[afternoon] && (
                <span
                  onClick={() => chooseSlot(afternoon)}
                  className={`special-time-slot time-slot ${
                    chosenTime === afternoon ? 'active' : ''
                  }`}>
                  {nameForSlot(afternoon)}
                  {priceForSlot(afternoon)}
                </span>
              )}
              {hasEveningSpecialSlot && hourlyPrices[evening] && (
                <span
                  onClick={() => chooseSlot(evening)}
                  className={`special-time-slot time-slot ${
                    chosenTime === evening ? 'active' : ''
                  }`}>
                  {nameForSlot(evening)}
                  {priceForSlot(evening)}
                </span>
              )}
              {showAfternoonSlots && (
                <Fragment>
                  {map(afternoonSlots, (slot, index) => {
                    if (hourlyPrices[slot]) {
                      return (
                        <span
                          key={index}
                          onClick={() => chooseSlot(slot)}
                          className={`time-slot ${
                            chosenTime === slot ? 'active' : ''
                          }`}>
                          {nameForSlot(slot)}
                          {priceForSlot(slot)}
                        </span>
                      )
                    }
                  })}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export function TimePickerValidator(variantQuantities, chosenDate, chosenTime) {
  if (chosenTime) {
    return {
      valid: true,
      message: null
    }
  } else {
    return {
      valid: false,
      message: 'Merci de choisir une heure'
    }
  }
}

export default TimePicker
