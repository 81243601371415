import React from 'react'
import createReactClass from 'create-react-class'
import BabaForm from 'components/forms/Index'

const OrderCheckoutValidationCouponForm = createReactClass({
  displayName: 'OrderCheckoutValidationCouponForm',

  getInitialState: function () {
    return {
      showCouponForm: false,
      couponName: this.props.order.couponName,
      errorMessage: this.props.order.couponErrorMessage
    }
  },

  handleCouponClick: function () {
    this.setState({ showCouponForm: !this.state.showCouponForm })
  },

  handleCouponChange: function (object, field, value) {
    this.setState({ couponName: value })
  },

  successCoupon: function (data) {
    this.props.onOrderModification(data)
    this.setState({ errorMessage: this.props.order.couponErrorMessage })
  },

  errorCoupon: function () {
    this.setState({
      errorMessage: 'Un problème est survenu, veuillez réessayer.'
    })
  },

  handleCouponValidate: function () {
    $.ajax({
      url: this.props.urls.couponUpdateURL,
      method: 'POST',
      cache: false,
      data: { coupon: { coupon_name: this.state.couponName } },
      dataType: 'json',
      success: this.successCoupon,
      error: this.errorCoupon
    })
  },

  render: function () {
    const showCouponArrow = this.state.showCouponForm ? (
      <span className="glyphicon glyphicon-menu-up" />
    ) : (
      <span className="glyphicon glyphicon-menu-down" />
    )

    let couponValidationMessage = null
    if (this.state.showCouponForm && this.props.order.couponId !== null) {
      couponValidationMessage = `Code promo appliqué : ${this.props.order.couponName}`
    }

    return (
      <div className="coupon-container mb-2">
        <div className="clickable mb-2" onClick={this.handleCouponClick}>
          Ajouter un code promo{showCouponArrow}
        </div>
        <div className={this.state.showCouponForm ? '' : 'hidden'}>
          <div className="row">
            <div className="col-xs-6">
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="text"
                  objectName="order"
                  fieldName="coupon"
                  validate={false}
                  defaultValue={this.props.order.couponName}
                  placeHolder=""
                  onChange={this.handleCouponChange}
                  required={false}
                  onEnterPress={this.handleCouponValidate}
                />
              </BabaForm.FormLine>
            </div>
            <div className="col-xs-6">
              <button
                onClick={this.handleCouponValidate}
                className="btn btn-primary inline-btn">
                OK
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <p>
                Si vous avez une carte cadeau, le code doit être utilisé au
                moment du Paiement sécurisé
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          {this.state.errorMessage && (
            <div className="col-xs-12 text-danger">
              {this.state.errorMessage}
            </div>
          )}
          {couponValidationMessage && (
            <div className="col-xs-12 text-success">
              {couponValidationMessage}
            </div>
          )}
        </div>
      </div>
    )
  }
})
export default OrderCheckoutValidationCouponForm
