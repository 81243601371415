import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import MultipleStepsForm from 'components/funnel/MultipleStepsForm'
import InformationForm from 'components/bookings/InformationForm'
import GiftDatePicker from './GiftDatePicker'
import GiftTimePicker from './GiftTimePicker'
import display from 'lib/Display'
import * as Sentry from '@sentry/react'

class ClaimGift extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: this.props.gift.comments,
      user: this.props.user,
      chosenDate: null,
      chosenTime: null
    }

    this.handleCommentsModification = this.handleCommentsModification.bind(this)
    this.handleUserModification = this.handleUserModification.bind(this)
    this.handleDateChosen = this.handleDateChosen.bind(this)
    this.handleTimeChosen = this.handleTimeChosen.bind(this)
  }

  handleCommentsModification(data) {
    this.setState({ comments: data.order.comments })
  }

  handleUserModification(user) {
    this.setState({ user: user })
  }

  handleDateChosen(date) {
    this.setState({ chosenDate: date })
  }

  handleTimeChosen(time) {
    this.setState({ chosenTime: time })
  }

  renderLineItem(lineItem, index) {
    return (
      <li key={index}>
        {lineItem.name} pour{' '}
        {display.pluralizeQuantityType(
          lineItem.quantity_type,
          lineItem.quantity
        )}
      </li>
    )
  }

  render() {
    const steps = [
      {
        name: 'Informations',
        content: (
          <InformationForm
            authenticityToken={this.props.authenticity_token}
            user={this.state.user}
            onUserModification={this.handleUserModification}
            onOrderModification={this.handleCommentsModification}
            urls={this.props.urls}
            order={this.props.gift}
            claimGift={true}
            previousStepURL={this.props.urls.claimGiftURL}
          />
        ),
        url: {}
      },
      {
        name: 'Date',
        content: (
          <GiftDatePicker
            offer={this.props.gift.offer}
            activity={this.props.gift.activity}
            onDateChosen={this.handleDateChosen}
            chosenDate={this.state.chosenDate}
            lineItems={this.props.gift.line_items}
            giftExpirationDate={this.props.gift.expiration_date}
            total={this.props.gift.total}
          />
        ),
        url: {}
      },
      {
        name: 'Heure',
        content: (
          <GiftTimePicker
            giftToken={this.props.gift.gift_token}
            offer={this.props.gift.offer}
            activity={this.props.gift.activity}
            onTimeChosen={this.handleTimeChosen}
            chosenDate={this.state.chosenDate}
            chosenTime={this.state.chosenTime}
            lineItems={this.props.gift.line_items}
          />
        ),
        url: {}
      }
    ]

    return (
      <Sentry.ErrorBoundary fallback="An error has occured">
        <div className="container mb-3">
          <MultipleStepsForm
            images={this.props.images}
            homepageURL={this.props.urls.homepageURL}
            steps={steps}>
            <Fragment>
              <img
                src={this.props.gift.activity.profile_picture_medium_url}
                className="img-responsive mb-4"
                alt={this.props.gift.activity.name}
              />
              <div className="row">
                <div className="col-sm-12">
                  <p className="gift-infos-title">
                    {this.props.gift.author.fname + ' vous a offert :'}
                  </p>
                  <p className="gift-infos-title">
                    {this.props.gift.activity.name}
                  </p>
                  <p className="gift-infos-title">
                    {this.props.gift.offer.name} :
                  </p>
                  <div>
                    <span className="gift-items">
                      <ul>
                        {this.props.gift.line_items.map(this.renderLineItem)}
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </Fragment>
          </MultipleStepsForm>
        </div>
      </Sentry.ErrorBoundary>
    )
  }
}

ClaimGift.propTypes = {
  user: PropTypes.object,
  gift: PropTypes.object,
  images: PropTypes.object,
  urls: PropTypes.object,
  authenticity_token: PropTypes.string
}
ClaimGift.displayName = 'ClaimGift'

export default ClaimGift
