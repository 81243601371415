import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { merge, map, chunk, groupBy, keys } from 'lodash'
import moment from 'moment'
import {
  getSpecialPeriodName,
  afternoon,
  fullDay,
  morning,
  evening
} from 'lib/TimeHelper'
import NavigationButtons from 'components/bookings/NavigationButtons'

class GiftTimePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      shouldFetchSchedule: false,
      hourlyPrices: {},
      chosenTime: this.props.chosenTime
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnValidate = this.handleOnValidate.bind(this)
  }

  componentDidMount() {
    this.setState({ shouldFetchSchedule: true })
  }

  handleOnChange(value) {
    this.setState({ chosenTime: value })
    this.props.onTimeChosen(value)
  }

  handleOnValidate() {
    const data = {
      gift_token: this.props.giftToken,
      order: {
        date: this.props.chosenDate,
        time: this.state.chosenTime
      }
    }
    $.ajax({
      url: `/gifts/update_order/${this.props.giftToken}`,
      method: 'POST',
      data: data,
      dataType: 'json',
      success: function (data) {
        if (data.redirect) {
          window.location.href = data.url
        }
      }
    })

    if (this.context.handleValidationSuccessful) {
      this.context.handleValidationSuccessful()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { hourlyPrices } = this.state
    const { chosenDate } = this.props
    if (!chosenDate) {
      return
    }

    const date = moment(chosenDate).format('YYYY-MM-DD')

    if (!hourlyPrices[date] && !this.state.error) {
      if (this.state.shouldFetchSchedule) {
        this.fetchScheduleForVariants()
      } else {
        this.setState({ shouldFetchSchedule: true })
      }
    }
  }

  fetchScheduleForVariants() {
    const { offer, activity, chosenDate } = this.props
    const { hourlyPrices } = this.state
    const date = moment(chosenDate).format('YYYY-MM-DD')

    fetch(
      `/activities/${activity.id}/offers/${
        offer.id
      }/prices?date=${date}&${this.formatVariantsQueryParams()}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          this.setState({ needsFetch: false, error: response })
        }
      })
      .then((body) => {
        const updatedHourlyPrices = merge(hourlyPrices, body.hourly_prices)
        this.setState({
          hourlyPrices: updatedHourlyPrices,
          shouldFetchSchedule: false
        })
      })
  }

  formatVariantsQueryParams() {
    return map(this.props.lineItems, (lineItem) => {
      return `variants[${lineItem.price_variant_id}]=${lineItem.quantity}`
    }).join('&')
  }

  groupedTimeSlots() {
    const { hourlyPrices } = this.state
    const { chosenDate } = this.props
    const date = moment(chosenDate).format('YYYY-MM-DD')
    const timeSlots = keys(hourlyPrices[date])

    const groups = groupBy(timeSlots, (time) => {
      const hour = parseInt(time.slice(0, 2))
      if (isNaN(hour)) {
        return 'special'
      } else if (hour <= 13) {
        return 'morning'
      } else {
        return 'afternoon'
      }
    })

    groups.morning = groups.morning || []
    groups.afternoon = groups.afternoon || []
    groups.special = groups.special || []

    return groups
  }

  nameForSlot(slot) {
    const specialPeriodName = getSpecialPeriodName(slot)
    if (specialPeriodName) {
      return specialPeriodName
    } else {
      return slot.slice(0, 5)
    }
  }

  render() {
    const { chosenTime } = this.state
    const groupedTimeSlots = this.groupedTimeSlots()
    const morningSlots = groupedTimeSlots.morning
    const afternoonSlots = groupedTimeSlots.afternoon
    const specialSlots = groupedTimeSlots.special
    const hasMorningSpecialSlot = specialSlots.includes(morning)
    const hasAfternoonSpecialSlot = specialSlots.includes(afternoon)
    const hasEveningSpecialSlot = specialSlots.includes(evening)
    const hasDaySpecialSlot = specialSlots.includes(fullDay)
    const showMorningSlots =
      !hasDaySpecialSlot && !hasMorningSpecialSlot && morningSlots.length > 0
    const showAfternoonSlots =
      !hasDaySpecialSlot &&
      !hasAfternoonSpecialSlot &&
      !hasEveningSpecialSlot &&
      afternoonSlots.length > 0

    return (
      <div className="booking-module">
        <div className="time-picker gift">
          <span className="date">
            {moment(this.props.chosenDate).format('Do MMMM YYYY')}
          </span>
          {hasDaySpecialSlot && (
            <div className="row timepicker-row">
              <div
                className="time-period col-xs-12 col-sm-12"
                style={{ width: '100%' }}>
                <span
                  onClick={() => this.handleOnChange(fullDay)}
                  className={`special-time-slot time-slot ${
                    chosenTime === fullDay ? 'active' : ''
                  }`}>
                  {this.nameForSlot(fullDay)}
                </span>
              </div>
            </div>
          )}
          <div className="row timepicker-row">
            <div className="time-period morning col-xs-12 col-sm-6">
              {hasMorningSpecialSlot && (
                <span
                  onClick={() => this.handleOnChange(morning)}
                  className={`special-time-slot time-slot ${
                    chosenTime === morning ? 'active' : ''
                  }`}>
                  {this.nameForSlot(morning)}
                </span>
              )}

              {showMorningSlots && (
                <Fragment>
                  <span className="period-title">matin</span>
                  {map(chunk(morningSlots, 12), (slotsBy12, index) => (
                    <div key={index} className="time-slots-chunks">
                      {map(slotsBy12, (slot, index) => (
                        <span
                          key={index}
                          onClick={() => this.handleOnChange(slot)}
                          className={`time-slot ${
                            chosenTime === slot ? 'active' : ''
                          }`}>
                          {this.nameForSlot(slot)}
                        </span>
                      ))}
                    </div>
                  ))}
                </Fragment>
              )}
            </div>

            <div className="time-period afternoon col-xs-12 col-sm-6">
              {hasAfternoonSpecialSlot && (
                <span
                  onClick={() => this.handleOnChange(afternoon)}
                  className={`special-time-slot time-slot ${
                    chosenTime === afternoon ? 'active' : ''
                  }`}>
                  {this.nameForSlot(afternoon)}
                </span>
              )}

              {hasEveningSpecialSlot && (
                <span
                  onClick={() => this.handleOnChange(evening)}
                  className={`special-time-slot time-slot ${
                    chosenTime === evening ? 'active' : ''
                  }`}>
                  {this.nameForSlot(evening)}
                </span>
              )}

              {showAfternoonSlots && (
                <Fragment>
                  <span className="period-title" style={{ marginLeft: '0' }}>
                    après-midi
                  </span>
                  {map(chunk(afternoonSlots, 12), (slotsBy12, index) => (
                    <div key={index} className="time-slots-chunks">
                      {map(slotsBy12, (slot, index) => (
                        <span
                          key={index}
                          onClick={() => this.handleOnChange(slot)}
                          className={`time-slot ${
                            chosenTime === slot ? 'active' : ''
                          }`}>
                          {this.nameForSlot(slot)}
                        </span>
                      ))}
                    </div>
                  ))}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <NavigationButtons
          goPrevious={this.context.goPrevious}
          submitLabel="Finaliser"
          handleValidate={this.handleOnValidate}
          disableSubmitButton={!this.state.chosenTime}
        />
      </div>
    )
  }
}

GiftTimePicker.propTypes = {
  chosenDate: PropTypes.object,
  chosenTime: PropTypes.string,
  offer: PropTypes.object,
  activity: PropTypes.object,
  onTimeChosen: PropTypes.func,
  lineItems: PropTypes.array,
  giftToken: PropTypes.string
}
GiftTimePicker.contextTypes = {
  handleValidationSuccessful: PropTypes.func,
  goPrevious: PropTypes.func
}
GiftTimePicker.displayName = 'GiftTimePicker'

export default GiftTimePicker
