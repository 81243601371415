import React from 'react'
import PropTypes from 'prop-types'
import PriceVariants from '../admin/offers/PriceVariants'
import classNames from 'classnames'
import { map, floor } from 'lodash'

const MultipleStepsFormHeader = ({
  steps,
  currentStep,
  goTo,
  maxReachedStep,
  babasportLogo,
  homepageURL
}) => {
  const handleStepNavigation = (stepIndex) => {
    if (goTo(stepIndex)) {
      goTo(stepIndex)
    }
  }

  const bootstrapRowClass = `col-xs-${floor(12 / steps.length)}`

  const headerStep = (step, stepIndex) => {
    if (stepIndex <= maxReachedStep) {
      return (
        <div
          key={stepIndex}
          className={`step-index-container ${bootstrapRowClass}`}>
          <a
            className="link-unstyled"
            onClick={() => handleStepNavigation(stepIndex)}>
            <span
              className={classNames('step-index', {
                active: stepIndex === currentStep
              })}>
              {stepIndex + 1}
            </span>
            <br className="visible-xs visible-sm" />
            {step.name}
          </a>
        </div>
      )
    } else {
      return (
        <div
          key={stepIndex}
          className={`step-index-container ${bootstrapRowClass}`}>
          <span className="step-index disabled">{stepIndex + 1}</span>
          <br className="visible-xs visible-sm" />
          <span className="baba-dark-grey">{step.name}</span>
        </div>
      )
    }
  }

  return (
    <div className="row py-4">
      <div className="col-md-3 logo-container">
        <a href={homepageURL} className="link-unstyled">
          <img src={babasportLogo} height="21" alt="Babasport" />
        </a>
      </div>
      <div className="col-md-8 col-md-offset-1">
        <div className="row">
          {map(steps, (step, stepIndex) => headerStep(step, stepIndex))}
        </div>
      </div>
    </div>
  )
}

MultipleStepsFormHeader.displayName = 'MultipleStepsFormHeader'
MultipleStepsFormHeader.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.element,
      url: PropTypes.shape({
        url: PropTypes.string,
        path: PropTypes.string
      })
    })
  ),
  currentStep: PropTypes.number,
  goTo: PropTypes.func,
  maxReachedStep: PropTypes.number,
  babasportLogo: PropTypes.string,
  homepageURL: PropTypes.string
}

PriceVariants.defaultProps = {}
export default MultipleStepsFormHeader
