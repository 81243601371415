import React, { Fragment, useEffect, useState } from 'react'
import { connectRefinementList } from 'react-instantsearch/connectors'
import AlgoliaHelper from 'lib/AlgoliaHelper'
import { filter } from 'lodash'

const CategoriesRefinementList = ({ items, refine, initialItems, currentRefinement }) => {
  const [categories, setCategories] = useState(
    AlgoliaHelper.mapFromAllCategories(initialItems, items, currentRefinement)
  )

  useEffect(() => {
    setCategories(
      AlgoliaHelper.mapFromAllCategories(initialItems, items, currentRefinement)
    )
  }, [items, initialItems, currentRefinement])

  const handleRefine = (item) => {
    if (item.isRefined) {
      refine(
        filter(currentRefinement, (refinement) => refinement !== item.label)
      )
    } else {
      refine([...currentRefinement, item.label])
    }
  }

  return (
    <Fragment>
      {categories.map((category, index) => (
        <div
          className="input-group search-categories-list"
          key={category.label}>
          {index !== 0 && <hr />}
          <div className="parent-category">
            <div className="parent-category-filter search-checkbox">
              {category.children && category.children.length > 0 ? (
                <label>{category.label}</label>
              ) : (
                <Fragment>
                  <div>
                    <input
                      type="checkbox"
                      checked={category.isRefined}
                      id={`category-${category.id}`}
                      onChange={() => handleRefine(category)}
                      disabled={category.count === 0}
                    />
                  </div>
                  <div>
                    <label htmlFor={`category-${category.id}`}>
                      {`${category.label} (${category.count})`}
                    </label>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          {category.children && category.children.length > 0 && (
            <div className="sub-categories-container">
              {category.children.map((subcategory) => (
                <div
                  className="sub-category search-checkbox"
                  key={subcategory.id}>
                  <div>
                    <input
                      type="checkbox"
                      id={`category-${subcategory.id}`}
                      checked={subcategory.isRefined}
                      onChange={() => handleRefine(subcategory)}
                      disabled={subcategory.count === 0}
                    />
                  </div>
                  <div className="label-wrapper">
                    <label
                      className={subcategory.count === 0 ? 'disabled' : ''}
                      htmlFor={`category-${subcategory.id}`}>{`${subcategory.label} (${subcategory.count})`}</label>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </Fragment>
  )
}

export default connectRefinementList(CategoriesRefinementList)
