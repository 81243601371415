import React, { useState, useEffect } from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'

const ShowFiltersButtonMobile = ({ items, openMobileMenu }) => {
  let initialRefinementsCount = 0

  const arrayUniqueById = [
    ...new Map(items.map((item) => [item.id, item])).values()
  ]
  if (arrayUniqueById.length > 0) {
    arrayUniqueById.forEach((refinementType) => {
      if (Array.isArray(refinementType.currentRefinement)) {
        initialRefinementsCount += refinementType.currentRefinement.length
      } else {
        initialRefinementsCount += 1
      }
    })
  }
  const [currentRefinementsCount, updateCurrentRefinementsCount] = useState(
    initialRefinementsCount
  )

  useEffect(() => {
    const arrayUniqueById = [
      ...new Map(items.map((item) => [item.id, item])).values()
    ]
    let updatedRefinementsCount = 0
    if (arrayUniqueById.length > 0) {
      arrayUniqueById.forEach((refinementType) => {
        if (Array.isArray(refinementType.currentRefinement)) {
          updatedRefinementsCount += refinementType.currentRefinement.length
        } else {
          updatedRefinementsCount += 1
        }
      })
      updateCurrentRefinementsCount(updatedRefinementsCount)
    } else {
      updateCurrentRefinementsCount(0)
    }
  }, [items])

  return (
    <button onClick={openMobileMenu} className="btn btn-primary btn-lg">
      Filtres ({currentRefinementsCount})
    </button>
  )
}

ShowFiltersButtonMobile.propTypes = {
  items: PropTypes.array,
  openMobileMenu: PropTypes.func
}

export default connectCurrentRefinements(ShowFiltersButtonMobile)
