import React from 'react'
import imageUrl from 'images/search/promotional/paragliding.jpeg'
import { isMobile } from 'react-device-detect'

const ParaglidingNode = () => (
  <a
    className="link-unstyled"
    href="/activites/5925-bababox-vol-en-parapente"
    target={isMobile ? '_self' : '_blank'}
    rel={isMobile ? '' : 'noreferrer'}>
    <div className="hit-card hit-card-promo">
      <div className="img-container">
        <div className="promo-banner">Promotion</div>
        <img src={imageUrl} alt="Vol en parapente" />
      </div>
      <div className="content">
        <div className="promo-title">Offrez une Bababox : Vol en parapente</div>

        <div className="hit-features">
          <div>
            Offrez une expérience incroyable pour découvrir les richesses de nos
            régions depuis le ciel.
          </div>
          <div> Des vols dans des sites d’exception en France.</div>
          <div>Valide 1 an, renouvelable 12 mois.</div>
        </div>
      </div>
      <div className="hit-card-bottom-container">
        <div className="pricing text-center">
          <span className="price-from">A partir de&nbsp;</span>
          <div className="price">108 €&nbsp;</div>
          <span className="price-per">par personne</span>
        </div>
      </div>
    </div>
  </a>
)

export default ParaglidingNode
