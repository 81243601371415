import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PriceOrFree from 'components/prices/PriceOrFree'
import classNames from 'classnames'

class OrderCheckoutValidationShippingMethodForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: ''
    }

    this.handleShippingMethodChange = this.handleShippingMethodChange.bind(this)
    this.shippingMethodsImageClasses =
      this.shippingMethodsImageClasses.bind(this)
    this.showShippingMethodPreviewArrow =
      this.showShippingMethodPreviewArrow.bind(this)
    this.handleShowPreviewClick = this.handleShowPreviewClick.bind(this)
    this.updateSuccess = this.updateSuccess.bind(this)
    this.updateError = this.updateError.bind(this)
  }

  showShippingMethodPreviewArrow() {
    if (this.state.showPreview) {
      return <span className="glyphicon glyphicon-menu-up" />
    } else {
      return <span className="glyphicon glyphicon-menu-down" />
    }
  }

  shippingMethodsImageClasses() {
    return classNames('img-responsive mb-2', {
      hidden: !this.state.showPreview
    })
  }

  handleShowPreviewClick() {
    this.setState({ showPreview: !this.state.showPreview })
  }

  handleShippingMethodChange() {
    $.ajax({
      url: this.props.shippingMethodUpdateURL,
      method: 'POST',
      cache: false,
      data: {
        shipping_method: {
          code: this.props.shippingMethod.code
        }
      },
      dataType: 'json',
      success: this.updateSuccess,
      error: this.updateError
    })
  }

  updateSuccess(data) {
    this.setState({
      errorMessage: ''
    })
    this.props.onOrderModification(data)
  }

  updateError() {
    this.setState({
      errorMessage: 'Un problème est survenu, veuillez réessayer.'
    })
  }

  render() {
    const selected =
      this.props.selectedShippingMethodCode === this.props.shippingMethod.code

    return (
      <div className="panel panel-default">
        <div className="panel-body">
          {this.state.errorMessage && (
            <div className="row">
              <div className="col-xs-12 text-danger">
                {this.state.errorMessage}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-xs-10">
              <div className="row">
                <div className="col-xs-1 checkbox-inline">
                  <input
                    type="radio"
                    className="mt-2 mr-3"
                    value={this.props.shippingMethod.code}
                    name="shipping_method"
                    id={`shipping_method_${this.props.shippingMethod.code}`}
                    onChange={this.handleShippingMethodChange}
                    defaultChecked={selected}
                  />
                </div>
                <div className="col-xs-11">
                  <div className="row">
                    <div className="col-sm-6">
                      <label
                        htmlFor={`shipping_method_${this.props.shippingMethod.code}`}>
                        <strong>{this.props.shippingMethod.name}</strong>{' '}
                        <div>{this.props.shippingMethod.headline}</div>
                      </label>
                    </div>
                    <div className="col-sm-6">
                      {this.props.shippingMethod.image && (
                        <div
                          className="clickable"
                          onClick={this.handleShowPreviewClick}>
                          <u>Voir un aperçu</u>
                          {this.showShippingMethodPreviewArrow()}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-2 text-right">
              <strong>
                <PriceOrFree amount={this.props.shippingMethod.baseAmount} />
              </strong>
            </div>
          </div>
          {this.props.shippingMethod.image && (
            <img
              className={this.shippingMethodsImageClasses()}
              src={this.props.shippingMethod.image}
              alt={this.props.shippingMethod.name}
            />
          )}
        </div>
      </div>
    )
  }
}

OrderCheckoutValidationShippingMethodForm.propTypes = {
  shippingMethod: PropTypes.object.isRequired,
  onOrderModification: PropTypes.func.isRequired,
  shippingMethodUpdateURL: PropTypes.string.isRequired,
  selectedShippingMethodCode: PropTypes.string.isRequired
}

OrderCheckoutValidationShippingMethodForm.defaultProps = {}

export default OrderCheckoutValidationShippingMethodForm
