function debouncePromise(fn, time) {
  let timerId = null
  let resolveFns = []

  return function debounced(...args) {
    clearTimeout(timerId)

    timerId = setTimeout(() => {
      resolveFns.pop()(fn(...args))
      resolveFns.forEach((resolve) => resolve([]))
      resolveFns = []
    }, time)

    return new Promise((resolve) => resolveFns.push(resolve))
  }
}

const debounced = debouncePromise((items) => Promise.resolve(items), 400)

export default debounced
