import React from 'react'
import PropTypes from 'prop-types'

class BookingFaqsAccordionPanel extends React.Component {
  render() {
    return (
      <div className="col-xs-12 col-sm-6">
        <div className="panel">
          <a
            className="collapsed"
            role="button"
            data-toggle="collapse"
            data-parent={this.props.parentId}
            href={'#' + this.props.collapseId}
            aria-expanded="false"
            aria-controls="faqCollapseOne">
            <div className="panel-heading" role="tab" id={this.props.headingId}>
              <h4 className="panel-title">
                <div className="row">
                  <div className="col-xs-10">{this.props.question}</div>
                  <div className="col-xs-2">
                    <span className="glyphicon glyphicon-menu-down" />
                    <span className="glyphicon glyphicon-menu-up" />
                  </div>
                </div>
              </h4>
            </div>
          </a>
          <div
            id={this.props.collapseId}
            className="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="faqHeadingOne">
            <div className="panel-body">{this.props.answer}</div>
          </div>
        </div>
      </div>
    )
  }
}

BookingFaqsAccordionPanel.propTypes = {
  headingId: PropTypes.string,
  parentId: PropTypes.string,
  collapseId: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string
}

export default BookingFaqsAccordionPanel
